import React, {FC} from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

interface CloseButtonProps {
    onClose: () => void
}

const CloseButton: FC<CloseButtonProps> = ({ onClose }) => {
    return (
        <div
            style={{cursor: 'pointer'}}
            onClick={onClose}
        >
            <CloseIcon stroke='#808080'/>
        </div>
    );
};

export default CloseButton;