import {configDefaultWithToken, postApiCall} from './base';

const companyUrl = '/api/company';


const assignCardToCompanyUrl = (companyId: number) => `${companyUrl}/${companyId}/assign-card`;
const removeCardFromCompanyUrl = `${companyUrl}/remove-card`;

const assignCardToCompanySelectAllUrl = (fromCompanyId: number, toCompanyId: number) => (
    `${companyUrl}/${fromCompanyId}/assign-card/${toCompanyId}/select-all`
);

const assignPersonalCardToCompanySelectAllUrl = (companyId: number) => (
    `${companyUrl}/${companyId}/assign-personal-card/select-all`
);

const removeCardFromCompanySelectAllUrl = (companyId: number) => `${companyUrl}/${companyId}/remove-card/select-all`;

export const assignCardToCompany = async (companyId: number, cardProfileIds: number[]) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(assignCardToCompanyUrl(companyId), cardProfileIds, config);
};

export const assignCardToCompanySelectAll = async (
    fromCompanyId: number, toCompanyId: number, ids: number[], searchText?: string, searchStatus?: string
) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    const queryParams = constructQueryParams(ids, searchText, searchStatus);
    const urlWithParams = `${assignCardToCompanySelectAllUrl(fromCompanyId, toCompanyId)}?${queryParams.toString()}`;

    return await postApiCall(urlWithParams, {}, config);
};

export const assignPersonalCardToCompanySelectAll = async (
    companyId: number, ids: number[], searchText?: string, searchStatus?: string
) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    const queryParams = constructQueryParams(ids, searchText, searchStatus);
    const urlWithParams = `${assignPersonalCardToCompanySelectAllUrl(companyId)}?${queryParams.toString()}`;

    return await postApiCall(urlWithParams, {}, config);
};

export const removeCardFromCompany = async (cardProfileIds: number[]) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(removeCardFromCompanyUrl, cardProfileIds, config);
};

export const removeCardFromCompanySelectAll = async (
    companyId: number, ids: number[], searchText?: string, searchStatus?: string
) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    const queryParams = constructQueryParams(ids, searchText, searchStatus);
    const urlWithParams = `${removeCardFromCompanySelectAllUrl(companyId)}?${queryParams.toString()}`;

    return await postApiCall(urlWithParams, {}, config);
};

const constructQueryParams = (
    ids: number[], searchText?: string, searchStatus?: string
) => {
    const queryParams = new URLSearchParams();
    queryParams.append('idsToNotUpdate', ids.join(','));
    if (searchText) {
        queryParams.append('searchByName', searchText);
    }
    if (searchStatus) {
        queryParams.append('status', searchStatus);
    }

    return queryParams;
};