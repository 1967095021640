import { FC, ReactNode, createContext, useContext } from 'react';
import {ExchangeContactData} from '../types/exchangeContacts';

interface ExchangeContactContextType {
    addUpdatedExchangeContact: (updatedContact: ExchangeContactData) => void;
    removeExchangeContact: (contactId: number) => void;
}

interface ExchangeContactProviderProps {
    children: ReactNode;
    addUpdatedExchangeContact: (updatedContact: ExchangeContactData) => void;
    removeExchangeContact: (contactId: number) => void;
}

const ExchangeContactContext = createContext<ExchangeContactContextType>(null!);

export const ExchangeContactProvider: FC<ExchangeContactProviderProps> = ({
    children, addUpdatedExchangeContact, removeExchangeContact
}) => {

    return (
        <ExchangeContactContext.Provider
            value={{
                addUpdatedExchangeContact, removeExchangeContact
            }}>
            {children}
        </ExchangeContactContext.Provider>
    );
};

export const useExchangeContactContext = () => {
    return useContext(ExchangeContactContext);
};
