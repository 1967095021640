import React, {FC, useState} from 'react';
import styles from './contents.module.scss';
import i18n from '../../../translations/i18n';
import {ReleaseNote} from '../../../types/releaseNote';

interface ContentsProps {
    releaseNotes: ReleaseNote[];
    onClickItem: (id: number) => void;
}

const Contents: FC<ContentsProps> = ({
    releaseNotes, onClickItem
}) => {
    const [activeContent, setActiveContent] = useState<number>(releaseNotes[0].id);

    const handleOnActiveContent = (id: number) => {
        setActiveContent(id);
        onClickItem(id);
    };

    return (
        <div className={styles.contents}>
            <div className={styles.contentsTitle}>
                {i18n.t('common.contents')}
            </div>
            {releaseNotes.map(r => (
                <div
                    key={r.id}
                    className={styles.contentsWrapper}
                    onClick={() => handleOnActiveContent(r.id)}
                    style={{ cursor: 'pointer' }}
                >
                    {r.id === activeContent &&
                        <div className={styles.redLine}></div>
                    }
                    <div className={`${styles.text} ${styles.version}`}>
                        {r.version}
                    </div>
                    <div className={`${styles.text} ${styles.date}`}>
                        - {r.releaseDate}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Contents;