import React, { FC } from 'react';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow/arrow-long-right.svg';
import styles from './category-card.module.scss';
import { useNavigate } from 'react-router-dom';
import { navigateTo } from '../../constants/routes';
import i18n from '../../translations/i18n';
import { TextHighlighter } from '../common/textHighlighter/TextHighlighter';


interface CategoryDeskProps {
    icon: string
    title: string
    articleCount: number
    slug: string
    searchText: string
}
const CategoryCard: FC<CategoryDeskProps> = ({ icon, title, articleCount, slug, searchText }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`${navigateTo.helpDesk}/${slug}`);
    };

    return (
        <div className={styles.container}>
            <div className={styles.icon}><img src={icon} /> </div>
            <div className={styles.title} >
                <TextHighlighter text={title} textToHighlight={searchText} />
            </div>
            <div className={styles.countContainer} onClick={handleClick}>
                <div className={styles.count} >{articleCount} {i18n.t('common.articles')} </div>
                <div className={styles.arrow}><ArrowRightIcon /> </div>
            </div>

        </div>
    );
};

export default CategoryCard;