import React, { FC, useState } from 'react';
import styles from './app-header.module.scss';
import { ReactComponent as IcardsLogoIcon } from '../../../assets/icons/logos/icardLogo.svg';
import { ReactComponent as AvatarIcon } from '../../../assets/icons/avatar.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow/arrow.svg';
import { ReactComponent as MobileAvatarIcon } from '../../../assets/icons/mobileAvatar.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menuIcon.svg';
import { ReactComponent as CloseMenuIcon } from '../../../assets/icons/closeMenuIcon.svg';
import Popover from '@mui/material/Popover';
import { routes } from '../../../constants/routes';
import { useAppSelector } from '../../../store/appDispatch';
import { selectUser, selectUserPhoto } from '../../../store/slices/userSessionSlice';
import { Company } from '../../../types/company';
import NotificationContainer from '../../notification/NotificationContainer';
import { IconButton, Drawer } from '@mui/material';
import HomeHeader from '../HomeHeader/HomeHeader';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';
import CompanySelector from '../../common/selector/CompanySelector';
import SettingMenu from '../../common/menu/SettingMenu';
import LanguageSelector from '../../common/selector/LanguageSelector';

const AppHeader: FC = () => {
    const user = useAppSelector(selectUser);
    const isSmallScreen = useIsSmallScreen();
    const userProfilePhoto = useAppSelector(selectUserPhoto);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    const toggleDrawer = (open: boolean) => () => {
        setOpenDrawer(!open);
    };

    const companyItems = user.companies.map((c: Company) => ({ value: c.id, name: c.name }));
    const hasCompany = companyItems?.length > 0;

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl((prev) => (prev ? null : event.currentTarget));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            {isSmallScreen ?
                <>
                    <nav className={styles.navContainer} style={{ zIndex: 1301 }}>
                        <IcardsLogoIcon className={styles.logo} />
                        <div className={styles.mobileIcons}>
                            <NotificationContainer />
                            <IconButton onClick={toggleDrawer(openDrawer)}>
                                {openDrawer ? <CloseMenuIcon /> : <MenuIcon />}
                            </IconButton>
                        </div>

                    </nav>
                    <div className={styles.selectorContainer}>
                        <CompanySelector 
                            companyItems={companyItems} 
                            defaultCompany={user.activeCompany?.id} 
                            hasCompany={hasCompany} 
                            isSmallScreen/>
                    </div>

                    <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer(openDrawer)} classes={{ paper: styles.drawerPaper }}>
                        <div className={styles.homeHeader}>
                            <HomeHeader onLinkClick={toggleDrawer(openDrawer)}/>
                        </div>

                        <div className={styles.drawerContent}>
                            <div className={styles.profileSection}>
                                <div className={styles.mobileAvatar}>
                                    {userProfilePhoto
                                        ? <img className={styles.mobileAvatarImage} src={userProfilePhoto} alt="Avatar AndroidPreview" />
                                        : <MobileAvatarIcon />
                                    }
                                </div>
                                <div>
                                    <div className={styles.name}>{user?.fullname}</div>
                                    <div className={styles.email}>
                                        {user?.email}
                                    </div>
                                </div>

                            </div>
                            <SettingMenu user={user} isSmallScreen={isSmallScreen}/>
                            <LanguageSelector isSmallScreen={isSmallScreen} user={user}/>
                        </div>
                    </Drawer>
                </> :
                <nav className={styles.navContainer}>
                    <a href={routes.analytics}>
                        <IcardsLogoIcon className={styles.logo} />
                    </a>
                    <div className={styles.separator}>/</div>
                    <CompanySelector
                        companyItems={companyItems}
                        defaultCompany={user.activeCompany?.id}
                        hasCompany={hasCompany} isSmallScreen={false}
                    />
                    <div className={styles.userInfo}>
                        <NotificationContainer />
                        <LanguageSelector isSmallScreen={isSmallScreen} user={user}/>
                        <div className={styles.userInfo} aria-haspopup="true" aria-controls={id} onClick={handleClick} role="button" >
                            <div className={styles.avatar}>
                                {userProfilePhoto
                                    ? <img className={styles.avatarImage} src={userProfilePhoto} alt="Avatar AndroidPreview" />
                                    : <AvatarIcon />
                                }
                            </div>
                            <div className={styles.username}>
                                {user?.fullname}
                            </div>
                            <div className={styles.arrow}>
                                <ArrowIcon />
                            </div>
                        </div>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <SettingMenu user={user} isSmallScreen={isSmallScreen}/>
                        </Popover>
                    </div>
                </nav>}
        </>
    );
};

export default AppHeader;