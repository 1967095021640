import {FC, useCallback, useEffect, useState} from 'react';
import Cropper from 'react-easy-crop';
import style from './image-cropper.module.scss';
import {Slider} from '@mui/joy';
import Typography from '@mui/material/Typography';
import CustomButton from '../common/button/CustomButton';
import i18n from '../../translations/i18n';
import {getCroppedImg} from './cropImageUtils';

export type CropShape = 'rect' | 'round';

interface ImageCropperProps {
    image: string;
    onClose: () => void;
    onSaveImage: (img: File) => void;
    cropShape: CropShape;
    cropHeight: number;
    cropWidth: number;
}

const ImageCropper: FC<ImageCropperProps> = ({
    image, onClose, onSaveImage, cropShape, cropHeight, cropWidth
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [rotation, setRotation] = useState(0);
    const [resizedImage, setResizedImage] = useState<string | null>(null);
    const [minZoom, setMinZoom] = useState(1);

    const onCropChange = (crop: any) => {
        setCrop(crop);
    };

    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const onZoomChange = (zoomValue: number) => {
        setZoom(Math.max(minZoom, zoomValue));
    };

    const showCroppedImage = async () => {
        const croppedImage: File | null = await getCroppedImg(
            resizedImage || image,
            croppedAreaPixels,
            zoom,
            rotation
        );

        croppedImage && onSaveImage(croppedImage);
    };

    // 🔹 Resize image before passing to Cropper
    useEffect(() => {
        const resizeImage = async (src: string) => {
            const img = new Image();
            img.src = src;
            await img.decode();

            const originalWidth = img.width;
            const originalHeight = img.height;
            const aspectRatio = cropWidth / cropHeight;
            const imageAspectRatio = originalWidth / originalHeight;

            // Calculate the minimum zoom needed to fit the image
            let calculatedMinZoom = imageAspectRatio > aspectRatio
                ? cropWidth / originalWidth
                : cropHeight / originalHeight;

            calculatedMinZoom = Math.min(1, calculatedMinZoom);

            setMinZoom(calculatedMinZoom);
            setZoom(calculatedMinZoom); // Set initial zoom
            setResizedImage(src); // Use original image
        };

        resizeImage(image);
    }, [image, cropWidth, cropHeight]);

    // 🔹 Handle zoom with mouse scroll (Fix for not zooming out fully)
    const handleWheelZoom = useCallback((event: WheelEvent) => {
        event.preventDefault();
        const zoomStep = event.deltaY > 0 ? -0.1 : 0.1; // Scroll down = zoom out, Scroll up = zoom in
        setZoom(prevZoom => Math.max(minZoom, Math.min(3, prevZoom + zoomStep)));
    }, [minZoom]);

    // 🔹 Add & Remove Wheel Event Listener
    useEffect(() => {
        window.addEventListener('wheel', handleWheelZoom, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWheelZoom);
        };
    }, [handleWheelZoom]);

    return (
        <div id='crop' className={style.app}>
            <div id='crop-container' className={style.cropContainer}>
                { resizedImage && (
                    <Cropper
                        image={resizedImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={cropWidth / cropHeight}
                        rotation={rotation}
                        cropShape={cropShape}
                        restrictPosition={false}
                        showGrid={false}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                        cropSize={{height: cropHeight, width: cropWidth}}
                        objectFit={'contain'}
                    />
                )}
            </div>
            <div className={style.controls}>
                <div className={style.sliderContainer}>
                    <div className={style.sliderWrapper}>
                        <Typography
                            variant="overline"
                            classes={{root: style.sliderLabel}}
                        >
                            Zoom
                        </Typography>
                        <Slider
                            value={zoom}
                            min={minZoom}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            classes={{root: style.slider}}
                            onChange={(e, zoom) => setZoom(Math.max(minZoom, zoom as number))}
                        />
                    </div>
                    <CustomButton
                        text={i18n.t('common.save')}
                        onClick={showCroppedImage}
                        sx={style.btn}
                    />
                </div>
                <div className={style.sliderContainer}>
                    <div className={style.sliderWrapper}>
                        <Typography
                            variant="overline"
                            classes={{root: style.sliderLabel}}
                        >
                            Rotation
                        </Typography>
                        <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-valuetext={'Rotate'}
                            aria-labelledby="Rotate"
                            classes={{root: style.slider}}
                            onChange={(e, rotation) => setRotation(rotation as number)}
                        />
                    </div>
                    <CustomButton
                        text={i18n.t('common.cancel')}
                        onClick={onClose}
                        sx={style.btn}
                    />
                </div>
            </div>
        </div>
    );
};

export default ImageCropper;