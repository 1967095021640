import {NotificationDto} from '../../types/notification';
import {isEmpty} from '../../utils/common';


export const NotificationConverter = {
    convertFromNotificationApiToNotifications(data: any[]): NotificationDto[] {
        if (isEmpty(data)) return [];

        return data
            .map(d => NotificationConverter.convertFromNotificationApiToNotificationDto(d))
            .filter((notification): notification is NotificationDto => notification !== undefined);
    },
    convertFromNotificationApiToNotificationDto(data: any): NotificationDto | undefined {
        if (!data) return undefined;

        const additionalData = data.additionalData;

        return {
            id: data.id,
            type: data.type,
            timeAgo: data.timeAgo,
            url: data.url,
            read: data.read,
            companyName: additionalData?.companyName || null,
            role: additionalData?.role || null,
            data1: additionalData?.data1 || null,
            data2: additionalData?.data2 || null,
        };
    }
};