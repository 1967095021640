import React, {FC} from 'react';
import useMyCardsController from './useMyCardsController';
import Cards from './Cards';

const MyCards: FC = () => {
    const {
        apiCalled, loading, apiFilter, setApiFilter, cardProfilesPagination,
        handleDeleteCardProfile, reloadCardsApi
    } = useMyCardsController();

    return (
        <Cards
            apiCalled={apiCalled}
            loading={loading}
            apiFilter={apiFilter}
            setApiFilter={setApiFilter}
            cardProfilesPagination={cardProfilesPagination}
            handleDeleteCardProfile={handleDeleteCardProfile}
            isMyCards={true}
            reloadCardsApi={reloadCardsApi}
        />
    );
};

export default MyCards;