import React, { FC, useState } from 'react';
import styles from './general.module.scss';
import Header from '../layout/Header';
import { Input } from '../../common/input/Input';
import i18n from '../../../translations/i18n';
import CoverImageInput from '../../common/fileInput/CoverImageInput';
import AvatarImageInput from '../../common/fileInput/AvatarImageInput';
import {CardProfileBasic} from '../../../types/cardProfile';
import Divider from '../../common/divider/Divider';
import {CropperModal} from '../../imageCropper/CropperModal';
import {
    NOT_ALLOWED_SPECIAL_CHARS_FOR_NAME_REGEX
} from '../../../utils/validationUtils';
import {CropShape} from '../../imageCropper/ImageCropper';
import {
    canEditCompanyName,
    canEditCoverImage, canEditDepartment,
    canEditFullname,
    canEditPosition,
    canEditProfileImage
} from '../../../store/utils/company';

interface GeneralProps {
    profileBasic?: CardProfileBasic | undefined
    profilePhoto?: string
    coverPhoto?: string
    showGeneralHeader?: boolean
    showIcon?: boolean
    setNewProfilePhoto: React.Dispatch<React.SetStateAction<File>>
    setNewCoverPhoto: React.Dispatch<React.SetStateAction<File>>
    setIsDirty: React.Dispatch<React.SetStateAction<boolean>>
    setBasicData: React.Dispatch<React.SetStateAction<any>>
    handleDeleteCoverImage?: (e: React.MouseEvent) => void
    handleRemoveProfile?: () => void
    newProfilePhoto?: File
    newCoverPhoto?: File
    isWalletCard?: boolean
    crop?: CropShape
    bulkView?: boolean
    error?: boolean
    isCompanyCard: boolean;
}

const General: FC<GeneralProps> = ({
    handleDeleteCoverImage, handleRemoveProfile, profileBasic, profilePhoto, coverPhoto, showGeneralHeader,
    showIcon, setNewProfilePhoto, setNewCoverPhoto, setIsDirty, setBasicData, newProfilePhoto, newCoverPhoto, isWalletCard,
    crop, bulkView, error, isCompanyCard
}) => {
    const [isExtended, setIsExtended] = useState<boolean>(false);
    const [cropperModal, setCropperModal] = useState<{ show: boolean, image: string }>({show: false, image: ''});
    const [cropperCoverModal, setCropperCoverModal] = useState<{ show: boolean, image: string }>({show: false, image: ''});
    const cropShape = crop ? crop : 'round';

    const handleProfilePhotoChange = (newPhoto: File) => {
        setCropperModal({show: true, image: URL.createObjectURL(newPhoto as Blob)});
    };

    const handleCoverPhotoChange = (newCoverPhoto: File) => {
        setCropperCoverModal({show: true, image: URL.createObjectURL(newCoverPhoto as Blob)});
    };

    const handleCloseCropperModal = () => setCropperModal({show: false, image: ''});

    const handleCloseCropperCoverModal = () => setCropperCoverModal({show: false, image: ''});

    const handleOnSaveImage = (croppedImg: File) => {
        setNewProfilePhoto(croppedImg);
        setIsDirty(true);
        handleCloseCropperModal();
    };

    const handleOnSaveCoverImage = (croppedImg: File) => {
        setNewCoverPhoto(croppedImg);
        setIsDirty(true);
        handleCloseCropperCoverModal();
    };

    const handleInputChange = (field: string, value: string) => {
        setBasicData((prevData: CardProfileBasic) => ({ ...prevData, [field]: value }));
        setIsDirty(true);
    };

    const handleExtend = () => {
        setIsExtended(prevState => !prevState);
    };

    return (
        <div className={`${styles.container} ${isExtended ? styles.extended : styles.collapsed}`}>
            <Header showIcon={showIcon} title={i18n.t('common.general')} onExtend={handleExtend} isExtended={isExtended} isToggled/>
            {isExtended && (
                <>
                    <form className={styles.form}>
                        <Divider/>
                        <CropperModal
                            isOpen={cropperModal.show}
                            image={cropperModal.image}
                            onClose={handleCloseCropperModal}
                            onSaveImage={handleOnSaveImage}
                            cropShape={cropShape}
                            cropHeight={130}
                            cropWidth={130}
                        />
                        {showGeneralHeader &&
                            <AvatarImageInput
                                id='avatarImage'
                                handleRemove={handleRemoveProfile}
                                onPhotoChange={handleProfilePhotoChange}
                                profilePhoto={newProfilePhoto ? URL.createObjectURL(newProfilePhoto) : profilePhoto}
                                shape={cropShape}
                                disabled={isCompanyCard && !canEditProfileImage()}
                            />
                        }
                        <div className={styles.flexInput}>
                            {!bulkView &&
                                <Input
                                    notAllowedSpecialChars={NOT_ALLOWED_SPECIAL_CHARS_FOR_NAME_REGEX}
                                    label={i18n.t('labels.fullname')}
                                    value={profileBasic?.fullname}
                                    type="text"
                                    onChange={(e) => handleInputChange('fullname', e.target.value)}
                                    max={64}
                                    disabled={isCompanyCard && !canEditFullname()}
                                />
                            }
                            {!isWalletCard &&
                                <Input
                                    label={i18n.t('labels.company')}
                                    value={profileBasic?.company}
                                    type="text"
                                    onChange={(e) => handleInputChange('company', e.target.value)}
                                    max={64}
                                    disabled={isCompanyCard && !canEditCompanyName()}
                                />
                            }
                        </div>
                        <div className={styles.flexInput}>
                            {isWalletCard &&
                                <Input
                                    label={i18n.t('labels.company')}
                                    value={profileBasic?.company}
                                    type="text"
                                    onChange={(e) => handleInputChange('company', e.target.value)}
                                    max={64}
                                    disabled={isCompanyCard && !canEditCompanyName()}
                                />
                            }
                            <Input
                                label={i18n.t('labels.position')}
                                value={profileBasic?.position}
                                type="text"
                                onChange={(e) => handleInputChange('position', e.target.value)}
                                max={128}
                                disabled={isCompanyCard && !canEditPosition()}
                            />
                            {!isWalletCard &&
                                <Input
                                    label={i18n.t('labels.department')}
                                    value={profileBasic?.department}
                                    type="text"
                                    onChange={(e) => handleInputChange('department', e.target.value)}
                                    max={64}
                                    disabled={isCompanyCard && !canEditDepartment()}
                                />
                            }
                        </div>
                        {!showGeneralHeader &&
                            <>
                                <div className={styles.label} style={{display: 'flex', gap: '4px'}}>
                                    {i18n.t('common.companyLogo')}
                                    {isWalletCard && <span>*</span>}
                                    {error &&
                                        <div style={{color: '#ED1B24'}}>This field is required</div>
                                    }
                                </div>
                                <AvatarImageInput
                                    id='companyLogo'
                                    handleRemove={handleRemoveProfile}
                                    onPhotoChange={handleProfilePhotoChange}
                                    profilePhoto={newProfilePhoto ? URL.createObjectURL(newProfilePhoto) : profilePhoto}
                                    shape={cropShape}
                                    error={error}
                                    disabled={isCompanyCard && !canEditProfileImage()}
                                />
                            </>
                        }
                        <CropperModal
                            isOpen={cropperCoverModal.show}
                            image={cropperCoverModal.image}
                            onClose={handleCloseCropperCoverModal}
                            onSaveImage={handleOnSaveCoverImage}
                            cropShape={'rect'}
                            cropHeight={128}
                            cropWidth={340}
                        />
                        <CoverImageInput
                            id='coverImage'
                            label='Cover'
                            btnText={i18n.t('profile.general.changeCover')}
                            handleRemove={handleDeleteCoverImage}
                            coverPhoto={newCoverPhoto ? URL.createObjectURL(newCoverPhoto) : coverPhoto}
                            onPhotoChange={handleCoverPhotoChange}
                            disabled={isCompanyCard && !canEditCoverImage()}
                        />
                    </form>
                </>
            )}
        </div>
    );
};

export default General;
