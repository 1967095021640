import React, {FC, useState} from 'react';
import i18n from '../../../translations/i18n';
import SettingsBox from '../SettingsBox';
import SalesforceConnectForm from './salesforce/SalesforceConnectForm';
import useIntegrationsController from './useIntegrationsController';
import LoadingIcards from '../../../pages/loading/LoadingIcards';
import SalesforceIntegration from './salesforce/SalesforceIntegration';
import HubSpotConnectForm from './hubspot/HubSpotConnectForm';
import {IntegrationForm} from '../../../types/integration';
import {HUBSPOT, SALESFORCE} from '../../../constants/integration';
import HubSpotIntegration from './hubspot/HubSpotIntegration';


const Integrations: FC = () => {
    const [openConnectForm, setOpenConnectForm]
        = useState<IntegrationForm | undefined>(undefined);

    const {
        loading, integrationStatus, reloadIntegrationStatus
    } = useIntegrationsController();

    if (loading) return <LoadingIcards />;

    if (openConnectForm === SALESFORCE) {
        return (
            <SalesforceConnectForm
                isSalesforceEnabled={integrationStatus.salesforce}
                onClose={() => setOpenConnectForm(undefined)}
                reloadIntegrationStatus={reloadIntegrationStatus}
            />
        );
    }

    if (openConnectForm === HUBSPOT) {
        return (
            <HubSpotConnectForm
                isHubSpotEnabled={integrationStatus.hubSpot}
                onClose={() => setOpenConnectForm(undefined)}
                reloadIntegrationStatus={reloadIntegrationStatus}
            />
        );
    }

    return (
        <SettingsBox title={i18n.t('common.integrations')}>
            <SalesforceIntegration
                isSalesforceEnabled={integrationStatus.salesforce}
                setOpenConnectForm={setOpenConnectForm}
            />
            <HubSpotIntegration
                isHubSpotEnabled={integrationStatus.hubSpot}
                setOpenConnectForm={setOpenConnectForm}
            />

        </SettingsBox>
    );
};

export default Integrations;