import React, {FC, useEffect, useRef, useState} from 'react';
import ModalContainer from '../common/modalContainer/ModalContainer';
import styles from './permission-modal.module.scss';
import i18n from '../../translations/i18n';
import Divider from '../common/divider/Divider';
import {useOutsideClick} from '../../hooks/useOutsideCLick';
import CustomButton from '../common/button/CustomButton';
import CustomCheckbox from '../common/checkbox/CustomCheckbox';
import {getAllPermissions, getPermissionsData, Permission} from './permissionModalUtils';
import {CompanyPermission, CompanyPermissionType, CompanyPermissionUpdater, CompanyRoleType} from '../../types/company';
import PermissionModalHeader from './PermissionModalHeader';
import {CompanyConverter} from '../../converters/api/CompanyConverter';

interface PermissionModal {
    isOpen: boolean;
    role?: CompanyRoleType;
    onClose: () => void;
    permissions?: CompanyPermission;
    onSave: (permission: CompanyPermissionUpdater) => void;
    loading: boolean;
}


const PermissionModal: FC<PermissionModal> = ({
    isOpen, role, onClose, permissions, onSave, loading
}) => {
    if (!isOpen) return <></>;

    const permissionsData = getPermissionsData();

    const [selectedPermissions, setSelectedPermissions] = useState<CompanyPermissionType[]>([]);
    const [changed, setChanged] = useState<boolean>(false);

    const modalContentRef = useRef<HTMLDivElement>(null);

    useOutsideClick([modalContentRef], () => { onClose(); });

    const handleCheckboxChange = (permission: CompanyPermissionType | CompanyPermissionType[]) => {
        if (Array.isArray(permission)) {
            const allSelected = permission.every((perm) => selectedPermissions.includes(perm));
            setSelectedPermissions((prev) =>
                allSelected
                    ? prev.filter((item) => !permission.includes(item)) // Unselect all in category
                    : [...prev, ...permission.filter((perm) => !prev.includes(perm))] // Select all in category
            );
        } else {
            setSelectedPermissions((prev) =>
                prev.includes(permission)
                    ? prev.filter((item) => item !== permission)
                    : [...prev, permission]
            );
        }

        !changed && setChanged(true);
    };

    const getCheckBoxLabel = (category: string) => {
        if (category === 'General') return i18n.t('common.general');
        if (category === 'Links') return i18n.t('common.links');
        if (category === 'Contact') return i18n.t('common.contact');
        if (category === 'Other') return i18n.t('common.other');
        return '';
    };

    const setDefaultPrivileges = () => {
        const perms = permissions?.permissions;
        perms && setSelectedPermissions(perms);
    };

    const handleSavePermissions = () => {
        if (!role) return;

        const updatePermission: CompanyPermissionUpdater = {
            role: CompanyConverter.convertFromCompanyRoleToCompanyRoleApi(role),
            permissionTypes: selectedPermissions
        };

        onSave(updatePermission);
        setChanged(false);
    };

    useEffect(() => {
        setDefaultPrivileges();
    }, [permissions]);

    return (
        <ModalContainer className={styles.modalContainer}>
            <div ref={modalContentRef} className={styles.container}>
                <PermissionModalHeader
                    role={role}
                    onClose={onClose}
                />
                <div className={styles.content}>
                    <form className={styles.form}>
                        {Object.entries(permissionsData).map(([category, permissions]) => {
                            const allPermissions: CompanyPermissionType[] = getAllPermissions(permissions);
                            const allSelected = allPermissions.every((perm) => selectedPermissions.includes(perm));

                            return (
                                <div key={category} className={styles.permissionCategory}>
                                    {/* Category Checkbox */}
                                    <CustomCheckbox
                                        label={getCheckBoxLabel(category)}
                                        value={category}
                                        isChecked={allSelected}
                                        onCheck={() => handleCheckboxChange(allPermissions)}
                                        className={styles.permissionTextHeader}
                                    />

                                    {/* Individual Permissions */}
                                    <div className={styles.permissions}>
                                        <div className={styles.leftColumn}>
                                            {permissions.left.map((permission: Permission) => (
                                                <CustomCheckbox
                                                    key={permission.value}
                                                    label={permission.name}
                                                    value={permission.name}
                                                    isChecked={selectedPermissions.includes(permission.value)}
                                                    onCheck={() => handleCheckboxChange(permission.value)}
                                                />
                                            ))}
                                        </div>
                                        <div className={styles.rightColumn}>
                                            {permissions.right.map((permission: Permission) => (
                                                <CustomCheckbox
                                                    key={permission.value}
                                                    label={permission.name}
                                                    value={permission.name}
                                                    isChecked={selectedPermissions.includes(permission.value)}
                                                    onCheck={() => handleCheckboxChange(permission.value)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </form>
                </div>
                <Divider/>
                <div className={styles.footer}>
                    {!loading &&
                        <CustomButton
                            text={i18n.t('common.discard')}
                            onClick={setDefaultPrivileges}
                            sx={`${styles.btnDiscard}`}
                            disabled={loading}
                        />
                    }
                    <CustomButton
                        text={i18n.t('common.save')}
                        onClick={handleSavePermissions}
                        sx={styles.btnDark}
                        loading={loading}
                        disabled={loading || !changed}
                    />
                </div>
            </div>
        </ModalContainer>
    );
};

export default PermissionModal;