import {CSSProperties} from 'react';

export const getNotesStyle = (position: { top: number; left: number }): CSSProperties => ({
    position: 'absolute',
    top: position.top,
    left: position.left,
    width: '300px',
    background: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '8px',
    boxShadow: '0px 4px 8px 0px #0000000F',
    zIndex: 9999
});

export const getMenuStyle = (position: { top: number; left: number } | undefined): CSSProperties => ({
    position: 'absolute',
    top: position?.top,
    left: position?.left,
    width: '40px',
    zIndex: 9999
});