import React, {FC, useRef} from 'react';
import useChangelogController from './useChangelogController';
import ReleaseNoteEntry from './ReleaseNoteEntry';
import styles from './changelog.module.scss';
import i18n from '../../translations/i18n';
import Contents from './contents/Contents';
import HomeHeader from '../../components/layout/HomeHeader/HomeHeader';
import {notEmpty} from '../../utils/common';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';

const Changelog: FC = () => {
    const { loading, releaseNotes } = useChangelogController();
    const  isSmallScreen  = useIsSmallScreen();
    const noteRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

    const scrollToReleaseNote = (id: number) => {
        if (noteRefs.current[id]) {
            noteRefs.current[id]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    if (loading) return <></>;

    return (
        <>
            {!isSmallScreen && <HomeHeader/>}
            <div className={styles.container}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                        {i18n.t('header.changelog')}
                    </div>
                    <div className={styles.desc}>
                        {i18n.t('common.featuresAndImprovements')}
                    </div>
                </div>
                <div className={styles.screen}>
                    <div className={styles.leftScreen}>
                        {releaseNotes.map(r => (
                            <div key={r.id} ref={(el) => (noteRefs.current[r.id] = el)}>
                                <ReleaseNoteEntry releaseNote={r}/>
                            </div>
                        ))}
                    </div>
                    <div className={styles.rightScreen}>
                        {notEmpty(releaseNotes) &&
                            <Contents
                                releaseNotes={releaseNotes}
                                onClickItem={scrollToReleaseNote}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Changelog;