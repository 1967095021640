import React, {FC, useState} from 'react';
import {deleteExchangeContactFromContactsSection} from '../../../api/exchangeContact';
import {ERROR, SUCCESS} from '../../../constants/common';
import i18n from '../../../translations/i18n';
import {useAlert} from '../../../context/AlertProvider';
import {WarningModal} from '../../../components/common/warningModal/WarningModal';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning/warning.svg';

interface DeleteContactModalProps {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    contactId: number;
}

const DeleteContactModal: FC<DeleteContactModalProps> = ({
    open, onClose, onCancel, contactId
}) => {
    const {setAlert} = useAlert();
    const [loading, setLoading] = useState<boolean>(false);

    const handleOnConfirmDeleteModal = () => {
        setLoading(true);
        deleteExchangeContactFromContactsSection(contactId)
            .then(res => {
                if (res.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                } else {
                    setAlert(SUCCESS, i18n.t('messages.contactSuccessfullyRemoved'));
                    onClose();
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <WarningModal
            isOpen={open}
            icon={<WarningIcon/>}
            title={i18n.t('common.deleteContact')}
            text={i18n.t('modal.areYouSureDeleteText')}
            confirmBtnText={i18n.t('common.delete')}
            cancelBtnText={i18n.t('common.cancel')}
            onConfirm={handleOnConfirmDeleteModal}
            onCancel={onCancel}
            loading={loading}
        />
    );
};

export default DeleteContactModal;