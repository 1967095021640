import React, {FC} from 'react';
import styles from './contacts.module.scss';
import {CardProfileAddress, CardProfileContact} from '../../../types/cardProfile';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as WebsiteIcon } from '../../../assets/icons/website.svg';
import { ReactComponent as AppointmentIcon } from '../../../assets/icons/appointment.svg';
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/whatsapp.svg';
import i18n from '../../../translations/i18n';
import {getContactUrl, getLocationUrl} from './contactsUtils';
import {isPhoneNumber, notBlank} from '../../../utils/common';
import {APPOINTMENT_BOOKING} from '../../../types/contacts';
import {
    isAppointmentBookingType,
    isEmailContactType,
    isPhoneContactType,
    isWebsiteContactType,
    isWhatsAppContactType
} from '../../../utils/contact/contact';

interface ContactsProps {
    profileContacts: CardProfileContact[],
    profileAddresses?: CardProfileAddress,
    style: object,
    textStyle: object
    iconStyle: object 
}

const Contacts: FC<ContactsProps> = ({
    profileContacts, profileAddresses, style, iconStyle, textStyle
}) => {

    const getContactIcon = (contact: CardProfileContact) => {
        const type = contact.type;

        if (isPhoneContactType(type)) return <PhoneIcon style={iconStyle} />;
        if (isEmailContactType(type)) return <MailIcon style={iconStyle} />;
        if (isWebsiteContactType(type)) return <WebsiteIcon style={iconStyle} />;
        if (isAppointmentBookingType(type)) return <AppointmentIcon style={iconStyle} />;
        if (isWhatsAppContactType(type)) return <WhatsAppIcon style={iconStyle} />;
        return <></>;
    };

    const getProfileAddress = (country: string | undefined, city: string | undefined) => {
        if (notBlank(country) && notBlank(city)) return `${country}, ${city}`;
        if (notBlank(country)) return country;
        if (notBlank(city)) return city;
        return '';
    };

    const getDisplayContactText = (contact: CardProfileContact) => {
        if (isAppointmentBookingType(contact.type)) return i18n.t('common.appointmentBooking');
        if (isWhatsAppContactType(contact.type)) {
            return isPhoneNumber(contact.details)
                ? `${contact.details} (${i18n.t('common.whatsapp')})`
                : i18n.t('common.whatsapp');
        }
        return contact.details;
    };

    return (
        <div className={styles.contactWrapper}>
            <div className={`${styles.text} ${styles.contactText}`} style={textStyle}>
                {i18n.t('common.contact')}
            </div>
            {profileContacts.filter(contact => notBlank(contact.type) && notBlank(contact.details)).map(contact => {
                const isAppointment = contact.type === APPOINTMENT_BOOKING;

                return (
                    <a href={getContactUrl(contact)} target='_blank' className={`${styles.contact} ${isAppointment && styles.pointer}`} key={contact.id}>
                        <div className={`${styles.logoWrapper} ${styles.contactIconWrapper}`} style={style}>
                            {getContactIcon(contact)}
                        </div>
                        <div className={`${styles.text} ${styles.contactTextDetails}`} style={textStyle}>
                            {getDisplayContactText(contact)}
                        </div>
                    </a>
                );
            })}
            {profileAddresses &&
                <a className={styles.contact} key={profileAddresses.id} href={getLocationUrl(profileAddresses)} target='_blank'>
                    {(profileAddresses.country || profileAddresses.city) &&
                        <>
                            <div className={`${styles.logoWrapper} ${styles.contactIconWrapper}`} style={style}>
                                <LocationIcon style={iconStyle} />
                            </div>
                            <div className={`${styles.text} ${styles.contactTextDetails}`} style={textStyle}>
                                {getProfileAddress(profileAddresses.country, profileAddresses.city)}
                            </div>
                        </>
                    }
                </a>
            }
        </div>
    );
};

export default Contacts;