import React, {FC, useRef, useState} from 'react';
import ModalContainer from '../../../components/common/modalContainer/ModalContainer';
import styles from '../../previewCardProfile/exchangeContact/exchange-contact.module.scss';
import i18n from '../../../translations/i18n';
import CloseButton from '../../../components/common/button/CloseButton';
import Divider from '../../../components/common/divider/Divider';
import {Input} from '../../../components/common/input/Input';
import {TextArea} from '../../../components/common/textArea/TextArea';
import { ReactComponent as MailIcon } from '../../../assets/icons/small-mail.svg';
import { ReactComponent as BriefcaseIcon } from '../../../assets/icons/briefcase.svg';
import { ReactComponent as PhoneCallIcon } from '../../../assets/icons/phone-call.svg';
import { ReactComponent as WorldIcon } from '../../../assets/icons/world.svg';
import BtnContainer from '../../../components/common/button/BtnContainer';
import {
    ExchangeContactFormData, INITIAL_CONTACT_FORM_DATA
} from '../../previewCardProfile/exchangeContact/ExchangeContact';
import useEditContactController from './useEditContactController';
import PrivacyPolicyCheckbox from '../../../components/common/checkbox/PrivacyPolicyCheckbox';
import {ExchangeContactData} from '../../../types/exchangeContacts';
import {getChangedFields} from './editContactModalUtils';
import {TEXT} from '../../../types/input';
import UploadOrTakePhoto from '../../previewCardProfile/exchangeContact/UploadOrTakePhoto';


interface EditContactModalProps {
    contactId: number;
    open: boolean;
    onClose: () => void;
    exchangeContact: ExchangeContactFormData;
    addUpdatedExchangeContact: (updatedContact: ExchangeContactData) => void;
}

const EditContactModal: FC<EditContactModalProps> = ({
    contactId, open, onClose, exchangeContact, addUpdatedExchangeContact
}) => {
    const [formData, setFormData] = useState<ExchangeContactFormData>(exchangeContact);
    const [checkPolicy, setCheckPolicy] = useState<boolean>(false);
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const [userImage, setUserImage] = useState<File | undefined>(undefined);
    const modalRef = useRef<HTMLDivElement>(null);

    const { loading, updateExchangeContactApi } = useEditContactController();

    const handleUpdateFormData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const onApiCallFinish = (contact: ExchangeContactData | undefined) => {
        onClose();
        setFormData(INITIAL_CONTACT_FORM_DATA);
        contact && addUpdatedExchangeContact(contact);
    };

    const handleCheckPolicy = () => {
        setSaveDisabled(checkPolicy);
        setCheckPolicy(prevState => !prevState);
    };

    const handleSave = () => {
        const changedFields = getChangedFields(exchangeContact, formData);
        if (Object.keys(changedFields).length === 0 && !userImage) return;
        updateExchangeContactApi(contactId, changedFields, onApiCallFinish, userImage);
    };

    if (!open) return <></>;

    return (
        <ModalContainer className={styles.modalContainer}>
            <div ref={modalRef} className={styles.container}>
                <div className={styles.modalHeader}>
                    <div className={styles.title}>
                        <div>{i18n.t('common.editContact')}</div>
                        <CloseButton onClose={onClose}/>
                    </div>
                    <Divider/>
                </div>
                <div className={styles.body}>
                    <UploadOrTakePhoto
                        onPhotoChange={setUserImage}
                        profilePhoto={userImage}
                        initialUserPhoto={exchangeContact.profileImagePath}
                    />
                    <div className={styles.name}>
                        <Input
                            label={i18n.t('labels.firstName')}
                            type={TEXT}
                            name={'firstName'}
                            value={formData.firstName}
                            onChange={handleUpdateFormData}
                            className={styles.input}
                            max={32}
                        />
                        <Input
                            label={i18n.t('labels.lastName')}
                            type={TEXT}
                            name={'lastName'}
                            value={formData.lastName}
                            onChange={handleUpdateFormData}
                            className={styles.input}
                            max={32}
                        />
                    </div>
                    <div>
                        <Input
                            label={i18n.t('common.email')}
                            type={TEXT}
                            name={'email'}
                            value={formData.email}
                            onChange={handleUpdateFormData}
                            className={styles.input}
                            max={64}
                            icon={<MailIcon/>}
                        />
                    </div>
                    <Input
                        label={i18n.t('common.phone')}
                        type={TEXT}
                        name={'phone'}
                        value={formData.phone}
                        onChange={handleUpdateFormData}
                        className={styles.input}
                        max={32}
                        icon={<PhoneCallIcon/>}
                    />
                    <Input
                        label={i18n.t('labels.company')}
                        type={TEXT}
                        name={'company'}
                        value={formData.company}
                        onChange={handleUpdateFormData}
                        className={styles.input}
                        max={64}
                        icon={<BriefcaseIcon/>}
                    />
                    <Input
                        label={i18n.t('common.website')}
                        type={TEXT}
                        name={'website'}
                        value={formData.website}
                        onChange={handleUpdateFormData}
                        className={styles.input}
                        max={64}
                        icon={<WorldIcon/>}
                    />
                    <TextArea
                        label={i18n.t('common.notes')}
                        name={'notes'}
                        value={formData.notes}
                        onChange={handleUpdateFormData}
                        rows={3}
                        maxLength={180}
                        className={styles.notes}
                    />
                    <PrivacyPolicyCheckbox
                        checked={checkPolicy}
                        onCheck={handleCheckPolicy}
                    />
                </div>
                <Divider/>
                <div className={styles.bottom}>
                    <div className={styles.btnWrapper}>
                        <BtnContainer
                            onCancel={onClose}
                            onSave={handleSave}
                            loading={loading}
                            isDisabled={saveDisabled}
                            saveBtnText={i18n.t('common.save')}
                        />
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};

export default EditContactModal;