import {CardProfileSocialLink} from '../../types/cardProfile';
import {
    COMPANY_LINKEDIN, CUSTOM_LINK,
    FACEBOOK, GITHUB,
    INSTAGRAM, PERSONAL_LINKEDIN,
    PINTEREST,
    REDDIT,
    SNAPCHAT,
    SocialLinks,
    THREADS,
    TIKTOK,
    X,
    YOUTUBE
} from '../../types/socialLinks';
import {
    canEditCompanyLinkedin,
    canEditCustomLink,
    canEditFacebook,
    canEditGitHub,
    canEditInstagram,
    canEditPersonalLinkedin,
    canEditPinterest,
    canEditReddit,
    canEditSnapchat,
    canEditThreads,
    canEditTikTok,
    canEditX,
    canEditYoutube
} from '../../store/utils/company';

export const options = [
    FACEBOOK, COMPANY_LINKEDIN, PERSONAL_LINKEDIN, INSTAGRAM, TIKTOK, X,
    YOUTUBE, SNAPCHAT, THREADS, PINTEREST, REDDIT, GITHUB, CUSTOM_LINK
];

export const MAX_NOOF_CUSTOM_LINKS = 5;

export const getMenuItemStyles = (option: string, links: CardProfileSocialLink[], isMaxNoofCustomLinksSelected: boolean) => {
    const isSelected = linkTypeAlreadySelected(option, links, isMaxNoofCustomLinksSelected);
    const cursor = isSelected ? 'default' : '';
    
    return {
        fontWeight: isSelected ? 'bold' : 'normal',
        cursor: cursor
    };
};

export const isCustomLinkType = (type: string) => {
    const t = type?.trim().toLowerCase();

    return t === CUSTOM_LINK.toLowerCase();
};

export const linkTypeAlreadySelected = (option: string, links: CardProfileSocialLink[], isMaxNoofCustomLinksSelected: boolean): boolean => {
    if (isCustomLinkType(option)) {
        return isMaxNoofCustomLinksSelected;
    }

    return links.some(link => link.type === option);
};


export const getAvailableSelectorLinkOptions = (isCompanyCard: boolean, links: CardProfileSocialLink[], isMaxNoofCustomLinksSelected: boolean) => (
    options.filter(o => {
        return (!isCompanyCard || canEditLink(o as SocialLinks)) && !linkTypeAlreadySelected(o, links, isMaxNoofCustomLinksSelected);
    })
);

export const canEditLink = (type: SocialLinks) => {
    if (type === COMPANY_LINKEDIN) return canEditCompanyLinkedin();
    if (type === PERSONAL_LINKEDIN) return canEditPersonalLinkedin();
    if (type === X) return canEditX();
    if (type === INSTAGRAM) return canEditInstagram();
    if (type === TIKTOK) return canEditTikTok();
    if (type === FACEBOOK) return canEditFacebook();
    if (type === YOUTUBE) return canEditYoutube();
    if (type === SNAPCHAT) return canEditSnapchat();
    if (type === PINTEREST) return canEditPinterest();
    if (type === THREADS) return canEditThreads();
    if (type === REDDIT) return canEditReddit();
    if (type === GITHUB) return canEditGitHub();
    if (type === CUSTOM_LINK) return canEditCustomLink();

    return true;
};
