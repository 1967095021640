import React, {FC} from 'react';
import {ReleaseNote} from '../../types/releaseNote';
import MarkdownRenderer from '../../components/markdownRenderer/MarkdownRenderer';
import styles from './release-note-entry.module.scss';
import {notEmpty} from '../../utils/common';

interface ReleaseNoteProps {
    releaseNote: ReleaseNote;
}

const ReleaseNoteEntry: FC<ReleaseNoteProps> = ({
    releaseNote
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.titleWrapper}>
                <div className={styles.title}>
                    {releaseNote.title}
                </div>
                <div className={styles.date}>
                    {releaseNote.releaseDate}
                </div>
            </div>
            <div className={styles.summary}>
                {releaseNote.summary}
            </div>
            {notEmpty(releaseNote.images) &&
                <div className={styles.images}>
                    {releaseNote.images.map((img, index) => (
                        <img
                            className={styles.img}
                            key={index}
                            alt="image"
                            src={img.url}
                            width={img.width}
                        />
                    ))}
                </div>
            }
            <div>
                <MarkdownRenderer richText={releaseNote.features}/>
            </div>
        </div>
    );
};

export default ReleaseNoteEntry;