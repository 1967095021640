import {configDefaultWithToken, getApiCall} from './base';


const appConfigUrl = '/api/config';
const appVersionUrl = `${appConfigUrl}/version`;

export const getWebappVersion = async () => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await getApiCall(appVersionUrl, config);
};