import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../help-desk.module.scss';
import useHelpDeskController from '../useHelpDeskController';
import MarkdownRenderer from '../../../components/markdownRenderer/MarkdownRenderer';
import { ReactComponent as EnvelopeIcon } from '../../../assets/icons/envelope.svg';
import { formatDate } from '../../../utils/date';
import { navigateTo } from '../../../constants/routes';
import i18n from '../../../translations/i18n';
import LoadingIcards from '../../loading/LoadingIcards';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';
import HomeHeader from '../../../components/layout/HomeHeader/HomeHeader';

interface HelpDeskArticleProps {
    toggleContactWidget?: () => void;
}

const HelpDeskArticle = ({ toggleContactWidget }: HelpDeskArticleProps) => {
    const isSmallScreen  = useIsSmallScreen();
    const navigate = useNavigate();
    const { categorySlug, articleSlug } = useParams<{
        categorySlug: string;
        articleSlug: string;
    }>();
    const { article, loadingArticle, categories } = useHelpDeskController(
        categorySlug,
        articleSlug
    );

    const navigateToCategory = (slug: string) => {
        navigate(`${navigateTo.helpDesk}/${slug}`);
    };

    return (
        <>
            {!isSmallScreen && <HomeHeader/>}
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    {loadingArticle ? (
                        <LoadingIcards className={styles.loading} fill='#fff'/>
                    ) : (
                        <>
                            <div>
                                <MarkdownRenderer richText={String(article?.content)} />
                            </div>
                            <div className={styles.footer}>
                                <div className={styles.footerLeft}>
                                    <EnvelopeIcon />
                                    <p className={styles.text}>{i18n.t('common.stillNeedHelp')}</p>
                                    <a className={styles.contact} onClick={toggleContactWidget}>
                                        {i18n.t('common.contactUs')}
                                    </a>
                                </div>
                                <div className={styles.text}>
                                    {i18n.t('common.lastUpdate')} {formatDate(String(article?.updatedAt))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.categoriesTable}>
                    <div className={styles.tableTitle}>{i18n.t('common.categories').toUpperCase()} </div>
                    {categories.map((category) => (
                        <span
                            key={category.slug}
                            className={styles.categoryTitle}
                            onClick={() => navigateToCategory(category.slug)}
                        >
                            {category.title}
                        </span>
                    ))}
                </div>
            </div>
        </>
    );
};

export default HelpDeskArticle;
