import {useCallback, useEffect, useState} from 'react';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {ExchangeContactConverter} from '../../../converters/api/ExchangeContactConverter';
import {UserIntegrationStatus} from '../../../types/exchangeContacts';
import {getUserIntegrationStatus} from '../../../api/salesforce';
import {getInitUserIntegrationStatus} from '../../../pages/contacts/contactsUtils';


const useIntegrationsController = () => {
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState<boolean>(false);
    const [integrationStatus, setIntegrationStatus] = useState<UserIntegrationStatus>(getInitUserIntegrationStatus());

    const isSalesforceIntegratedApi = useCallback((userId: number, loading = true) => {
        loading && setLoading(true);
        getUserIntegrationStatus(userId)
            .then(res => {
                const data = ExchangeContactConverter.convertFromIntegrationStatusApiToUserIntegrationStatus(res.data);
                data && setIntegrationStatus(data);
            })
            .finally(() => loading && setLoading(false));
    }, [user]);

    const reloadIntegrationStatus = () => isSalesforceIntegratedApi(user.id, false);

    useEffect(() => {
        if (loading || !user) return;

        isSalesforceIntegratedApi(user.id);
    }, [user]);


    return {
        loading,
        integrationStatus,
        reloadIntegrationStatus
    };
};

export default useIntegrationsController;