import {eyeSvgCode} from './eyeSvgCode';

export const getQrCodeEyeSize = (): string => {
    return '63';
};

export const getQrCodeEyeSizeCordinates = (): {x: string, y: string} => {
    return {x: '235', y: '0'};
};

export const getQrCodeEyeElement = (
    eyeSvgCode: string,
    eyeSize: string,
    xCoordinate: string,
    yCoordinate: string
): SVGSVGElement => {
    const parser = new DOMParser();
    const eyeSvgDoc = parser.parseFromString(eyeSvgCode, 'image/svg+xml');
    const eyeElement = eyeSvgDoc.documentElement as unknown as SVGSVGElement;

    eyeElement.setAttribute('x', xCoordinate);
    eyeElement.setAttribute('y', yCoordinate);
    eyeElement.setAttribute('width', eyeSize);
    eyeElement.setAttribute('height', eyeSize);

    return eyeElement;
};

export const replacePlaceholderImageWithLogo = (
    svgElement: SVGSVGElement,
    logoSvgCode: string
) => {
    const imageElement = svgElement.querySelector('image');
    if (imageElement) {
        const x = imageElement.getAttribute('x') || '0';
        const y = imageElement.getAttribute('y') || '0';
        const width = imageElement.getAttribute('width') || '0';
        const height = imageElement.getAttribute('height') || '0';

        const parser = new DOMParser();
        const logoDoc = parser.parseFromString(logoSvgCode, 'image/svg+xml');
        const logoElement = logoDoc.documentElement as unknown as SVGSVGElement;

        logoElement.setAttribute('x', x);
        logoElement.setAttribute('y', y);
        logoElement.setAttribute('width', width);
        logoElement.setAttribute('height', height);

        imageElement.parentNode?.replaceChild(logoElement, imageElement);
    }
};

export const replaceEyesWithSVGs = (svg: SVGSVGElement) => {
    const rect00 = svg.querySelector('rect[clip-path^="url(\'#clip-path-corners-square-color-0-0"]');
    const rect01 = svg.querySelector('rect[clip-path^="url(\'#clip-path-corners-square-color-0-1"]');
    const rect02 = svg.querySelector('rect[clip-path^="url(\'#clip-path-corners-square-color-1-0"]');
    const eyeSize = getQrCodeEyeSize();
    const eyeCordinates = getQrCodeEyeSizeCordinates();
    const eyeImage = eyeSvgCode;

    if (rect00) {
        const customImage00 = getQrCodeEyeElement(eyeImage, eyeSize, eyeCordinates.y, eyeCordinates.y);
        rect00.parentNode?.replaceChild(customImage00, rect00);
    }

    if (rect01) {
        const customImage01 = getQrCodeEyeElement(eyeImage, eyeSize, eyeCordinates.y, eyeCordinates.x);
        rect01.parentNode?.replaceChild(customImage01, rect01);
    }

    if (rect02) {
        const customImage02 = getQrCodeEyeElement(eyeImage, eyeSize, eyeCordinates.x, eyeCordinates.y);
        rect02.parentNode?.replaceChild(customImage02, rect02);
    }
};