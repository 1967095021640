import {Company} from '../../../types/company';
import {isUserCompanyAdmin} from '../../../utils/company';

export const getCompanyItemsWhereUserIsAdmin = (companies: Company[]) => (
    companies
        .filter(c => isUserCompanyAdmin(c))
        .map((c: Company) => ({value: c.id, name: c.name}))
);


export const getCompanyItems = (companies: Company[]) => (
    companies.map((c: Company) => ({value: c.id, name: c.name}))
);