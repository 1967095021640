import {getApiCall, strapiUrl} from '../base';
import {FRENCH_VALUE, GERMAN_VALUE} from '../../types/language';

const categoriesUrl = `${strapiUrl}/api/categories?sort=publishedAt:asc`;
const articlesUrl = `${strapiUrl}/api/articles?sort=publishedAt:asc`;

const getStrapiLocale = (language: string) => {
    const lang = language.toUpperCase();
    if (lang === GERMAN_VALUE) return 'de-DE';
    if (lang === FRENCH_VALUE) return 'fr-FR';
    return 'en';
};

export const getCategories = async (language: string) => {

    const config = {
        params: {
            locale: getStrapiLocale(language),
            fields: ['id', 'title', 'slug', 'description'],
            populate: {
                icon: {
                    fields: ['url', 'width']
                },
                articles: {
                    fields: ['title']
                }
            }
        }
    };

    return await getApiCall(categoriesUrl, config);
};

export const getCategoryBySlug = async (language: string, slug: string) => {
    const config = {
        params: {
            locale: getStrapiLocale(language),
            filters: {
                slug: {
                    $eq: slug, 
                },
            },
            fields: ['id', 'title', 'slug', 'description'],
            populate: {
                icon: {
                    fields: ['url', 'width']
                },
                articles: {
                    fields: ['title', 'slug', 'locale', 'updatedAt'],
                },
            },
        },
    };

    return await getApiCall(categoriesUrl, config);
};

export const getArticleBySlug = async (language: string, articleSlug: string) => {

    const config = {
        params: {
            locale: getStrapiLocale(language),
            filters: {
                slug: {
                    $eq: articleSlug,
                },
            },
            fields: ['id', 'title', 'slug', 'content', 'locale','updatedAt'],
        },
    };

    return await getApiCall(articlesUrl, config);
};