import React, {FC, useEffect, useState} from 'react';
import styles from './share-modal.module.scss';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share-link.svg';
import i18n from '../../../translations/i18n';
import { ICARDS_FULL_URL } from '../../../constants/common';
import CloseButton from '../button/CloseButton';
import useQrcodeGenerator from '../../qrcode/useQrcodeGenerator';
import {downloadFile} from '../../../utils/download';

interface ShareModalProps {
    isOpen: boolean;
    uuid: string
    onClose: () => void;
    fullName?: string
}

export const ShareModal: FC<ShareModalProps> = ({
    isOpen, uuid, onClose, fullName
}) => {
    if (!isOpen) return <></>;

    const [copied, setCopied] = useState(false);
    const { qrCodeRef, qrCodeInstance } = useQrcodeGenerator(uuid, true);

    const handleCopy = () => {
        navigator.clipboard.writeText(`${ICARDS_FULL_URL}/${uuid}`);
        setCopied(true);
    };

    const handleDownload = () => {
        if (!qrCodeInstance) return;

        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(qrCodeInstance, 'image/svg+xml');
        const svgElement = svgDoc.querySelector('svg');

        if (svgElement) {
            svgElement.setAttribute('width', '300');
            svgElement.setAttribute('height', '300');
            const updatedSvgString = new XMLSerializer().serializeToString(svgElement);
            const blob = new Blob([updatedSvgString], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);

            downloadFile(url, `${fullName || 'QRCode'}.svg`);
            URL.revokeObjectURL(url);
        }
    };

    useEffect(() => {
        if (qrCodeRef.current && qrCodeInstance) {
            qrCodeRef.current.innerHTML = '';
            qrCodeRef.current.insertAdjacentHTML('afterbegin', qrCodeInstance);
        }
    }, [qrCodeInstance]);

    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <div className={styles.headerTxt}>
                        {i18n.t('common.shareCard')}
                    </div>
                    <CloseButton onClose={onClose}/>
                </div>
                <div className={styles.boxLinkWrapper}>
                    <div className={styles.left}>
                        <div className={styles.icon}>
                            <ShareIcon/>
                        </div>
                        <div className={styles.link}>
                            {ICARDS_FULL_URL}/{uuid}
                        </div>
                    </div>
                    <div className={styles.rightText} onClick={handleCopy}>
                        {copied ? i18n.t('common.copied') : i18n.t('common.copy')}
                    </div>
                </div>
                <div className={styles.boxWrapper}>
                    <div ref={qrCodeRef} className={styles.qrCodeImg} />
                    <div className={styles.qrCodeTxt}>
                        <div className={styles.qrCodeTitleTxt}>{i18n.t('common.yourBusinessCard')}</div>
                        <div>{i18n.t('common.qrCode')}</div>
                    </div>
                    <div onClick={handleDownload} className={`${styles.rightText}, ${styles.downloadText}`}>
                        {i18n.t('common.download')}
                    </div>
                </div>
            </div>
        </div>
    );
};