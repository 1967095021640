import React, {FC} from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {getGridStyle} from './custom-grid-data.style';
import {Pagination} from '@mui/material';

interface CustomDataGridProps {
    columns: GridColDef<[number]>[];
    rows: any[];
    totalPages: number;
    handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
    page: number;
}

const CustomDataGrid: FC<CustomDataGridProps> = ({
    columns, rows, totalPages, handlePageChange, page
}) => {

    return (
        <>
            <DataGrid
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
                getRowHeight={() => 'auto'}
                sx={getGridStyle}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                disableColumnMenu
            />
            {totalPages > 1 &&
                <Pagination
                    page={page}
                    count={totalPages}
                    shape="rounded"
                    onChange={handlePageChange}
                    style={{alignSelf: 'center'}}
                />
            }
        </>
    );
};


export default CustomDataGrid;