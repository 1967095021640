import { FC } from 'react';
import styles from './save-button.module.scss';
import i18n from '../../../translations/i18n';
import CustomButton from '../../common/button/CustomButton';

interface SaveButtonProps {
    isDirty: boolean;
    loading: boolean;
    saveInfos: () => void;
    className?: string;
    classNameBtn?: string;
}

const SaveButton: FC<SaveButtonProps> = ({
    isDirty, loading, saveInfos, className, classNameBtn
}) => {

    return (
        <div className={className}>
            {( isDirty || loading )
                ? <div>
                    <CustomButton
                        sx={`${styles.btnDark} ${classNameBtn}`}
                        text={i18n.t('common.saveChanges')}
                        onClick={saveInfos}
                        loading={loading}
                    />
                </div>
                : <div>
                    <CustomButton
                        sx={classNameBtn}
                        text={i18n.t('common.changesSaved')}
                        onClick={()=>{}}
                    />
                </div>
            }
        </div>
    );
};

export default SaveButton;