import {ReleaseNote} from '../../types/releaseNote';
import {isEmpty} from '../../utils/common';
import {strapiUrl} from '../../api/base';
import {getFormattedDate} from '../../utils/date';

const extractImageUrls = (entry: any): string[] => {
    if (isEmpty(entry.images)) return [];
    return entry.images.map((image: any) =>( {
        url: `${strapiUrl}${image.url}`,
        width: image.width
    }));
};


export const ChangelogConverter = {
    convertFromReleaseNotesApiToReleaseNotes(data: any): ReleaseNote[] {
        if (isEmpty(data)) return [];

        return data.map((d: ReleaseNote) => ({
            id: d.id,
            title: d.title,
            releaseDate: getFormattedDate(d.releaseDate).toString(),
            summary: d.summary,
            version: d.version,
            features: d.features,
            images: extractImageUrls(d)
        })).sort((a:any, b:any) => new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime());
    }
};