import React, {FC, useMemo, useState} from 'react';
import HomeHeader from '../../components/layout/HomeHeader/HomeHeader';
import { ThemeProvider } from '../../context/theme/ThemeProvider';
import MyCardsHeader from '../../components/cards/myCardsHeader/MyCardsHeader';
import styles from './my-cards.module.scss';
import {
    CardProfileFilterStatus,
    DeleteModal,
    UserCardProfile,
    UserCardProfileFilter, UserCardProfilesPagination
} from '../../types/userCardProfile';
import {navigateTo} from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import {useDebouncedCallback} from 'use-debounce';
import {API_CALL_DEBOUNCE_TIME_IN_MS, GRID} from '../../constants/common';
import i18n from '../../translations/i18n';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning/warning.svg';
import {WarningModal} from '../../components/common/warningModal/WarningModal';
import {Pagination} from '@mui/material';
import EmptyCard from '../../components/EmptyCard/EmptyCard';
import {COMPANY_ID} from '../../store/utils/userSession';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import Divider from '../../components/common/divider/Divider';
import CardsGridView from './CardsGridView';
import CardsListView, {SelectAllObject} from './CardsListView';
import {FilerConverter} from '../../converters/api/FilterConverter';
import {removeCardFromCompany} from '../../api/companyAssign';
import {ViewSelectorType} from '../../components/cards/myCardsHeader/ViewSelector';
import {CardListProvider} from '../../context/CardListProvider';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import {getViewModeFromLocalStorage} from '../../storage/localStorage';
import LoadingIcards from '../loading/LoadingIcards';


const initialDeleteModal = {
    isOpen: false,
    cardProfileId: null,
};

export interface CardsProps {
    apiCalled: boolean;
    loading: boolean;
    apiFilter: UserCardProfileFilter,
    setApiFilter: React.Dispatch<React.SetStateAction<UserCardProfileFilter>>,
    cardProfilesPagination: UserCardProfilesPagination | null,
    handleDeleteCardProfile: (cardProfileId: number) => Promise<void>,
    isMyCards?: boolean,
    reloadCardsApi?: () => void
}

const Cards: FC<CardsProps> = ({
    apiCalled, loading, apiFilter, setApiFilter, cardProfilesPagination, handleDeleteCardProfile, isMyCards, reloadCardsApi
}) => {
    const navigate = useNavigate();
    const  isSmallScreen  = useIsSmallScreen();
    const [deleteModal, setDeleteModal] = useState<DeleteModal>(initialDeleteModal);
    const [viewMode, setViewMode] = useState<ViewSelectorType>(getViewModeFromLocalStorage() ?? GRID);
    const [selected, setSelected] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState<SelectAllObject>({selected: false, unselected: []});

    const user = useAppSelector(selectUser);

    const noofCardProfilesTotal = cardProfilesPagination?.totalElements ?? 0;
    const cardProfiles: UserCardProfile[] = cardProfilesPagination?.profiles ?? [];
    const userHasNoCardProfile = noofCardProfilesTotal < 1;
    const totalPages = cardProfilesPagination?.totalPages;
    const hasAnyCardProfileFound = cardProfiles.length > 0;
    const noofCardsSelected = selectAll.selected ? (noofCardProfilesTotal - selectAll.unselected.length) : selected.length;

    const companyId = useMemo(() => COMPANY_ID(), [user.activeCompany]);

    const onEdit = (cardProfileId: number) => navigate(`${navigateTo.profile}/${cardProfileId}`);
    const onPreview = (uuid: string) => {
        if (uuid) window.location.href = `${navigateTo.previewCardProfile}/${uuid}`;
    };

    const onSearchDebounced = useDebouncedCallback((text: string) => {
        return onSearch(text);
    }, API_CALL_DEBOUNCE_TIME_IN_MS);

    const onSearch = (text: string) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: 0,
            searchByName: text
        }));
    };

    const onSelect = (option: CardProfileFilterStatus) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: 0,
            status: option
        }));
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: page-1,
        }));
    };

    const handleOpenDeleteModal = (cardProfileId: number) => setDeleteModal({isOpen: true, cardProfileId});

    const handleCloseDeleteModal = () => setDeleteModal(initialDeleteModal);

    const handleOnConfirmDeleteModal = () => {
        if (!deleteModal.cardProfileId) return;
        handleDeleteCardProfile(deleteModal.cardProfileId)
            .finally(() => handleCloseDeleteModal());
    };

    const removeFromCompanyCards = async (cardProfileId: number) => {
        companyId && removeCardFromCompany([cardProfileId])
            .then(() => reloadCardsApi && reloadCardsApi());
    };

    const onViewSelect = (type: ViewSelectorType) => setViewMode(type);

    const toggleSelectAll = () => {
        if (selectAll.selected) {
            setSelected([]);
        }

        setSelectAll({selected: !selectAll.selected, unselected: []});
    };

    const addSelected = (newSelected: number) => {
        setSelected((prevSelected) =>
            prevSelected.includes(newSelected) ? prevSelected : [...prevSelected, newSelected]
        );

        setSelectAll((prevSelectAll) => {
            const unselectedSet = new Set(prevSelectAll.unselected);
            if (unselectedSet.has(newSelected)) {
                return {
                    ...prevSelectAll,
                    unselected: prevSelectAll.unselected.filter((item) => item !== newSelected)
                };
            } else {
                return { ...prevSelectAll };
            }
        });
    };

    const removeSelected = (toRemove: number) => {
        setSelectAll({
            selected: selectAll.selected,
            unselected: [...selectAll.unselected, toRemove]
        });

        setSelected((prevSelected) =>
            prevSelected.filter((item) => item !== toRemove)
        );
    };

    return (
        <CardListProvider
            selectAll={selectAll}
            selected={selected}
            setSelected={setSelected}
            addSelected={addSelected}
            removeSelected={removeSelected}
            noofCardsSelected={noofCardsSelected}
            toggleSelectAll={toggleSelectAll}
            reloadCardsApi={reloadCardsApi}
        >
            {!isSmallScreen && <HomeHeader />}
            <ThemeProvider>
                <div className={styles.container}>
                    <MyCardsHeader
                        onSearch={onSearchDebounced}
                        onSelect={onSelect}
                        onViewSelect={onViewSelect}
                        userHasCardProfile={cardProfilesPagination != null && !userHasNoCardProfile}
                        title={isMyCards ? i18n.t('header.personalCards') : i18n.t('header.companyCards')}
                        defaultViewMode={viewMode}
                        totalElements={noofCardProfilesTotal}
                        searchStatus={FilerConverter.convertFromCardProfileFilterStatusApiToCardProfileApiFilterStatus(apiFilter.status)}
                        searchText={apiFilter.searchByName ?? ''}
                        isMyCards={isMyCards ?? false}
                        loading={loading}
                    />
                    <div className={styles.dividerWrapper}><Divider/></div>
                    {loading
                        ? <LoadingIcards className={styles.loading} />
                        : <>
                            <EmptyProfile
                                userHasNoCardProfile={userHasNoCardProfile}
                                apiCalled={apiCalled}
                            />
                            {hasAnyCardProfileFound
                                ? (viewMode === GRID
                                    ? <CardsGridView
                                        cardProfiles={cardProfiles}
                                        onEdit={onEdit}
                                        onDelete={handleOpenDeleteModal}
                                        onPreview={onPreview}
                                        searchText={apiFilter.searchByName ?? ''}
                                        removeFromCompanyCards={removeFromCompanyCards}
                                        isMyCards={isMyCards}
                                        reloadCardsApi={reloadCardsApi}
                                    />
                                    : <CardsListView
                                        cardProfiles={cardProfiles}
                                        onEdit={onEdit}
                                        onDelete={handleOpenDeleteModal}
                                        onPreview={onPreview}
                                        searchText={apiFilter.searchByName ?? ''}
                                        removeFromCompanyCards={removeFromCompanyCards}
                                        isMyCards={isMyCards}
                                        reloadCardsApi={reloadCardsApi}
                                    />)
                                : <ICardsNotFound apiCalled={apiCalled} />
                            }
                        </>
                    }
                </div>
                {hasAnyCardProfileFound && totalPages && totalPages > 1 ? (
                    <div className={styles.pagination}>
                        <Pagination
                            count={totalPages}
                            shape="rounded"
                            onChange={handlePageChange}
                        />
                    </div>
                ): <></>}
                <WarningModal
                    isOpen={deleteModal.isOpen}
                    icon={<WarningIcon />}
                    title={i18n.t('modal.areYouSure')}
                    text={i18n.t('modal.deleteText')}
                    confirmBtnText={i18n.t('common.deleteCard')}
                    cancelBtnText={i18n.t('common.cancel')}
                    onConfirm={handleOnConfirmDeleteModal}
                    onCancel={handleCloseDeleteModal}
                />
            </ThemeProvider>
        </CardListProvider>
    );
};

export default Cards;


const ICardsNotFound: FC<{apiCalled: boolean}> = ({ apiCalled }) => {
    if (!apiCalled) return <></>;

    return (
        <>
            <div className={styles.noIcardsFound}>
                {i18n.t('messages.noIcardsFound')}
            </div>
        </>
    );
};

const EmptyProfile: FC<{userHasNoCardProfile:boolean, apiCalled: boolean}> = ({ userHasNoCardProfile, apiCalled }) => {
    if (!(userHasNoCardProfile && apiCalled)) return <></>;

    return (
        <div className={styles.emptyCard}>
            <EmptyCard/>
        </div>
    );
};