import {isBlank, isEmpty} from '../../utils/common';
import {isAndroid} from '../../utils/device';
import {getEncodedAndResizedProfilePhoto} from '../../utils/image';
import {
    CardProfile,
    CardProfileAbout,
    CardProfileAddress,
    CardProfileBasic,
    CardProfileContact,
    CardProfileSocialLink, CardProfileToggle
} from '../../types/cardProfile';
import {
    EMAIL,
    WORK_PHONE,
    WEBSITE,
    MOBILE_PHONE,
    OFFICE_PHONE,
    HOME_PHONE,
    WORK_EMAIL,
    HOME_EMAIL, APPOINTMENT_BOOKING, WHATSAPP
} from '../../types/contacts';
import {createWhatsAppLink} from '../../components/previewCardProfile/contacts/contactsUtils';

const trimAndLowerCase = (value: string | undefined) => value && value.trim().toLowerCase();

export const isEmptyProfile  = (cardProfileData: CardProfile) => {
    return isEmpty(cardProfileData.profileAbout) &&
        isEmpty(cardProfileData.profileSocialLinks) &&
        isEmpty(cardProfileData.profileContacts) &&
        isEmpty(cardProfileData.profileAddresses)&&
        isEmpty(cardProfileData.profileBasic?.company)&&
        isEmpty(cardProfileData.profileBasic?.position);
};

export const createVCard = (
    profileBasic: CardProfileBasic,
    profileContacts: CardProfileContact[],
    profileSocialLinks: CardProfileSocialLink[],
    base64Photo: string,
    profileAddresses?: CardProfileAddress,
    profileAbout?: CardProfileAbout ,
) => {
    const isAndroidDevice = isAndroid();

    const workPhoneNumber = profileContacts.find(c => trimAndLowerCase(c.type) === WORK_PHONE.toLowerCase())?.details?.trim();
    const mobilePhoneNumber = profileContacts.find(c => trimAndLowerCase(c.type) === MOBILE_PHONE.toLowerCase())?.details?.trim();
    const officePhoneNumber = profileContacts.find(c => trimAndLowerCase(c.type) === OFFICE_PHONE.toLowerCase())?.details?.trim();
    const homePhoneNumber = profileContacts.find(c => trimAndLowerCase(c.type) === HOME_PHONE.toLowerCase())?.details?.trim();
    const userEmail = profileContacts.find(c => trimAndLowerCase(c.type) === EMAIL.toLowerCase())?.details?.trim();
    const workEmail = profileContacts.find(c => trimAndLowerCase(c.type) === WORK_EMAIL.toLowerCase())?.details?.trim();
    const homeEmail = profileContacts.find(c => trimAndLowerCase(c.type) === HOME_EMAIL.toLowerCase())?.details?.trim();
    const urls = profileContacts.filter(c => trimAndLowerCase(c.type) === WEBSITE.toLowerCase()).map(c => c.details);
    const appointmentUrl = profileContacts.find(c => trimAndLowerCase(c.type) === APPOINTMENT_BOOKING.toLowerCase())?.details;
    const whatsApp = profileContacts.find(c => trimAndLowerCase(c.type) === WHATSAPP.toLowerCase())?.details;
    const whatsAppUrl = whatsApp ? createWhatsAppLink(whatsApp) : undefined;

    let itemIndex = 1;

    const appointmentUrlFormat = () => {
        if (isBlank(appointmentUrl)) return '';
        const result = isAndroidDevice
            ? `URL:${appointmentUrl}`
            : `item${itemIndex}.URL:${appointmentUrl}\nitem${itemIndex}.X-ABLABEL:Appointment Booking`;
        itemIndex++;
        return result;
    };

    const whatsAppUrlFormat = () => {
        if (isBlank(whatsAppUrl)) return '';
        const result = isAndroidDevice
            ? `URL:${whatsAppUrl}`
            : `item${itemIndex}.URL:${whatsAppUrl}\nitem${itemIndex}.X-ABLABEL:WhatsApp`;
        itemIndex++;
        return result;
    };

    if (isAndroidDevice) {
        urls.push(...profileSocialLinks.map(s => s.details));
    }

    const contact = {
        name: profileBasic.fullname,
        workPhone: workPhoneNumber ? workPhoneNumber : '',
        mobilePhone: mobilePhoneNumber? mobilePhoneNumber : '',
        officePhone: officePhoneNumber ? officePhoneNumber : '',
        homePhone:  homePhoneNumber ? homePhoneNumber : '',
        email: userEmail ? userEmail : '',
        workEmail: workEmail ? workEmail : '',
        homeEmail: homeEmail ? homeEmail: '',
        company: profileBasic.company,
        position: profileBasic.position ?? '',
        department: profileBasic.department ?? '',
        note: profileAbout?.text?.trim()?.replace(/\n/g, '\\n') ?? ''
    };

    const addresses = [{street: profileAddresses?.address, city: profileAddresses?.city, country: profileAddresses?.country, zip: profileAddresses?.zip }];
    const socialProfiles = profileSocialLinks
        .filter(social => social.details && social.details.trim() !== '')
        .map(social => ({
            type: social.type.toLowerCase(),
            value: social.details.trim(),
        }));

    const formatAddresses = (addresses: any) => {
        return addresses.map((address: any) => {
            const street = address.street || '';
            const city = address.city || '';
            const zip = address.zip || '';
            const country = address.country ? address.country.trim() : '';
            return `${street};${city};;${zip};${country}`;
        }).join('\nADR;:;;');
    };

    const formatSocialProfiles = (profiles: any) => {
        if (isAndroidDevice) return '';

        return profiles
            .map((profile: any) => `\nX-SOCIALPROFILE;type=${profile.type?.charAt(0).toUpperCase()}${profile.type?.slice(1).toLowerCase()}:${profile.value}`)
            .join('');
    };

    return (
        `BEGIN:VCARD
VERSION:3.0
N:${contact.name?.replace(/\s+/g, ';').split(';').reverse().join(';')};;;
FN:${contact.name}
NOTE:${contact.note}
ORG:${contact.company};${contact.department}
TITLE:${contact.position}
${contact.workPhone ? `TEL;TYPE=work,voice:${contact.workPhone}` : ''}
${contact.mobilePhone ? `TEL;TYPE=CELL,voice:${contact.mobilePhone}` : ''}
${contact.homePhone ? `TEL;TYPE=home,voice:${contact.homePhone}` : ''}
${contact.officePhone ? `TEL;TYPE=${isAndroidDevice ? 'X-office' : 'office'},voice:${contact.officePhone}` : ''}
EMAIL;TYPE=WORK:${contact.workEmail}
EMAIL;TYPE=${isAndroidDevice ? 'X-email' : 'INTERNET'}:${contact.email}
EMAIL;TYPE=HOME:${contact.homeEmail}
URL:${urls.join('\nURL:')}
${appointmentUrlFormat()}
${whatsAppUrlFormat()}
ADR;:;;${formatAddresses(addresses)}${formatSocialProfiles(socialProfiles)}
PHOTO;ENCODING=b;TYPE=PNG::${base64Photo}
END:VCARD`
    );
};

export const saveContact = async (
    profileContacts: CardProfileContact[],
    profileSocialLinks: CardProfileSocialLink[],
    profileBasic?: CardProfileBasic,
    profileAddresses?: CardProfileAddress,
    profileAbout?: CardProfileAbout,
    profilePhoto?: string,
    profileToggle?: CardProfileToggle,
    handleAddContactSaveTrack?: () => void,
) => {
    if (!profileBasic) return;

    const base64Photo = profilePhoto ? await getEncodedAndResizedProfilePhoto(profilePhoto, 200, 200) : '';
    const cleanedBase64Photo = base64Photo.replace(/\s/g, '');

    const vcard = createVCard(
        profileBasic,
        profileToggle?.contact ? profileContacts : [],
        profileToggle?.socialLinks ? profileSocialLinks : [],
        cleanedBase64Photo,
        profileToggle?.contact ? profileAddresses : undefined,
        profileToggle?.about ? profileAbout : undefined
    );

    const blob = new Blob([vcard], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);

    const newLink = document.createElement('a');
    newLink.download = `${profileBasic.fullname}.vcf`;
    newLink.textContent = profileBasic.fullname;
    newLink.href = url;

    newLink.click();
    handleAddContactSaveTrack && handleAddContactSaveTrack();
};

