import React, {ChangeEvent, FC, useRef, useState} from 'react';
import styles from './exchange-contact.module.scss';
import Divider from '../../../components/common/divider/Divider';
import {Input} from '../../../components/common/input/Input';
import {NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX} from '../../../utils/validationUtils';
import i18n from '../../../translations/i18n';
import ModalContainer from '../../../components/common/modalContainer/ModalContainer';
import BtnContainer from '../../../components/common/button/BtnContainer';
import {CustomSwitch} from '../../../components/common/switch/CustomSwitch';
import {TextArea} from '../../../components/common/textArea/TextArea';
import { ReactComponent as MailIcon } from '../../../assets/icons/small-mail.svg';
import { ReactComponent as BriefcaseIcon } from '../../../assets/icons/briefcase.svg';
import { ReactComponent as PhoneCallIcon } from '../../../assets/icons/phone-call.svg';
import { ReactComponent as WorldIcon } from '../../../assets/icons/world.svg';
import useExchangeContactController from './useExchangeContactController';
import {useOutsideClick} from '../../../hooks/useOutsideCLick';
import CloseButton from '../../../components/common/button/CloseButton';
import PrivacyPolicyCheckbox from '../../../components/common/checkbox/PrivacyPolicyCheckbox';
import UploadOrTakePhoto from './UploadOrTakePhoto';

export interface ExchangeContactFormData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    company: string;
    website: string;
    notes: string;
    profileImagePath: string;
    sendEmail: boolean;
}

interface ExchangeContactProps {
    open: boolean;
    onClose: () => void;
    cardUuid?: string;
    userId?: number;
    sendEmail: boolean;
}

export const INITIAL_CONTACT_FORM_DATA = {
    firstName: '', lastName: '', email: '', phone: '', company: '', website: '',
    notes: '', profileImagePath: '', sendEmail: true
};

const ExchangeContact: FC<ExchangeContactProps> = ({
    open, onClose, cardUuid, userId, sendEmail
}) => {
    const [formData, setFormData] = useState<ExchangeContactFormData>(INITIAL_CONTACT_FORM_DATA);
    const [checkPolicy, setCheckPolicy] = useState<boolean>(false);
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const [userImage, setUserImage] = useState<File | undefined>(undefined);
    const modalRef = useRef<HTMLDivElement>(null);

    const { saveExchangeContactFromPreviewProfileApi, saveExchangeContactFromContactsSectionApi, loading } = useExchangeContactController();

    useOutsideClick([modalRef], () => { onClose(); });

    const handleUpdateFormData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const onApiCallFinish = () => {
        onClose();
        setFormData(INITIAL_CONTACT_FORM_DATA);
    };

    const handleSave = () => {
        if (cardUuid) {
            saveExchangeContactFromPreviewProfileApi(cardUuid, formData, onApiCallFinish, userImage);
        } else if (userId) {
            saveExchangeContactFromContactsSectionApi(userId, formData, onApiCallFinish, userImage);
        }
    };

    const handleCheckPolicy = () => {
        setSaveDisabled(checkPolicy);
        setCheckPolicy(prevState => !prevState);
    };

    const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFormData(prevState => ({ ...prevState, sendEmail: checked }));
    };

    if (!open) return <></>;

    return (
        <ModalContainer className={styles.modalContainer}>
            <div ref={modalRef} className={styles.container}>
                <div className={styles.modalHeader}>
                    <div className={styles.title}>
                        <div>{i18n.t('common.exchangeContact')}</div>
                        <CloseButton onClose={onClose}/>
                    </div>
                    <Divider/>
                </div>
                <div className={styles.body}>
                    <UploadOrTakePhoto
                        onPhotoChange={setUserImage}
                        profilePhoto={userImage}
                    />
                    <div className={styles.name}>
                        <Input
                            notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                            label={i18n.t('labels.firstName')}
                            type="text"
                            name={'firstName'}
                            value={formData.firstName}
                            onChange={handleUpdateFormData}
                            className={styles.input}
                            max={32}
                            required={true}
                        />
                        <Input
                            notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                            label={i18n.t('labels.lastName')}
                            type="text"
                            name={'lastName'}
                            value={formData.lastName}
                            onChange={handleUpdateFormData}
                            className={styles.input}
                            max={32}
                            required={true}
                        />
                    </div>
                    <div>
                        <Input
                            notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                            label={i18n.t('common.email')}
                            type="text"
                            name={'email'}
                            value={formData.email}
                            onChange={handleUpdateFormData}
                            className={styles.input}
                            max={64}
                            icon={<MailIcon />}
                            required={true}
                        />
                    </div>
                    <div>
                        <Input
                            notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                            label={i18n.t('common.phone')}
                            type="text"
                            name={'phone'}
                            value={formData.phone}
                            onChange={handleUpdateFormData}
                            className={styles.input}
                            max={32}
                            icon={<PhoneCallIcon />}
                        />
                    </div>
                    <div>
                        <Input
                            notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                            label={i18n.t('labels.company')}
                            type="text"
                            name={'company'}
                            value={formData.company}
                            onChange={handleUpdateFormData}
                            className={styles.input}
                            max={64}
                            icon={<BriefcaseIcon />}
                        />
                    </div>
                    <div>
                        <Input
                            notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                            label={i18n.t('common.website')}
                            type="text"
                            name={'website'}
                            value={formData.website}
                            onChange={handleUpdateFormData}
                            className={styles.input}
                            max={64}
                            icon={<WorldIcon />}
                        />
                    </div>
                    <div>
                        <TextArea
                            notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                            label={i18n.t('common.notes')}
                            name={'notes'}
                            value={formData.notes}
                            onChange={handleUpdateFormData}
                            rows={3}
                            maxLength={180}
                            className={styles.notes}
                            required={true}
                        />
                    </div>
                    <PrivacyPolicyCheckbox
                        checked={checkPolicy}
                        onCheck={handleCheckPolicy}
                    />
                </div>
                <Divider/>
                <div className={styles.bottom}>
                    {sendEmail &&
                        <div className={styles.switch}>
                            <CustomSwitch
                                checked={formData.sendEmail}
                                handleChange={handleSwitchChange}
                            />
                            <div className={styles.sendEmailText}>
                                {i18n.t('common.sendEmail')}
                            </div>
                        </div>
                    }
                    <div className={styles.btnWrapper}>
                        <BtnContainer
                            onCancel={onClose}
                            onSave={handleSave}
                            loading={loading}
                            isDisabled={saveDisabled}
                            saveBtnText={i18n.t('common.submit')}
                        />
                    </div>
                </div>

            </div>
        </ModalContainer>
    );
};

export default ExchangeContact;