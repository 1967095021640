import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../store/appDispatch';
import { selectUser } from '../../store/slices/userSessionSlice';
import { getArticleBySlug, getCategories, getCategoryBySlug } from '../../api/strapi/helpDesk';
import { CatgoryConverter } from '../../converters/strapi/CategoryConverter';
import { Article, Category } from '../../types/helpDesk';

const useHelpDeskController = (categorySlug?: string, articleSlug?: string) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingCategory, setLoadingCategory] = useState<boolean>(false);
    const [loadingArticle, setLoadingArticle] = useState<boolean>(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const [category, setCategory] = useState<Category>();
    const [article, setArticle] = useState<Article>();
    const user = useAppSelector(selectUser);

    const getCategoriesApi = useCallback(() => {
        setLoading(true);        
        getCategories(user.language)
            .then(res => {
                if (res.error) return;
                setCategories(CatgoryConverter.convertFromCategoriesApiToCatgories(res.data?.data));
            })
            .finally(() => setLoading(false));
    }, [user]);

    const getCategoryApi = useCallback((slug: string) => {
        setLoadingCategory(true);
        getCategoryBySlug(user.language, String(slug))
            .then(res => {
                if (res.error) return;
                setCategory(res.data?.data[0]);
            })
            .finally(() => setLoadingCategory(false));
    }, [user, categorySlug]);

    const getArticleApi = useCallback((articleSlug: string) => {
        setLoadingArticle(true);
        getArticleBySlug(user?.language, String(articleSlug))
            .then(res => {
                if (res.error) return;
                setArticle(res.data?.data[0]);
            })
            .finally(() => setLoadingArticle(false));
    }, [user, articleSlug]);

    useEffect(() => {
        if (user) {
            getCategoriesApi();
        }
    }, [user]);

    useEffect(() => {
        if (categorySlug) {
            getCategoryApi(categorySlug);
        }
    }, [categorySlug, user]);

    useEffect(() => {
        if (articleSlug) {
            getArticleApi(articleSlug);
        }
    }, [articleSlug, user]);

    return {
        loading,
        categories,
        loadingCategory,
        loadingArticle,
        category,
        article,
        getCategoryApi,
        setCategories
    };
};

export default useHelpDeskController;
