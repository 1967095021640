export const ENGLISH = {
    analytics: {
        country: 'Country',
        previousPeriod: 'than previous period',
        users: 'Users'
    },
    header: {
        accountSetting: 'Account Setting',
        accountSettings: 'Account settings',
        analytics: 'Analytics',
        changelog: 'Changelog',
        companyCards: 'Company Cards',
        personalCards: 'Personal Cards',
        profilePage: 'Profile Page',
        walletCard: 'Wallet Card',
        settings: 'Settings',
        signedInAs: 'Signed in as',
        signOut: 'Sign out',
        switchToBusinessAccount: 'Switch to business account',
        visitWebsite: 'Visit website',
        youAreEditing: 'You are editing {{noofCards}} cards'
    },
    common: {
        about: 'About',
        accepted: 'Accepted',
        actions: 'Actions',
        activated: 'Activated',
        addMember: 'Add member',
        admin: 'Admin',
        admins: 'admins',
        appointmentBooking: 'Appointment booking',
        appearance: 'Appearance',
        allCards: 'All cards',
        articles: 'articles',
        assign: 'Assign',
        assignCard: 'Assign card',
        assignCardTo: 'Assign card to',
        authentication: 'Authentication',
        bulkActions: 'Bulk Actions',
        cancel: 'Cancel',
        cancelInvitation : 'Cancel invitation',
        cardAssignedTo: 'Card assigned to',
        cardsSelected: 'cards selected',
        cardSelectedCapital: 'Card Selected',
        cardsSelectedCapital: 'Cards Selected',
        cardViews: 'Card views',
        categories: 'Categories',
        changeCardOwner: 'Change card owner',
        changePassword: 'Change Password',
        changesSaved: 'Saved',
        close: 'Close',
        companyCards: 'Company cards',
        companyLinkedin: 'Company LinkedIn',
        contents: 'CONTENTS',
        personalLinkedin: 'Personal LinkedIn',
        companyLogo: 'Company Logo',
        companyMembers: 'Company Members',
        companyPage: 'Company Page',
        confirmPassword: 'Confirm Password',
        connect: 'Connect',
        connected: 'Connected',
        contact: 'Contact',
        contactUs: 'Contact us',
        contacts: 'Contacts',
        contactSaved: 'Contact saved',
        copy: 'Copy',
        copied: 'Copied!',
        currentPassword: 'Current Password',
        customLink: 'Custom Link',
        dataAndPrivacy: 'Data and Privacy',
        delete: 'Delete',
        deleteCard: 'Delete card',
        deleteContact: 'Delete contact',
        deletePermanently: 'Delete permanently',
        discard: 'Discard',
        dismiss: 'Dismiss',
        download: 'Download',
        downloadInstructions: 'Download instructions',
        edit: 'Edit',
        editCard: 'Edit card',
        editCards: 'Edit cards',
        editContact: 'Edit contact',
        editPermissions: 'Edit Permissions',
        email: 'Email',
        emailDash: 'E-mail',
        emailAddress: 'Email address',
        enabled: 'Enabled',
        exchangeContact: 'Exchange Contact',
        expired: 'Expired',
        export: 'Export',
        facebook: 'Facebook',
        featuresAndImprovements: "Features and improvements we've recently shipped.",
        general: 'General',
        github: 'GitHub',
        grid: 'Grid',
        groupPerformance: 'Group Performance',
        helpDesk: 'Help Desk',
        howCanWeHelp: 'How can we help?',
        inactive: 'Inactive',
        instagram: 'Instagram',
        integrations: 'Integrations',
        inviteAs: 'Invite as',
        inviteNewMember: 'Invite new member',
        language: 'Language',
        lastUpdate: 'Last updated on',
        leave: 'Leave',
        links: 'Links',
        linkedin: 'LinkedIn',
        linkedinCompany: 'LinkedIn (Company)',
        linkedinPersonal: 'LinkedIn (Personal)',
        linkedinProfile: 'LinkedIn Profile',
        list: 'List',
        managedBy: 'Managed by',
        markAllAsRead: 'Mark all as read',
        member: 'Member',
        message: 'Message',
        more: 'More',
        move: 'Move',
        moveCardsTo: 'Move cards to',
        moveToCompany: 'Move to company cards',
        moveToMyCards: 'Move to Personal Cards',
        personalCards: 'Personal cards',
        name: 'Name',
        newContact: 'New Contact',
        newPassword: 'New Password',
        noFileChosen: 'No file chosen',
        notActivated: 'Not activated',
        notes: 'Notes',
        notifications: 'Notifications',
        optional: 'optional',
        ok: 'Ok',
        or: 'or',
        other: 'Other',
        others: 'Others',
        owner: 'Owner',
        owners: 'owners',
        password: 'Password',
        pending: 'Pending',
        permissions: 'Permissions',
        phone: 'Phone',
        pinterest: 'Pinterest',
        previewCard: 'Preview card',
        proceed: 'Proceed',
        profilePicture: 'Profile picture',
        pleaseSelectOption : 'Please select an option',
        qrCode: 'QR code',
        recentActivities: 'Recent Activities',
        reddit: 'Reddit',
        refreshPage : 'Please refresh the page to continue',
        reload: 'Reload',
        remove: 'Remove',
        removeMember: 'Remove member',
        request: 'Request',
        resendInvitation: 'Resend invitation',
        report: 'Report',
        role: 'Role',
        roles: 'Roles',
        save: 'Save',
        saveChanges: 'Save changes',
        saveContact: 'Save Contact',
        search: 'Search',
        selectAll: 'Select All',
        selectFile: 'Select File to Import',
        selectOption: 'Select option',
        selectPlatform: 'Select platform',
        send: 'Send',
        sendEmail: 'Send Email',
        sent: 'Sent',
        sessionExpired: 'Session Expired',
        setInactive: 'Set inactive',
        share: 'Share',
        shareCard: 'Share card',
        snapchat: 'Snapchat',
        status: 'Status',
        stay: 'Stay',
        subject: 'Subject',
        subjectLine: 'Subject line',
        submit: 'Submit',
        support: 'Support',
        supportedFormat: 'Supported formats: jpeg, png, gif, pdf',
        stillNeedHelp: 'Still need help?',
        takeAphoto: 'Take a photo',
        threads: 'Threads',
        today: 'Today',
        topCards: 'Top Cards',
        tiktok: 'TikTok',
        title: 'Title',
        to: 'to',
        toCapital: 'To',
        uniqueUsers: 'Unique users',
        unnamed: 'Unnamed',
        unsavedChanges: 'Unsaved changes',
        unsavedChangesText: 'It looks like you have been editing something. If you leave before saving, your changes will be lost.',
        user: 'User',
        users: 'users',
        usersByCountries: 'Users by Country',
        view: 'View',
        viewAll: 'View all',
        viewMode: 'View mode',
        yourAccount: 'Your Account',
        website: 'Website',
        whatsapp: 'WhatsApp',
        x: 'X',
        yes: 'Yes',
        yourBusinessCard: 'Your business card',
        youtube: 'YouTube'
    },
    contact: {
        description1: 'Do you have any questions or need advice?',
        description2: ' We\'re happy to help you!',
        hello: 'Hello!',
        liveChat: 'Live Chat',
        helpDeskDescription: 'Access Support Portal'
    },
    integration: {
        allSynced: 'All synced!',
        syncingContacts: 'Syncing {{count}} contacts',
        syncNow: 'Sync now',
        syncNewContacts: 'Sync {{count}} new contacts',
    },
    modal: {
        addUserRoleText: 'Are you sure you want to assign the {{role}} role to {{username}}?',
        areYouSure: 'Are you sure?',
        areYouSureDeleteText: 'Are you sure you want to remove contact?',
        changeCardOwnerDesc: 'If you change the card owner, you will no longer be able to manage or view this card profile',
        deleteAccountConfirm: 'Yes, delete my account',
        deleteAccountText: 'Are you absolutely sure that you want to delete your iCards account? Please note that there is no option to restore the account or its data once it\'s deleted.',
        deleteText: 'This action cannot be undone, if you delete this card it is gone forever. Any link or QR code associated with this card will no longer work.',
        disable2faTitle: 'Disable Two-Factor Authentication',
        disable2faDesc: 'Do you want to disable Two-Factor Authentication?',
        enterCompanyName: 'Enter company name',
        hiThere: 'Hi there 👋',
        howCanIhelpYou: 'How can I help you',
        iCardsTeam : 'iCards Team',
        iCardsTeamSubTitle : 'Typically replies within a day',
        lostPhysicalCard: 'Lost your physical card?',
        lostPhysicalCardText: 'If your card was lost or stollen, for your data protection you have option to set profile used for this cards as inactive. If your card was damaged you can proceed to order replacement card with the link to the same profile, and all your information will remain unchanged.',
        orderNewCardText: 'Order replacement card with the link to the same profile, and all your information will remain unchanged.',
        permissionModalText: 'Define what company {{role}} can edit.',
        removeCompanyInvitationTitle: 'Remove company invitation',
        removeCompanyInvitationDesc: 'The company invitation for {{userEmail}} will be removed.',
        removeCompanyMemberTitle: 'Remove company member',
        removeCompanyMemberDesc: '{{name}} can only be removed if no company card is assigned to them. Any iCards {{name}} owns will also be removed from the company. ',
        setInactiveText : 'This option will deactivate link to your profile and you can proceed with to order replacement card with the new profile.',
        startChat : 'Start Chat',
        setup2faTitle: 'Setup Two-Factor Authentication',
        setup2faDesc: "Would you like to proceed with the 2FA setup? You'll need to complete the setup before you can disable it again."
    },
    myCards : {
        clickBtnToCreateCard: 'Click the button below to create your first digital business card',
        newCard: 'New Card',
        orderNewCard: 'Order new card',
        setInactiveText: 'Set inactive if this card was lost, stollen or no longer in use. This option will deactivate link to your profile.',
        setInactiveText2: 'Set this card as inactive if you do not want it to be visible.',
        youDontHaveCards: 'You don\'t have any cards'
    },
    notification: {
        cardActivatedUserTitle: 'Card Activated Successfully!',
        cardActivatedUserDesc: 'Your card has been activated and is now ready for use. Please keep it safe and secure.',
        cardActivatedOwnerTitle: 'Card Activated Successfully!',
        cardActivatedOwnerDesc: 'Your card has been activated. You can now move it to company cards or change its owner.',
        cardAssignedTitle: 'Card Assigned to Your Account',
        cardAssignedDesc: 'A card has been successfully assigned to your account. You can now manage it through your account settings.',
        cardOwnerTransferredTitle: 'Card Ownership Transferred',
        cardOwnerTransferredDesc: 'The ownership of your card has been transferred to a new owner. Please contact support if you have any questions or concerns.',
        cardOwnerReceivedTitle: 'Card Ownership Received',
        cardOwnerReceivedDesc: 'You have become a card owner. You can now manage it through your account settings.',
        companyRoleAssignedTitle: 'Company Role Assigned!',
        companyRoleAssignedDesc: 'You have been appointed as {{role}} of the company {{company}}.',
        newFeatureTitle: 'New feature available',
        newFeatureAdded: 'We’ve just released a new feature to improve your experience. Check it out!',
        newFeaturesAdded: 'We’ve enhanced our platform! Learn more about the new features today.',
        noNewNotifications: 'You don\'t have any new notifications at this time'
    },
    labels: {
        buttonBackgroundColor: 'Button Background Color',
        buttonTextColor: 'Button Text Color',
        cardBackgroundColor: 'Card Background Color',
        cardTextColor: 'Card Text Color',
        city: 'City',
        company: 'Company',
        companyBgColor: 'Company Background Color',
        companyName: 'Company name',
        companyTextColor: 'Company Text Color',
        country: 'Country',
        cover: 'Cover',
        coverImage: 'Cover Image',
        coverBackgroundColor: 'Cover Background Color',
        createCompany: 'Create company',
        createNewCompany: 'Create a new company',
        department: 'Department',
        filterBy: 'Filter by',
        firstName: 'First Name',
        fullname: 'Full Name',
        iconBackgroundColor: 'Icon Background Color',
        iconsColor: 'Icons Color',
        lastName: 'Last Name',
        language: 'Language',
        pendingUserHasNotAcceptYet: 'Pending: waiting for the user to activate the account',
        position: 'Position',
        searchNameOrEmail: 'Search name or email',
        streetAdress: 'Street Address',
        stepsToTeproduceTheIssue: 'Steps to reproduce the issue',
        zipPostal: 'Zip / Postal'
    },
    messages: {
        companyInvitationSent: 'Company invitation has been sent',
        companyInvitationRemoved: 'Company invitation was successfully removed.',
        companyMemberRemoved: 'Company member was successfully removed.',
        companyWasSuccessfullyCreated: 'The company was successfully created',
        contactSuccessfullyRemoved: 'Contact successfully saved removed',
        contactSuccessfullySaved: 'Contact successfully saved',
        contactSuccessfullySynced: 'Contacts successfully synced',
        contactSyncFailed: 'Contact synchronization failed.',
        emailSentSuccess: 'Your email has been sent successfully!',
        emailSentFailed: 'Failed to send your email. Please try again later.',
        internalServerError: 'Internal server error',
        noIcardsFound: 'No iCards found',
        noCompanyMemberFound: 'No company members found',
        noContactsFound: 'No contacts found',
        noCategoryFound: 'No category found',
        passwordHasBeenChanged: 'The password has been changed.',
        passwordsDoNotMatch: 'Password and password confirmation do not match.',
        profilesSuccessfullyUpdated: '{{noofCards}} profiles updated successfully',
        profileWasSuccessfullyAssigned: 'Profile was successfully assigned.',
        selectAtLeastOneItem: 'Please select at least one item to perform bulk actions.',
        unableToRemoveCompanyMember: 'Unable to remove company member because they are assigned to company card profiles.'
    },
    notFoundPage: {
        description: 'We couldn’t find the page you were looking for. It might have been moved, renamed, or doesn’t exist anymore.',
        goBackToHomepage: 'Go Back to Homepage',
        title: 'Page Not Found'
    },
    policy: {
        iHaveRead: 'I have read and agree to the',
    },
    profile: {
        colors: {
            custom: 'Custom',
            default: 'Default',
            green: 'Green',
            purple: 'Purple',
            red: 'Red',
            yellow: 'Yellow'
        },
        error: {
            fileTooLarge: 'File is too large. Please upload a file smaller than 5MB.',
            invalidEmailAddress: 'Invalid email address',
            invalidFileType: 'Invalid file type. Please upload a PNG or JPEG file.',
            specialCharacters: 'You should not use special characters'
        },
        general:{
            changeCover: 'Change cover',
            changePic: 'Change picture',
            dragAndDrop: 'or drag and drop',
            noFileChosen: 'No file chosen',
            pngJpgGif : 'PNG, JPG, GIF up to 10MB',
            upload: 'Upload',
            uploadFile: 'Upload file',
            uploadPic: 'Upload picture'
        },
        links: {
            addLink: 'Add link'
        },
        contact: {
            addField: 'Add field'
        },
        noContentAdded: 'has not added any content yet',
        poweredBy: 'Powered by :'
    },
    hubspot: {
        authenticationFailed: 'Authentication failed! Please check your API Token.',
        apiToken: 'API Token',
        description: 'AI-powered customer platform with all the software, integrations, and resources you need to connect your marketing, sales, and customer service.',
        hubspot: 'HubSpot',
        loginToHubspot: 'Please log in to your HubSpot account, create a Private App, and fill in the API Token.'
    },
    salesforce: {
        authenticationSuccess: 'Authentication successful! You\'re all set to explore the integration features and streamline your operations.',
        authenticationFailed: 'Authentication failed! Please check your credentials.',
        consumerKey: 'Consumer Key',
        consumerSecret: 'Consumer Secret',
        description: 'Cloud-based software designed to help businesses find more prospects, close more deals.',
        enableIntegration: 'Enable Salesforce or HubSpot integration to keep the contacts up-to-date and connected!',
        instanceUrl: 'Salesforce Instance URL',
        loginToSalesforce: 'Please log in to your Salesforce account, create a New Connected App and fill in the Callback URL.',
        salesforce: 'Salesforce'
    },
    selector: {
        last7days: 'Last 7 days',
        last14days: 'Last 14 days',
        last30days: 'Last 30 days',
        totalViews: 'Total views'
    },
    settings: {
        deleteYourAccount: 'Delete your account',
        featureRequest: 'Feature Request',
        permanentlyDeleteYourAccount: 'Permanently delete your account',
        privacyPolicy: 'Privacy policy',
        reportBug: 'Report a bug',
        reportBugDesc: 'Something is broken? Let us know!',
        submitRequest: 'Submit request',
        supportContactDesc: 'Get in touch with our lovely support team',
        supportFeatureDesc: 'Any suggestions? Let us know what we could do to improve.',
        supportFeatureContactDesc: 'Let us know what you\'re looking for! It may already be in our roadmap.',
        supportFeatureContactPlaceholder: 'Describe your request',
        supportFeatureContactTitle: 'Looking for a specific feature?',
        supportFeedback: 'Support & Feedback',
        termsOfService: 'Terms of Service',
        termsAndConditions: 'Terms and Conditions',
        titleDesc: 'What are you looking for?',
        twofaEnableBtn: 'Enable 2FA',
        twofaDisableBtn: 'Disable 2FA',
        twofaDesc: 'Two-Factor Authentication protects your account by adding an extra security step when you log in.',
        twofaTitle: 'Two-factor authentication (2FA)'
    },
    activate: {
        assignProfile: 'Do you want to activate your new iCard {{uuid}} and link it to this account?',
        failedToActivateProfile: 'Failed to activate your iCard',
        profileActivation: 'Activate your new iCard',
        welcomeMessage1: 'Welcome to iCards',
        welcomeMessage2: 'Your iCard is not yet activated. Please click the button below to activate it',
        welcomeMessage3: 'If you already have account you will need to log in, otherwise you will need to register.',
        welcomeActivateButton: 'Activate your iCard',
        yesActivate: 'Yes, activate!',
        yourProfileIsActivated: 'Your iCard has been successfully activated!'
    }

};