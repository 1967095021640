import React, {FC, ReactElement, useState} from 'react';
import styles from './social-links.module.scss';
import CustomDropdown from '../../common/dropdown/CustomDropdown';
import i18n from '../../../translations/i18n';
import {COMPANY_LINKEDIN, PERSONAL_LINKEDIN} from '../../../types/socialLinks';
import {getExternalUrl} from '../../../utils/common';
import {CardProfileSocialLink} from '../../../types/cardProfile';

interface LinkedInDropdownProps {
    linkedinLinks: CardProfileSocialLink[];
    style: object;
    icon?: ReactElement;
    backgroundColor?: string;
    backgroundImage?: string;
    isLastTwo: boolean;
}

const LinkedInDropdown: FC<LinkedInDropdownProps> = ({
    linkedinLinks, style, icon, backgroundColor, backgroundImage, isLastTwo
}) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const toCompanyLinkedin = () => {
        const linkedIn = linkedinLinks.find(
            l => l.type?.trim().toLowerCase() == COMPANY_LINKEDIN.toLowerCase()
        );
        linkedIn && window.open(getExternalUrl(linkedIn.details), '_blank');
    };

    const toPersonalLinkedin = () => {
        const linkedIn = linkedinLinks.find(
            l => l.type?.trim().toLowerCase() == PERSONAL_LINKEDIN.toLowerCase()
        );
        linkedIn && window.open(getExternalUrl(linkedIn.details), '_blank');
    };

    return (
        <div
            className={styles.dropdownWrapper}
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
        >
            <div className={styles.logoWrapper}
                style={{...style, backgroundColor, backgroundImage}}>
                {icon}
            </div>
            {showDropdown && (
                <CustomDropdown
                    className={`${styles.customDropdown} ${isLastTwo ? styles.dropdownAdjust : ''}`}
                    items={[
                        {name: i18n.t('common.companyPage'), onAction: () => toCompanyLinkedin()},
                        {name: i18n.t('common.linkedinProfile'), onAction: () => toPersonalLinkedin()}
                    ]}
                />
            )}
        </div>
    );
};

export default LinkedInDropdown;