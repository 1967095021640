import React, {FC, ReactElement} from 'react';
import styles from './settings.module.scss';

interface SettingsCardProps {
    label: string;
    text?: string;
    actionElement?: ReactElement;
    onClick?: () => void;
    sx?: string;
    startIcon?: ReactElement;
}

const SettingsCard: FC<SettingsCardProps> = ({
    label, text, actionElement, onClick, sx, startIcon
}) => {

    const classNameContainerPart = onClick ? `${styles.cardContainer} ${styles.pointer}` : `${styles.cardContainer}`;
    const classNameContainer = sx ? `${classNameContainerPart} ${sx}` : classNameContainerPart;

    return (
        <div className={classNameContainer} onClick={onClick}>
            <div className={styles.containerLeftSection}>
                {startIcon &&
                    <div>
                        {startIcon}
                    </div>
                }
                <div className={styles.cardTextWrapper}>
                    <div className={styles.cardLabel}>
                        {label}
                    </div>
                    <div className={styles.cardText}>
                        {text}
                    </div>
                </div>
            </div>
            <div className={styles.cardElement}>
                {actionElement}
            </div>
        </div>
    );
};

export default SettingsCard;