import {useCallback, useEffect, useState} from 'react';
import {getReleaseNotes} from '../../api/strapi/changelog';
import {ReleaseNote} from '../../types/releaseNote';
import {ChangelogConverter} from '../../converters/strapi/ChangelogConverter';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';


const useChangelogController = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>([]);
    const user = useAppSelector(selectUser);

    const getReleaseNotesApi = useCallback(() => {
        setLoading(true);
        getReleaseNotes(user.language)
            .then(res => {
                if (res.error) return;
                setReleaseNotes(ChangelogConverter.convertFromReleaseNotesApiToReleaseNotes(res.data?.data));
            })
            .finally(() => setLoading(false));
    }, [user]);

    useEffect(() => {
        user && getReleaseNotesApi();
    }, [user]);


    return {
        loading,
        releaseNotes
    };
};

export default useChangelogController;