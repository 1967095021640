import {FC, ReactElement, useEffect, useMemo, useState} from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {ReactComponent as GridIcon} from '../../../assets/icons/grid.svg';
import {ReactComponent as ListIcon} from '../../../assets/icons/list.svg';
import {ReactComponent as ArrowDownIcon} from '../../../assets/icons/arrow/arrow-down.svg';
import {GRID, LIST} from '../../../constants/common';
import i18n from '../../../translations/i18n';
import {getViewModeFromLocalStorage, saveViewModeToLocalStorage} from '../../../storage/localStorage';
import {StyledMenu} from './ViewSelector.style';
import {btnSelectorStyle, defaultItemStyle, viewModeStyle} from './view-selector.style';
import styles from './view-selector.module.scss';

export type ViewSelectorType = typeof GRID | typeof LIST;

export interface ViewSelectorItem {
    type: ViewSelectorType;
    icon: ReactElement;
    text: string;
}

export interface ViewSelectorProps {
    defaultValue?: ViewSelectorType;
    onSelect: (type: ViewSelectorType) => void;
}

const ViewSelector: FC<ViewSelectorProps> = ({
    defaultValue, onSelect
}) => {
    const INITIAL_VIEW_SELECTOR_ITEM: ViewSelectorItem[] = useMemo(() => [
        {
            type: GRID,
            icon: <GridIcon />,
            text: i18n.t('common.grid')
        },
        {
            type: LIST,
            icon: <ListIcon />,
            text: i18n.t('common.list')
        }
    ], [i18n.locale]);

    const savedViewMode = getViewModeFromLocalStorage();

    const defaultItem = useMemo(
        () => savedViewMode
            ? savedViewMode === GRID
                ? INITIAL_VIEW_SELECTOR_ITEM[0]
                : INITIAL_VIEW_SELECTOR_ITEM[1]
            : defaultValue
                ? defaultValue === GRID
                    ? INITIAL_VIEW_SELECTOR_ITEM[0]
                    : INITIAL_VIEW_SELECTOR_ITEM[1]
                : INITIAL_VIEW_SELECTOR_ITEM[0],
        [savedViewMode, defaultValue]
    );

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [item, setItem] = useState<ViewSelectorItem>(defaultItem);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleSelect = (el: ViewSelectorItem) => {
        setItem(el);
        saveViewModeToLocalStorage(el.type);
        handleClose();
        onSelect(el.type);
    };

    useEffect(() => {
        if (savedViewMode) {
            const savedItem = INITIAL_VIEW_SELECTOR_ITEM.find(item => item.type === savedViewMode);
            if (savedItem) {
                setItem(savedItem);
                onSelect(savedItem.type);
            }
        }
    }, [savedViewMode, INITIAL_VIEW_SELECTOR_ITEM, onSelect]);

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<ArrowDownIcon />}
                style={btnSelectorStyle}
                className={styles.btnSelector}
            >
                <div style={defaultItemStyle}>
                    {item.icon}
                    {item.text}
                </div>
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <div style={viewModeStyle}
                >
                    {i18n.t('common.viewMode')}
                </div>
                {INITIAL_VIEW_SELECTOR_ITEM.map(item => (
                    <MenuItem
                        key={item.type}
                        onClick={() => handleSelect(item)}
                        disableRipple
                    >
                        {item.icon}
                        {item.text}
                    </MenuItem>
                ))}
            </StyledMenu>
        </div>
    );
};

export default ViewSelector;