import React, { FC, useRef, useState } from 'react';
import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';
import styles from './notification-container.module.scss';
import useNotificationController, { INITIAL_NOTIFICATIONS_LOAD } from './useNotificationController';
import { useAppSelector } from '../../store/appDispatch';
import { selectUser } from '../../store/slices/userSessionSlice';
import i18n from '../../translations/i18n';
import NotificationBox from './NotificationBox';
import { useOutsideClick } from '../../hooks/useOutsideCLick';
import { CircularProgress } from '@mui/material';
import { isEmpty, notEmpty } from '../../utils/common';
import NoNewNotifications from './NoNewNotifications';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';

const NotificationContainer: FC = () => {
    const user = useAppSelector(selectUser);
    const [open, setOpen] = useState<boolean>(false);
    const  isSmallScreen  = useIsSmallScreen();

    const notificationsRef = useRef<HTMLDivElement>(null);
    const bellIconRef = useRef<HTMLDivElement>(null);

    useOutsideClick([notificationsRef, bellIconRef], () => {
        setNotifications([]);
        setOpen(false);
    });

    const {
        loading, allLoaded, notifications, setNotifications,
        unreadCounter, getNotificationsApi, markNotificationAsReadApi, markAllNotificationAsReadApi
    } = useNotificationController(user.id);

    const handleOpenNotificationCenter = () => {
        if (open) {
            setOpen(false);
            setNotifications([]);
        } else {
            setOpen(true);
            getNotificationsApi(INITIAL_NOTIFICATIONS_LOAD);
        }
    };

    const handleLoadAllNotifications = () => getNotificationsApi();

    return (
        <>
            {open && <div className={styles.overlay} onClick={() => setOpen(false)} />}
            <div className={styles.container}>
                <div className={styles.iconWrapper} onClick={handleOpenNotificationCenter} ref={bellIconRef}>
                    {unreadCounter > 0 &&
                        <div className={unreadCounter > 9 ? styles.bigCounter : styles.counter}>
                            {unreadCounter}
                        </div>
                    }
                    <BellIcon />
                </div>
                {open &&
                    <div className={isSmallScreen? styles.mobileBox: styles.box} ref={notificationsRef}>
                        <div className={styles.boxHeader}>
                            <div className={styles.boxNotification}>
                                {i18n.t('common.notifications')} ({unreadCounter})
                            </div>
                            <div className={styles.boxMarkRead} onClick={markAllNotificationAsReadApi}>
                                {i18n.t('common.markAllAsRead')}
                            </div>
                        </div>
                        {loading && isEmpty(notifications)
                            ? <CircularProgress style={{ alignSelf: 'center' }} />
                            : <>
                                <div className={styles.notificationsWrapper}>
                                    {notEmpty(notifications)
                                        ? notifications.map(n => (
                                            <NotificationBox
                                                key={n.id}
                                                notification={n}
                                                readNotification={markNotificationAsReadApi}
                                                closeNotification={() => setOpen(false)}
                                            />
                                        ))
                                        : <NoNewNotifications />
                                    }
                                </div>
                                {loading
                                    ? <CircularProgress style={{ alignSelf: 'center' }} />
                                    : !allLoaded &&
                                    <div className={styles.boxViewAll} onClick={handleLoadAllNotifications}>
                                        {i18n.t('common.viewAll')}
                                    </div>
                                }
                            </>
                        }
                    </div>
                }
            </div>
        </>
    );
};

export default NotificationContainer;