import React, {FC, useEffect, useRef, useState} from 'react';
import styles from './social-links.module.scss';
import { CardProfileSocialLink } from '../../../types/cardProfile';
import {getExternalUrl, notBlank, notEmpty} from '../../../utils/common';
import {getSocialIcon} from './socialLinkLogo';
import {isCustomLink, isLinkedInLink} from './socialLinksUtils';
import LinkedInDropdown from './LinkedInDropdown';
import CustomLinkDropdown from './CustomLinkDropdown';

interface SocialLinksProps {
    profileSocialLinks: CardProfileSocialLink[];
    style: object;
    iconStyle: object;
}

const SocialLinks: FC<SocialLinksProps> = ({ style, iconStyle ,profileSocialLinks}) => {
    const [lastTwoPositions, setLastTwoPositions] = useState<number[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);

    const linkedinLinks = profileSocialLinks.filter(social => isLinkedInLink(social));
    const customLinks = profileSocialLinks.filter(social => isCustomLink(social) && notEmpty(social.details));

    const displayedLinkedIn = linkedinLinks.length > 0 ? linkedinLinks[0] : null;
    const displayedCustomLink = customLinks.length > 0 ? customLinks[0] : null;

    const filteredSocialLinks = profileSocialLinks.filter(social =>
        (!isLinkedInLink(social) || social === displayedLinkedIn) &&
        (!isCustomLink(social) || social === displayedCustomLink)
    );

    const socialNotBlank = (social: CardProfileSocialLink) => notBlank(social.type) && notBlank(social.details);

    useEffect(() => {
        if (containerRef.current) {
            const items = Array.from(containerRef.current.children);
            if (items.length < 3) return;

            const numItemsPerRow = Math.floor((containerRef.current.clientWidth-30) / (items[0].clientWidth+15));

            if (numItemsPerRow > 0) {
                const lastTwoIndexes: number[] = [];

                items.forEach((_, index) => {
                    const positionInRow = index % numItemsPerRow;
                    if (positionInRow >= numItemsPerRow - 2) {
                        lastTwoIndexes.push(index);
                    }
                });

                setLastTwoPositions(lastTwoIndexes);
            }
        }
    }, [profileSocialLinks]);

    return (
        <div className={styles.logoContainer} ref={containerRef}>
            {filteredSocialLinks.filter(social => socialNotBlank(social)).map((social, index) => {
                const {
                    icon, backgroundColor, backgroundImage
                } = getSocialIcon(social, iconStyle);

                const showLinkedInDropdown = social === displayedLinkedIn && linkedinLinks.length > 1;
                const showCustomLinkDropdown = social === displayedCustomLink && customLinks.length > 1;

                if (showCustomLinkDropdown) {
                    return (
                        <CustomLinkDropdown
                            customLinks={customLinks}
                            style={style}
                            icon={icon}
                            backgroundColor={backgroundColor}
                            backgroundImage={backgroundImage}
                            isLastTwo={lastTwoPositions.includes(index)}
                        />
                    );
                }

                return (
                    <div key={social.id}>
                        {showLinkedInDropdown ? (
                            <LinkedInDropdown
                                linkedinLinks={linkedinLinks}
                                style={style}
                                icon={icon}
                                backgroundColor={backgroundColor}
                                backgroundImage={backgroundImage}
                                isLastTwo={lastTwoPositions.includes(index)}
                            />
                        ) : (
                            <a href={getExternalUrl(social.details)} target="_blank" rel="noopener noreferrer">
                                <div className={styles.logoWrapper}
                                    style={{...style, backgroundColor, backgroundImage}}
                                >
                                    {icon}
                                </div>
                            </a>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default SocialLinks;