export const btnSelectorStyle = {
    height: '48px',
    backgroundColor: '#FFFFFF',
    color: '#242222',
    paddingLeft: '0px',
    border: '1px solid #D1D5DB',
    justifyContent: 'space-between'
};

export const defaultItemStyle: any = {
    display: 'flex',
    gap: '4px',
    padding: '8px 0px 8px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: 'Lato'
};

export const viewModeStyle: any = {
    color: '#808080',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '12px',
    textTransform: 'uppercase',
    padding: '4px',
    marginLeft: '15px',
    marginTop: '6px'
};