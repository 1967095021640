import React, {RefObject} from 'react';
import {ExchangeContactData, ExchangeContactsApiFilter} from '../../types/exchangeContacts';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import { ReactComponent as AvatarIcon } from '../../assets/icons/avatar.svg';
import { ReactComponent as NotesIcon } from '../../assets/icons/notes.svg';
import styles from './contacts.module.scss';
import {getFormattedTime} from '../../utils/date';
import {TextHighlighter} from '../../components/common/textHighlighter/TextHighlighter';
import {isEmpty, notEmpty} from '../../utils/common';
import ThreeDotsMenu from '../../components/common/menu/ThreeDotsMenu';
import {
    ExchangeContactFormData,
    INITIAL_CONTACT_FORM_DATA
} from '../previewCardProfile/exchangeContact/ExchangeContact';
import {imageBaseUrl} from '../../api/base';

export const defaultExchangeContactsApiFilter: ExchangeContactsApiFilter = {
    page: 0,
    size: 8
};

export const getExchangeContactColumns = (
    openNotesRow: number | undefined,
    setPosition: (arg: any) => void,
    setOpenNotesRow: (rowId: { id: number, text: string } | undefined) => void,
    searchBy: string,
    menuIconRef: RefObject<HTMLDivElement>,
    openMenu: number | undefined,
    setOpenMenu: (arg: number | undefined) => void,
    setMenuPosition: (arg: any) => void,
): GridColDef[] => [
    {
        field: 'email',
        headerName: 'Name',
        type: 'string',
        minWidth: 260,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            const profileImagePath = params.row.profileImagePath;
            return (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
                    <div style={{height: '48px', width: '48px', borderRadius: '48px'}}>
                        {notEmpty(profileImagePath)
                            ? <img
                                className={styles.avatarImage}
                                src={`${imageBaseUrl + profileImagePath}`}
                                alt=""
                            />
                            : <AvatarIcon className={styles.avatarImage} />
                        }
                    </div>
                    <div className={styles.gridNameWrapper}>
                        <div className={styles.gridEmail}>
                            <TextHighlighter
                                text={params.row.email ?? ''}
                                textToHighlight={searchBy}
                            />
                        </div>
                        <div className={styles.gridName}>
                            <TextHighlighter
                                text={(params.row.firstName + ' ' + params.row.lastName) ?? ''}
                                textToHighlight={searchBy}
                            />
                        </div>
                    </div>
                </div>
            );
        },
    },
    {
        field: 'company',
        headerName: 'Company',
        type: 'string',
        minWidth: 120,
        flex: 1
    },
    {
        field: 'phone',
        headerName: 'Phone',
        type: 'string',
        minWidth: 120,
        flex: 1
    },
    {
        field: 'website',
        headerName: 'Website',
        type: 'string',
        minWidth: 120,
        flex: 1
    },
    {
        field: 'created',
        headerName: 'Date',
        type: 'string',
        minWidth: 170,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <div>
                    {getFormattedTime(params.row.created)}
                </div>
            );
        },
    },
    {
        field: 'notes',
        headerName: '',
        type: 'string',
        maxWidth: 40,
        hideSortIcons: true,
        renderCell: (params: GridRenderCellParams) => {
            const handleIconClick = (event: React.MouseEvent) => {
                const iconRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
                const scrollY = window.scrollY;
                const scrollX = window.scrollX;

                setPosition({
                    top: iconRect.bottom + scrollY + 8,
                    left: iconRect.left + scrollX - 300,
                });
                setOpenNotesRow(openNotesRow === params.row.id ? undefined : {id: params.row.id, text: params.row.notes} );
            };

            if (isEmpty(params.row.notes)) return <></>;

            return (
                <div
                    style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                    onClick={handleIconClick}
                >
                    <NotesIcon/>
                </div>
            );
        },
    },
    {
        field: 'menu',
        headerName: '',
        type: 'string',
        maxWidth: 40,
        hideSortIcons: true,
        renderCell: (params: GridRenderCellParams) => {
            const isOpen = openMenu === params.row.id;

            const handleIconClick = (event: React.MouseEvent) => {
                const iconRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
                const scrollY = window.scrollY;
                const scrollX = window.scrollX;

                setMenuPosition({
                    top: iconRect.bottom + scrollY + 8,
                    left: iconRect.left + scrollX - 120,
                });
                setOpenMenu(openMenu === params.row.id ? undefined : params.row.id);
            };

            return (
                <div onClick={handleIconClick}>
                    <ThreeDotsMenu
                        menuIconRef={menuIconRef}
                        isOpen={isOpen}
                        toggleOpen={() => {}}
                    />
                </div>
            );
        },
    }

];

export const convertToExchangeContactFormData = (data: ExchangeContactData | undefined): ExchangeContactFormData => {
    if (!data) return INITIAL_CONTACT_FORM_DATA;

    return {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        company: data.company,
        website: data.website,
        notes: data.notes,
        profileImagePath: data.profileImagePath,
        sendEmail: false
    };
};
