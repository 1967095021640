import React, {FC, useState} from 'react';
import UploadOrTakePhotoImage from '../../../components/common/fileInput/UploadOrTakePhotoImage';
import {CropperModal} from '../../../components/imageCropper/CropperModal';

interface UploadOrTakePhotoProps {
    onPhotoChange: (newPhoto: File) => void;
    profilePhoto?: File;
    initialUserPhoto?: string;
}

const UploadOrTakePhoto: FC<UploadOrTakePhotoProps> = ({
    onPhotoChange, profilePhoto, initialUserPhoto
}) => {
    const [cropperModal, setCropperModal] = useState<{ show: boolean, image: string }>({show: false, image: ''});

    const currentProfilePhoto = profilePhoto ? URL.createObjectURL(profilePhoto) : undefined;

    const handleProfilePhotoChange = (image: File) => {
        setCropperModal({show: true, image: URL.createObjectURL(image as Blob)});
    };

    const handleCloseCropperModal = () => setCropperModal({show: false, image: ''});

    const handleOnSaveImage = (croppedImg: File) => {
        onPhotoChange(croppedImg);
        handleCloseCropperModal();
    };

    return (
        <>
            <UploadOrTakePhotoImage
                id='upload-photo'
                onPhotoChange={handleProfilePhotoChange}
                profilePhoto={currentProfilePhoto}
                initialUserPhoto={initialUserPhoto}
            />
            <CropperModal
                isOpen={cropperModal.show}
                image={cropperModal.image}
                onClose={handleCloseCropperModal}
                onSaveImage={handleOnSaveImage}
                cropShape={'round'}
                cropHeight={192}
                cropWidth={192}
            />
        </>
    );
};

export default UploadOrTakePhoto;