export const isEmpty = (value: any) =>
    !value || value.length < 1 || Object.keys(value).length === 0;

export const notEmpty = (value: any) => !isEmpty(value);

export const valueOr = (value: any, value2: any) => isEmpty(value) ? value2 : value;

export const isBlank = (value: string | undefined | null) => !value || value.trim() === '' ;

export const notBlank = (value: string | undefined | null) => !isBlank(value);

export const getExternalUrl = (url: string) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'https://' + url;
    }

    return url ?? '';
};

export const openInNewTab = (url: string | undefined) => {
    if (!url) return;

    window.open(url, '_blank', 'noopener,noreferrer');
};

export const isArrayBufferEmpty = (data: any): boolean => {
    if (data == null) {
        return true;
    }

    return data instanceof ArrayBuffer && data.byteLength === 0;
};

export const sortByDesc = (data: any[]) => data.sort((a, b) => b[1] - a[1]);


export const getPhoneUrl = (url: string) => `tel:${url}`;

export const getMailUrl = (url: string) => `mailto:${url}`;

export const extractDomainName = (url: string): string => {
    try {
        const cleanUrl = url.replace(/https?:\/\//, '').replace(/^www\./, '');
        const domain = cleanUrl.split('.')[0];
        return domain.charAt(0).toUpperCase() + domain.slice(1);
    } catch (error) {
        return url;
    }
};

export function isPhoneNumber(input: string): boolean {
    const cleanedInput = input.replace(/\s+/g, '').trim();
    // Allow optional leading +, followed by 6 to 18 digits
    return /^(\+)?\d{6,18}$/.test(cleanedInput);
}
