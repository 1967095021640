import {CompanyRoleType} from './company';

export const CARD_ACTIVATED_USER = 'CARD_ACTIVATED_USER';
export const CARD_ACTIVATED_OWNER = 'CARD_ACTIVATED_OWNER';
export const CARD_ASSIGNED = 'CARD_ASSIGNED';
export const CARD_OWNER_TRANSFERRED = 'CARD_OWNER_TRANSFERRED';
export const CARD_OWNER_RECEIVED = 'CARD_OWNER_RECEIVED';
export const COMPANY_ROLE_CHANGED = 'COMPANY_ROLE_CHANGED';
export const NEW_WEBAPP_RELEASE = 'NEW_WEBAPP_RELEASE';

export type NotificationType =
    typeof CARD_ACTIVATED_USER |
    typeof CARD_ACTIVATED_OWNER |
    typeof CARD_ASSIGNED |
    typeof CARD_OWNER_TRANSFERRED |
    typeof CARD_OWNER_RECEIVED |
    typeof COMPANY_ROLE_CHANGED |
    typeof NEW_WEBAPP_RELEASE;

export interface NotificationDto {
    id: number;
    type: NotificationType;
    timeAgo: string;
    url: string;
    read: boolean;
    companyName: string;
    role: CompanyRoleType;
    data1: string;
    data2: string;
}
