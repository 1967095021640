import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getWebappVersion} from '../../api/appConfig';

interface AppConfigSlice {
    version: string;
}

const initialState: AppConfigSlice = {
    version: ''
};

const appConfigSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setAppVersion.fulfilled, (state, action) => {
            if (action?.payload) {
                state.version = action.payload;
            }
        });
    }

});

export const setAppVersion = createAsyncThunk<string | undefined
>('appConfig/setAppVersion', async () => {
    const res = await getWebappVersion();

    return res.error ? '' : res.data;
});

export default appConfigSlice.reducer;

export const selectAppVersion = (state: any) => state.appConfig.version;
