import {
    configDefaultWithToken,
    configForImages,
    deleteApiCall,
    getApiCall,
    patchApiCall,
    portalUrl,
    postApiCall
} from './base';
import {ExchangeContactsApiFilter} from '../types/exchangeContacts';


const exchangeContactUrl = '/api/exchange-contact';
const exchangeContactByCardUuidUrl = (uuid: string) => `${portalUrl}${exchangeContactUrl}/card/${uuid}`; // it should be dynamically baseUrl, do not update this
const exchangeContactByUserIdUrl = (userId: number) => `${exchangeContactUrl}/user/${userId}`;
const exchangeContactExportUserIdUrl = (userId: number) => `${exchangeContactUrl}/user/${userId}/export`;
const exchangeContactByContactIdUrl = (contactId: number) => `${exchangeContactUrl}/${contactId}`;
const syncExchangeContactByUserIdUrl = (userId: number) => `${exchangeContactUrl}/user/${userId}/sync`;

export const saveExchangeContactFromPreviewProfile = async (uuid: string, data: FormData) => {
    const config = {
        ...(await configForImages())
    };

    return await postApiCall(exchangeContactByCardUuidUrl(uuid), data, config);
};

export const saveExchangeContactFromContactsSection = async (userId: number, data: FormData) => {
    const config = {
        ...(await configForImages())
    };

    return await postApiCall(exchangeContactByUserIdUrl(userId), data, config);
};

export const updateExchangeContactFromContactsSection = async (contactId: number, data: FormData) => {
    const config = {
        ...(await configForImages())
    };

    return await patchApiCall(exchangeContactByContactIdUrl(contactId), data, config);
};

export const deleteExchangeContactFromContactsSection = async (contactId: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await deleteApiCall(exchangeContactByContactIdUrl(contactId), config);
};

export const getExchangeContacts = async (userId: number, filter: ExchangeContactsApiFilter) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            searchBy: filter.searchBy,
            page: filter.page,
            size: filter.size
        }
    };

    return await getApiCall(exchangeContactByUserIdUrl(userId), config);
};

export const exportExchangeContactsToCsv = async (userId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
        responseType: 'blob'
    };

    return await getApiCall(exchangeContactExportUserIdUrl(userId), config);
};

export const syncExchangeContactFromContactsSection = async (userId: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(syncExchangeContactByUserIdUrl(userId), {}, config);
};
