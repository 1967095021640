import {ReportBugEmail, SendEmail} from '../../../types/user';
import {icardsSupportEmail} from '../../../api/base';

export const initSendEmail = (featureRequest: boolean): SendEmail => ({
    to: icardsSupportEmail,
    subject: '',
    body: '',
    featureRequest: featureRequest
});

export const initBugReportEmail = (): ReportBugEmail => ({
    to: icardsSupportEmail,
    description: ''
});


export const formatFileSize = (sizeInBytes: number) => {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    return `${sizeInMB.toFixed(2)} MB`;
};