import QRCodeStyling from 'qr-code-styling';

export const getQrCodeDefault = (
    data: string, qrCodeSize: number
) => {

    return new QRCodeStyling({
        width: qrCodeSize,
        height: qrCodeSize,
        shape: 'square',
        type: 'svg',
        data: data,
        qrOptions: {
            errorCorrectionLevel: 'H',
        },
        dotsOptions: {
            color: '#231F20',
            type: 'square'
        },
        backgroundOptions: {
            color: '#FFFFFF',
        },
        imageOptions: {
            margin: 8,
            imageSize: 0.35,
            hideBackgroundDots: true
        },
        cornersSquareOptions: {
            type: 'square',
            color: '#231F20',
        },
    });
};