import {FC, useState} from 'react';
import styles from './contacts-header.module.scss';
import i18n from '../../../translations/i18n';
import {SearchInput} from '../../../components/common/searchInput/SearchInput';
import HeaderButton from '../../../components/common/button/HeaderButton';
import ExchangeContact from '../../previewCardProfile/exchangeContact/ExchangeContact';
import Title from '../../../components/common/title/Title';
import ExportDropdown from '../../../components/common/exportImport/ExportDropdown';
import {exportExchangeContactsToCsv} from '../../../api/exchangeContact';
import {downloadFile} from '../../../utils/download';
import {UserIntegrationStatus} from '../../../types/exchangeContacts';
import PlatformSync from '../platformSync/PlatformSync';


interface ContactsHeaderProps {
    onSearch: (text: string) => void;
    title: string;
    userId: number;
    reloadContactsApi: () => void;
    totalElements?: number;
    integrationStatus: UserIntegrationStatus;
    reloadIntegrationStatusApi: () => void;
}

const ContactsHeader: FC<ContactsHeaderProps> = ({
    onSearch, title, userId, reloadContactsApi, totalElements, integrationStatus, reloadIntegrationStatusApi
}) => {
    const [openExchangeContact, setOpenExchangeContact] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const hasAnyContact = totalElements ? totalElements > 0 : false;

    const handleOnCloseModal = () => {
        reloadContactsApi();
        setOpenExchangeContact(false);
    };

    const handleExportToCsv = () => {
        setLoading(true);
        exportExchangeContactsToCsv(userId)
            .then((res) => {
                const blob = new Blob([res.data]);
                downloadFile(window.URL.createObjectURL(blob), 'iCards_contacts.csv');
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className={styles.container}>
            <div className={styles.topSection}>
                <Title
                    text={title}
                    totalElements={totalElements}
                />
                <div className={styles.rightSection}>
                    {hasAnyContact &&
                        <ExportDropdown
                            loading={loading}
                            onExport={handleExportToCsv}
                        />
                    }
                    <HeaderButton
                        title={i18n.t('common.newContact')}
                        onClick={() => setOpenExchangeContact(true)}
                    />
                </div>
            </div>
            <div className={styles.search}>
                <SearchInput
                    placeholder={`${i18n.t('labels.searchNameOrEmail')}...`}
                    onSearch={onSearch}
                    className={styles.input}
                />

                <PlatformSync
                    integrationStatus={integrationStatus}
                    reloadIntegrationStatusApi={reloadIntegrationStatusApi}
                />
            </div>
            <ExchangeContact
                open={openExchangeContact}
                onClose={handleOnCloseModal}
                userId={userId}
                sendEmail={false}
            />
        </div>
    );
};

export default ContactsHeader;