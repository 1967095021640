import { FC, ReactNode, createContext, useContext } from 'react';
import {SelectAllObject} from '../pages/cards/CardsListView';

interface CardListContextType {
    selectAll: { selected: boolean, unselected: number[] };
    selected: number[];
    setSelected: (arg: number[]) => void;
    addSelected: (selected: number) => void;
    removeSelected: (selected: number) => void;
    noofCardsSelected: number;
    toggleSelectAll: () => void;
    reloadCardsApi?: () => void;
}

interface CardListContextProviderProps {
    children: ReactNode;
    selectAll: SelectAllObject;
    selected: number[];
    setSelected: (arg: number[]) => void;
    addSelected: (selected: number) => void;
    removeSelected: (selected: number) => void;
    noofCardsSelected: number;
    toggleSelectAll: () => void;
    reloadCardsApi?: () => void;
}

const CardListContext = createContext<CardListContextType>(null!);

export const CardListProvider: FC<CardListContextProviderProps> = ({
    children , selectAll, selected, setSelected, addSelected, removeSelected, noofCardsSelected, toggleSelectAll, reloadCardsApi
}) => {

    return (
        <CardListContext.Provider
            value={{
                selectAll, selected, setSelected, addSelected, removeSelected, noofCardsSelected, toggleSelectAll, reloadCardsApi
            }}>
            {children}
        </CardListContext.Provider>
    );
};

export const useCardListContext = () => {
    return useContext(CardListContext);
};
