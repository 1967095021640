import moment from 'moment';

// format example 2024/12/12 at 3:17pm
export const getFormattedTime = (inputTime: string) => moment(inputTime).format('YYYY/MM/DD [at] h:mma');

// format example "2024-12-31" to "31 Dec 2024"
export const getFormattedDate = (dateString: string): string => {
    return new Date(dateString).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
};

// format example "2025-02-27T10:18:12.655Z"" to "February 27, 2025"
export const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};
