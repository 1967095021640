import React, {FC} from 'react';
import styles from './salesforce-popup.module.scss';
import i18n from '../../../translations/i18n';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import {useNavigate} from 'react-router-dom';
import {routes} from '../../../constants/routes';

interface SalesforcePopupProps {
    onClose: () => void;
}

const SalesforcePopup: FC<SalesforcePopupProps> = ({ onClose }) => {
    const navigate = useNavigate();

    const goToSalesforceIntegrationForm = () => {
        navigate(routes.settings, { state: { salesforceForm: true } });
    };

    return (
        <div className={styles.salesforcePopup}>
            <div className={styles.salesforceWrapper}>
                {i18n.t('salesforce.enableIntegration')}
                <div
                    className={styles.salesforceConnect}
                    onClick={goToSalesforceIntegrationForm}
                >
                    {i18n.t('common.connect')}&nbsp;→
                </div>
            </div>
            <CloseIcon
                stroke="#FFFFFF"
                style={{cursor: 'pointer'}}
                onClick={onClose}
            />
        </div>
    );
};

export default SalesforcePopup;