import React, {FC, useCallback} from 'react';
import styles from './notification-box.module.scss';
import i18n from '../../translations/i18n';
import {
    CARD_ACTIVATED_OWNER,
    CARD_ACTIVATED_USER,
    CARD_ASSIGNED,
    CARD_OWNER_RECEIVED,
    CARD_OWNER_TRANSFERRED,
    COMPANY_ROLE_CHANGED,
    NEW_WEBAPP_RELEASE,
    NotificationDto,
    NotificationType
} from '../../types/notification';
import {openInNewTab} from '../../utils/common';
import {useNavigate} from 'react-router-dom';
import {navigateTo} from '../../constants/routes';

interface NotificationBoxProps {
    notification: NotificationDto;
    readNotification: (id: number) => void;
    closeNotification: () => void;
}

const NotificationBox: FC<NotificationBoxProps> = ({
    notification, readNotification, closeNotification
}) => {
    const navigate = useNavigate();
    const unread = !notification.read;

    const isData2True = (data2: string) => data2 && data2 === 'true';

    const getTitle = useCallback((type: NotificationType) => {
        if (type === CARD_ACTIVATED_USER) return i18n.t('notification.cardActivatedUserTitle');
        if (type === CARD_ACTIVATED_OWNER) return i18n.t('notification.cardActivatedOwnerTitle');
        if (type === CARD_ASSIGNED) return i18n.t('notification.cardAssignedTitle');
        if (type === CARD_OWNER_TRANSFERRED) return i18n.t('notification.cardOwnerTransferredTitle');
        if (type === CARD_OWNER_RECEIVED) return i18n.t('notification.cardOwnerReceivedTitle');
        if (type === COMPANY_ROLE_CHANGED) return i18n.t('notification.companyRoleAssignedTitle');
        if (type === NEW_WEBAPP_RELEASE) return i18n.t('notification.newFeatureTitle');
        return '';
    }, [notification, i18n]);

    const getDesc = useCallback((notify: NotificationDto) => {
        const type = notify.type;

        if (type === CARD_ACTIVATED_USER) return i18n.t('notification.cardActivatedUserDesc');
        if (type === CARD_ACTIVATED_OWNER) return i18n.t('notification.cardActivatedOwnerDesc');
        if (type === CARD_ASSIGNED) return i18n.t('notification.cardAssignedDesc');
        if (type === CARD_OWNER_TRANSFERRED) return i18n.t('notification.cardOwnerTransferredDesc');
        if (type === CARD_OWNER_RECEIVED) return i18n.t('notification.cardOwnerReceivedDesc');
        if (type === COMPANY_ROLE_CHANGED) return i18n.t('notification.companyRoleAssignedDesc', {
            role: notification.role, company: notification.companyName
        });
        if (type === NEW_WEBAPP_RELEASE) {
            return isData2True(notify.data2) ? i18n.t('notification.newFeaturesAdded') : i18n.t('notification.newFeatureAdded');
        }
        return '';
    }, [notification, i18n]);

    const handleOpenNotification = () => {
        readNotification(notification.id);

        if (notification.type === NEW_WEBAPP_RELEASE) {
            navigate(navigateTo.changelog);
            closeNotification();
        } else {
            openInNewTab(notification.url);
        }
    };

    return (
        <div key={notification.id} className={`${styles.container} ${unread ? styles.unread : styles.read}`} onClick={handleOpenNotification}>
            <div className={styles.title}>
                {getTitle(notification.type)}
            </div>
            <div className={styles.body}>
                {getDesc(notification)}
            </div>
            <div className={styles.timesAgoWrapper}>
                {unread &&
                    <div className={styles.timesAgoIndicator}></div>
                }
                <div className={styles.timesAgo}>
                    {notification.timeAgo}
                </div>
            </div>
        </div>
    );
};

export default NotificationBox;