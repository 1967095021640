import React, {FC, useState} from 'react';
import {useAlert} from '../../context/AlertProvider';
import {isEmpty} from '../../utils/common';
import {ERROR, SUCCESS} from '../../constants/common';
import i18n from '../../translations/i18n';
import ModalContainer from '../common/modalContainer/ModalContainer';
import styles from './create-company-modal.module.scss';
import BtnContainer from '../common/button/BtnContainer';
import {Input} from '../common/input/Input';
import {createNewCompany} from '../../api/company';
import {CompanyConverter} from '../../converters/api/CompanyConverter';
import {Company} from '../../types/company';
import {storeCompany} from '../../store/slices/userSessionSlice';
import {useAppDispatch} from '../../store/appDispatch';
import CloseButton from '../common/button/CloseButton';

interface CreateCompanyModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateCompanyModal: FC<CreateCompanyModalProps> = ({
    isOpen, onClose
}) => {
    if (!isOpen) return <></>;

    const [companyName, setCompanyName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const {setAlert} = useAlert();

    const saveDisabled = isEmpty(companyName);

    const handleSaveChanges = () => {
        if (saveDisabled) return;

        setLoading(true);
        createNewCompany({name: companyName})
            .then((res) => {
                if (res.error) {
                    const errorMsg = res.error.response?.data?.error;
                    setAlert(ERROR, errorMsg);
                } else {
                    const company: Company | undefined = CompanyConverter.convertFromCompanyApiToCompany(res.data);
                    company && dispatch(storeCompany(company));
                    setAlert(SUCCESS, i18n.t('messages.companyWasSuccessfullyCreated'));
                }
            })
            .finally(() => {
                onCloseModal();
                setLoading(false);
            });
    };

    const onCloseModal = () => {
        setCompanyName('');
        onClose();
    };

    const handleOnInputChange = (value: string) => setCompanyName(value);

    return (
        <ModalContainer>
            <div className={styles.header}>
                <div className={styles.headerTxt}>
                    {i18n.t('labels.createNewCompany')}
                </div>
                <CloseButton onClose={onClose}/>
            </div>
            <div className={styles.content}>
                <div>{i18n.t('labels.companyName')}</div>
                <Input
                    value={companyName}
                    placeholder={i18n.t('modal.enterCompanyName')}
                    onChange={(e) => handleOnInputChange(e.target.value)}
                    className={styles.input}
                />
                <BtnContainer
                    onCancel={onCloseModal}
                    onSave={handleSaveChanges}
                    loading={loading}
                    isDisabled={saveDisabled}
                    saveBtnText={i18n.t('common.save')}
                />
            </div>
        </ModalContainer>
    );
};

export default CreateCompanyModal;