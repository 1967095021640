import React, {FC, useCallback, useEffect, useMemo, useState,} from 'react';
import styles from './assign-card-modal.module.scss';
import i18n from '../../../translations/i18n';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning/warning-icon.svg';
import {isEmpty} from '../../../utils/common';
import {ERROR, SUCCESS} from '../../../constants/common';
import {useAlert} from '../../../context/AlertProvider';
import {getUserAssignList} from '../../../api/user';
import {getAssignSelectorStyle} from './AssignCardModalSelector.styles';
import ModalContainer from '../../common/modalContainer/ModalContainer';
import { SelectorWithSearch } from '../../common/selector/SelectorWithSearch';
import BtnContainer from '../../common/button/BtnContainer';
import {changeCardOwner} from '../../../api/card';
import CloseButton from '../../common/button/CloseButton';

interface ChangeCardOwnerModalProps {
    isOpen: boolean;
    onClose: () => void;
    userId: number;
    uuid: string;
    reloadCardsApi?: () => void;
}

const ChangeCardOwnerModal: FC<ChangeCardOwnerModalProps> = ({
    isOpen, onClose, userId, uuid, reloadCardsApi
}) => {
    if (!isOpen) return <></>;

    const [userEmail, setUserEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [userAssignEmails, setUserAssignEmails] = useState<string[]>([]);
    const {setAlert} = useAlert();

    const items = useMemo(() => userAssignEmails.map((uae, index) => ({label: uae, id: index+1})), [userAssignEmails]);

    const saveDisabled = isEmpty(userEmail);

    const getUserAssignListApi = useCallback( (userId: number) => {
        setLoading(true);
        getUserAssignList(userId)
            .then((res) => {
                if (res.error) return;

                setUserAssignEmails(res.data ? res.data : []);
            })
            .finally(() => setLoading(false));
    }, [userId]);

    const onSelectUserEmail = (value: {label: string, id: number}) => {
        const email = value.label;
        setUserEmail(email);
    };

    const handleSaveChanges = () => {
        if (saveDisabled) return;

        setLoading(true);
        changeCardOwner(userEmail, uuid)
            .then((res) => {
                if (res.error) {
                    const errorMsg = res.error.response?.data?.error;
                    setAlert(ERROR, errorMsg);
                } else {
                    reloadCardsApi && reloadCardsApi();
                    setAlert(SUCCESS, i18n.t('messages.profileWasSuccessfullyAssigned'));
                }
            })
            .finally(() => {
                onCloseModal();
                setLoading(false);
            });
    };

    const onCloseModal = () => {
        setUserEmail('');
        onClose();
    };

    const handleOnInputChange = (value: string) => setUserEmail(value);

    useEffect(() => {
        getUserAssignListApi(userId);
    }, [userId]);

    return (
        <ModalContainer>
            <div className={styles.header}>
                <div className={styles.headerTxt}>
                    {i18n.t('common.changeCardOwner')}
                </div>
                <CloseButton onClose={onClose}/>
            </div>
            <div className={styles.content}>
                <div>{i18n.t('common.emailAddress')}</div>
                <SelectorWithSearch
                    items={items}
                    defaultValue={{label: userEmail, id: 0}}
                    onSelect={onSelectUserEmail as any}
                    sx={getAssignSelectorStyle()}
                    onInputChange={handleOnInputChange}
                    iconDisabled
                    allowCustomInputs
                />
                <div className={styles.changeOwnerWrapper}>
                    <WarningIcon />
                    <div className={styles.changeOwnerDesc}>
                        {i18n.t('modal.changeCardOwnerDesc')}
                    </div>
                </div>
                <BtnContainer
                    onCancel={onCloseModal}
                    onSave={handleSaveChanges}
                    loading={loading}
                    isDisabled={saveDisabled}
                    saveBtnText={i18n.t('common.assign')}
                />
            </div>
        </ModalContainer>
    );
};

export default ChangeCardOwnerModal;