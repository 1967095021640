import React, {FC, useEffect, useState} from 'react';
import styles from './my-cards.module.scss';
import CardBoxView from '../../components/cards/cardBoxView/CardBoxView';
import {UserCardProfile} from '../../types/userCardProfile';
import {GRID} from '../../constants/common';
import OrderCardBox from '../../components/cards/orderCardBox/OrderCardBox';
import CustomCheckbox from '../../components/common/checkbox/CustomCheckbox';
import i18n from '../../translations/i18n';
import {useCardListContext} from '../../context/CardListProvider';
import listViewStyles from '../../components/cards/cardBoxView/card-list-view.module.scss';

interface CardsGridViewProps {
    cardProfiles: UserCardProfile[],
    onEdit: (cardProfileId: number) => void,
    onDelete: (cardProfileId: number) => void,
    onPreview: (uuid: string) => void,
    removeFromCompanyCards: (id: number) => Promise<void>,
    searchText: string,
    isMyCards?: boolean,
    reloadCardsApi?: () => void
}

const CardsGridView: FC<CardsGridViewProps> = ({
    cardProfiles, onEdit, onDelete, onPreview, removeFromCompanyCards,
    searchText, isMyCards, reloadCardsApi
}) => {
    const [isMediumScreen, setIsMediumScreen] = useState(false);

    const { toggleSelectAll, selectAll } = useCardListContext();

    useEffect(() => {
        const handleResize = () => {
            setIsMediumScreen(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.cardBox}>
            {!isMediumScreen
                ? <OrderCardBox/>
                : <div className={listViewStyles.headerRow}>
                    <CustomCheckbox
                        value={'selectAll'}
                        label={i18n.t('common.selectAll')}
                        isChecked={selectAll.selected}
                        onCheck={toggleSelectAll}
                    />
                </div>
            }
            {cardProfiles.map(profile => (
                <CardBoxView
                    key={profile.cardProfileId}
                    profile={profile}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onPreview={onPreview}
                    searchText={searchText}
                    removeFromCompanyCards={removeFromCompanyCards}
                    isMyCards={isMyCards}
                    reloadCardsApi={reloadCardsApi}
                    viewMode={GRID}
                />)
            )}
        </div>
    );
};

export default CardsGridView;