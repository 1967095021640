import {getApiCall, strapiUrl} from '../base';
import {FRENCH_VALUE, GERMAN_VALUE} from '../../types/language';

const releaseNotesUrl = `${strapiUrl}/api/release-notes`;

const getStrapiLocale = (language: string) => {
    const lang = language.toUpperCase();
    if (lang === GERMAN_VALUE) return 'de-DE';
    if (lang === FRENCH_VALUE) return 'fr-FR';
    return 'en';
};

export const getReleaseNotes = async (language: string) => {

    const config = {
        params: {
            locale: getStrapiLocale(language),
            fields: ['id', 'title', 'releaseDate', 'summary', 'version', 'features'],
            populate: {
                images: {
                    fields: ['url', 'width']
                }
            }
        }
    };

    return await getApiCall(releaseNotesUrl, config);
};