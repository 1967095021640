import {SendEmail} from '../types/user';
import {configDefaultWithToken, configForImages, postApiCall} from './base';

const emailUrl = '/api/email';
const supportEmailUrl = `${emailUrl}/send-support`;
const reportBugEmailUrl = `${emailUrl}/report-bug`;

export const sendSupportEmailById = async (id: number, data: SendEmail) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(`${supportEmailUrl}/${id}`,data, config);
};

export const sendReportBugEmailById = async (id: number, data: FormData) => {
    const config = {
        ...(await configForImages())
    };

    return await postApiCall(`${reportBugEmailUrl}/${id}`,data, config);
};