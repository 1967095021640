import {useState} from 'react';
import {ERROR, SUCCESS} from '../../../constants/common';
import {useAlert} from '../../../context/AlertProvider';
import {
    updateExchangeContactFromContactsSection
} from '../../../api/exchangeContact';
import i18n from '../../../translations/i18n';
import {ExchangeContactFormData} from '../../previewCardProfile/exchangeContact/ExchangeContact';
import {ExchangeContactConverter} from '../../../converters/api/ExchangeContactConverter';
import {ExchangeContactData} from '../../../types/exchangeContacts';

const useEditContactController = () => {
    const {setAlert} = useAlert();
    const [loading, setLoading] = useState<boolean>(false);

    const updateExchangeContactApi = async (
        contactId: number, exchangeFormData: Partial<ExchangeContactFormData>,
        onApiCallFinish: (contact: (ExchangeContactData | undefined)) => void, userImage: File | undefined
    ) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('contact', JSON.stringify(exchangeFormData));
        userImage && formData.append('userImage', userImage);

        updateExchangeContactFromContactsSection(contactId, formData)
            .then(res => {
                if (res.status === 400) {
                    setAlert(ERROR, res.error.response.data.error);
                } else if (res.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                } else {
                    setAlert(SUCCESS, i18n.t('messages.contactSuccessfullySaved'));
                    const data = ExchangeContactConverter.convertFromExchangeContactApiToExchangeContact(res.data);
                    onApiCallFinish(data);
                }
            })
            .finally(() => setLoading(false));
    };

    return {
        loading,
        updateExchangeContactApi
    };
};

export default useEditContactController;
