import React, {FC, useEffect, useState} from 'react';
import styles from './my-cards.module.scss';
import listViewStyles from '../../components/cards/cardBoxView/card-list-view.module.scss';
import CardBoxView from '../../components/cards/cardBoxView/CardBoxView';
import {
    UserCardProfile
} from '../../types/userCardProfile';
import {LIST} from '../../constants/common';
import {useCardListContext} from '../../context/CardListProvider';
import i18n from '../../translations/i18n';
import CustomCheckbox from '../../components/common/checkbox/CustomCheckbox';


export interface SelectAllObject {
    selected: boolean;
    unselected: number[];
}

interface CardsListViewProps {
    cardProfiles: UserCardProfile[],
    onEdit: (cardProfileId: number) => void,
    onDelete: (cardProfileId: number) => void,
    onPreview: (uuid: string) => void,
    removeFromCompanyCards: (id: number) => Promise<void>,
    searchText: string,
    isMyCards?: boolean,
    reloadCardsApi?: () => void
}

const CardsListView: FC<CardsListViewProps> = ({
    cardProfiles, onEdit, onDelete, onPreview, removeFromCompanyCards,
    searchText, isMyCards, reloadCardsApi
}) => {
    const [isMediumScreen, setIsMediumScreen] = useState(false);
    const { toggleSelectAll, selectAll, selected } = useCardListContext();

    useEffect(() => {
        const handleResize = () => {
            setIsMediumScreen(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className={styles.cardBox}>
                <div className={listViewStyles.headerRow}>
                    <CustomCheckbox
                        value={'selectAll'}
                        label={isMediumScreen ? i18n.t('common.selectAll') : ''}
                        isChecked={selectAll.selected}
                        onCheck={toggleSelectAll}
                    />
                    {!isMediumScreen && selected.length < 1 &&
                        <>
                            <div>ID</div>
                            <div style={{marginLeft: '100px'}}>{i18n.t('common.name')}</div>
                            <div style={{marginLeft: 'auto', marginRight: '90px'}}>{i18n.t('common.status')}</div>
                            <div>{i18n.t('common.actions')}</div>
                        </>
                    }
                </div>
                {cardProfiles.map(profile => (
                    <CardBoxView
                        key={profile.cardProfileId}
                        profile={profile}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onPreview={onPreview}
                        searchText={searchText}
                        removeFromCompanyCards={removeFromCompanyCards}
                        isMyCards={isMyCards}
                        reloadCardsApi={reloadCardsApi}
                        viewMode={LIST}
                    />
                ))}
            </div>
        </>
    );
};

export default CardsListView;