import React, {useMemo, useRef, useState} from 'react';
import HomeHeader from '../../components/layout/HomeHeader/HomeHeader';
import useContactsController from './useContactsController';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import styles from './contacts.module.scss';
import ContactsHeader from './contactsHeader/ContactsHeader';
import i18n from '../../translations/i18n';
import {useDebouncedCallback} from 'use-debounce';
import {API_CALL_DEBOUNCE_TIME_IN_MS} from '../../constants/common';
import {ExchangeContactData} from '../../types/exchangeContacts';
import CustomDataGrid from '../../components/common/grid/CustomDataGrid';
import {convertToExchangeContactFormData, getExchangeContactColumns} from './contactsColumnsUtils';
import SalesforcePopup from './salesforcePopup/SalesforcePopup';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import {getMenuStyle, getNotesStyle} from './contacts.style';
import ContactMenu from './menu/ContactMenu';
import ContactsMobileView from './mobileView/ContactsMobileView';
import { ExchangeContactProvider } from '../../context/ExchangeContactProvider';
import LoadingIcards from '../loading/LoadingIcards';
import useSalesforcePopupController from './salesforcePopup/useSalesforcePopupController';

const Contacts = () => {
    const user = useAppSelector(selectUser);
    const isSmallScreen  = useIsSmallScreen();
    const [openNotesRow, setOpenNotesRow] = useState<{ id: number, text: string } | undefined>(undefined);
    const [position, setPosition] = useState<{top: number; left: number} | undefined>(undefined);
    const [menuPosition, setMenuPosition] = useState<{top: number; left: number} | undefined>(undefined);
    const [openMenu, setOpenMenu] = useState<number | undefined>(undefined);
    const menuIconRef = useRef<HTMLDivElement>(null);

    const {
        loading, apiFilter, setApiFilter,
        exchangeContactPagination, addUpdatedExchangeContact, removeExchangeContact, reloadContactsApi
    } = useContactsController(user.id);

    const {
        integrationStatus, showSalesforcePopup, setShowSalesforcePopup, reloadIntegrationStatusApi
    } = useSalesforcePopupController(user.id);

    const exchangeContacts: ExchangeContactData[] = exchangeContactPagination?.contacts ?? [];
    const totalPages = exchangeContactPagination?.totalPages ?? 1;
    const totalElements = exchangeContactPagination?.totalElements;

    const columns = useMemo(() => getExchangeContactColumns(
        openNotesRow?.id, setPosition, setOpenNotesRow, apiFilter.searchBy ?? '',
        menuIconRef, openMenu, setOpenMenu, setMenuPosition
    ), [apiFilter, openNotesRow, apiFilter.searchBy, openMenu]);

    const userHasContacts = (exchangeContactPagination && exchangeContactPagination.noofElements > 0) ?? false;

    const onSearch = (text: string) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: 0,
            searchBy: text
        }));
    };

    const onSearchDebounced = useDebouncedCallback((text: string) => {
        return onSearch(text);
    }, API_CALL_DEBOUNCE_TIME_IN_MS);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: page - 1,
        }));
    };

    const getExchangeContactData = () => (
        convertToExchangeContactFormData(exchangeContacts.find(ec => ec.id === openMenu))
    );

    return (
        <ExchangeContactProvider
            addUpdatedExchangeContact={addUpdatedExchangeContact}
            removeExchangeContact={removeExchangeContact}
        >
            {!isSmallScreen && <HomeHeader/>}
            {showSalesforcePopup &&
                <SalesforcePopup onClose={() => setShowSalesforcePopup(false)}/>
            }
            <div className={styles.container}>
                <ContactsHeader
                    onSearch={onSearchDebounced}
                    title={i18n.t('common.contacts')}
                    userId={user.id}
                    reloadContactsApi={reloadContactsApi}
                    totalElements={totalElements}
                    integrationStatus={integrationStatus}
                    reloadIntegrationStatusApi={reloadIntegrationStatusApi}
                />
                {loading
                    ? <LoadingIcards className={styles.loading} />
                    : userHasContacts
                        ? isSmallScreen
                            ?  <ContactsMobileView
                                contacts={exchangeContacts}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                page={apiFilter?.page + 1}
                            />
                            : <CustomDataGrid
                                rows={exchangeContacts}
                                columns={columns}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                page={apiFilter?.page + 1}
                            />
                        : <div style={{alignSelf: 'center'}}>
                            {i18n.t('messages.noContactsFound')}
                        </div>
                }
                {openNotesRow?.id !== undefined && position &&
                    <div style={getNotesStyle(position)} >
                        <div className={styles.notesTitle}>
                            {i18n.t('common.notes')}
                        </div>
                        <div className={styles.notesText}>
                            {openNotesRow.text}
                        </div>
                    </div>
                }
                {openMenu &&
                    <div style={getMenuStyle(menuPosition)}>
                        <ContactMenu
                            contactId={openMenu}
                            menuIconRef={menuIconRef}
                            onClose={() => setOpenMenu(undefined)}
                            exchangeContact={getExchangeContactData()}
                        />
                    </div>
                }
            </div>
        </ExchangeContactProvider>
    );
};

export default Contacts;