import { FC, ReactElement, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Navigate } from 'react-router-dom';
import MainShell from '../components/layout/MainShell/MainShell';
import { useAppSelector } from '../store/appDispatch';
import { selectUser } from '../store/slices/userSessionSlice';
import ContactWidget from '../components/widget/ContactWidget';
import { IS_BUSINESS_ACCOUNT } from '../store/utils/userSession';
import LoadingScreen from '../pages/loading/LoadingScreen';
import React from 'react';

interface ProtectedProps {
    redirectTo?: string;
    component: ReactElement<any>;
    hasStickyMobileFooter?: boolean;
    onlyForBusinessAccount?: boolean;
}

export const Protected: FC<ProtectedProps> = ({
    redirectTo = '/',
    component,
    hasStickyMobileFooter,
    onlyForBusinessAccount,
}) => {
    const { keycloak } = useKeycloak();
    const user = useAppSelector(selectUser);
    const [isContactWidgetOpen, setIsContactWidgetOpen] = useState<boolean>(false);

    const loggedIn = keycloak.authenticated;

    if (onlyForBusinessAccount && !IS_BUSINESS_ACCOUNT()) {
        return <Navigate to={redirectTo} replace />;
    }

    if (!loggedIn) {
        keycloak.login();
        return <LoadingScreen />;
    }

    if (user == null) {
        return <LoadingScreen />;
    }

    if (!component) {
        return <Navigate to={redirectTo} replace />;
    }

    const toggleContactWidget = () => {
        setIsContactWidgetOpen((prev) => !prev);
    };

    const componentWithProps = React.cloneElement(component, {
        ...component.props,
        toggleContactWidget,
    });
    
    return (
        <MainShell hasStickyFooter={hasStickyMobileFooter}>
            {componentWithProps}
            <ContactWidget open={isContactWidgetOpen} onClose={toggleContactWidget} />
        </MainShell>
    );
};