import React, {FC, useRef, useState} from 'react';
import styles from './support.module.scss';
import {Input} from '../../common/input/Input';
import {NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX} from '../../../utils/validationUtils';
import i18n from '../../../translations/i18n';
import ModalContainer from '../../../components/common/modalContainer/ModalContainer';
import {TextArea} from '../../common/textArea/TextArea';
import {isBlank} from '../../../utils/common';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {SendEmail} from '../../../types/user';
import {sendSupportEmailById} from '../../../api/email';
import {useOutsideClick} from '../../../hooks/useOutsideCLick';
import Divider from '../../common/divider/Divider';
import BtnContainer from '../../common/button/BtnContainer';
import CloseButton from '../../common/button/CloseButton';
import {initSendEmail} from './support-utils';
import {ERROR, SUCCESS} from '../../../constants/common';
import {useAlert} from '../../../context/AlertProvider';


interface FeatureRequestModalProps {
    open: boolean;
    onClose: () => void;
}

const FeatureRequestModal: FC<FeatureRequestModalProps> = ({
    open, onClose
}) => {
    const {setAlert} = useAlert();
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement>(null);

    useOutsideClick([modalRef], () => { onClose(); });

    const [emailData, setEmailData] = useState<SendEmail>(initSendEmail(true));

    const isDisabled = isBlank(emailData.subject) || isBlank(emailData.body) || emailSent;

    const handleSendEmail = () => {
        setLoading(true);
        sendSupportEmailById(user.id, emailData)
            .then(res => {
                if (res.error) {
                    setAlert(ERROR, i18n.t('messages.emailSentFailed'));
                } else {
                    setEmailSent(true);
                    setAlert(SUCCESS, i18n.t('messages.emailSentSuccess'));
                    onClose();
                }
            })
            .finally(() => setLoading(false));
    };

    const handleOnClose = () => {
        setEmailData(initSendEmail(true));
        setEmailSent(false);
        onClose();
    };

    if (!open) return <></>;

    return (
        <ModalContainer className={styles.modalContainer}>
            <div ref={modalRef} className={styles.container}>
                <div className={styles.title}>
                    <div className={styles.titleTextWrapper}>
                        <div>
                            {i18n.t('settings.supportFeatureContactTitle')}
                        </div>
                        <div className={styles.subTitle}>
                            {i18n.t('settings.supportFeatureContactDesc')}
                        </div>
                    </div>
                    <CloseButton onClose={handleOnClose}/>
                </div>
                <Divider/>
                <div className={styles.body}>
                    <Input
                        notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                        className={styles.input}
                        label={i18n.t('common.subject')}
                        placeholder={i18n.t('common.subjectLine')}
                        value={emailData.subject}
                        onChange={(e) => setEmailData({...emailData, subject: e.target.value})}
                        required
                    />
                    <TextArea
                        notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                        label={i18n.t('common.request')}
                        placeholder={`${i18n.t('settings.supportFeatureContactPlaceholder')}...`}
                        value={emailData.body}
                        onChange={(e) => setEmailData({...emailData, body: e.target.value})}
                        rows={10}
                        required
                    />
                </div>
                <Divider/>
                <div className={styles.footerFeature}>
                    <BtnContainer
                        onCancel={handleOnClose}
                        onSave={handleSendEmail}
                        loading={loading}
                        isDisabled={isDisabled}
                        saveBtnText={emailSent ? i18n.t('common.sent') : i18n.t('settings.submitRequest')}
                    />
                </div>
            </div>
        </ModalContainer>
    );
};

export default FeatureRequestModal;