import React, {FC} from 'react';
import styles from './privacy-policy-checkbox.module.scss';
import {Checkbox} from '@mui/material';
import i18n from '../../../translations/i18n';
import {openInNewTab} from '../../../utils/common';
import {privacyPolicyURL} from '../../../api/base';

interface PrivacyPolicyCheckboxProps {
    checked: boolean;
    onCheck: () => void;
}

const PrivacyPolicyCheckbox: FC<PrivacyPolicyCheckboxProps> = ({
    checked, onCheck
}) => {
    return (
        <div className={styles.policy}>
            <Checkbox
                checked={checked}
                onChange={onCheck}
                inputProps={{'aria-label': 'controlled'}}
                style={{padding: 0}}
            />
            <div>
                <span>
                    {i18n.t('policy.iHaveRead')}
                </span>
                <div
                    className={styles.link}
                    onClick={() => openInNewTab(privacyPolicyURL)}
                >
                    {i18n.t('settings.privacyPolicy')}
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicyCheckbox;