import {FC} from 'react';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';

interface CustomDropdownProps {
    items: {name: string, onAction: () => void}[];
    className?: string;
}

const CustomDropdown: FC<CustomDropdownProps> = ({
    items, className
}) => {

    return (
        <Stack
            direction="row"
            spacing={2}
            style={{position: 'absolute', boxShadow: '0 0 4px 0 #0000000A'}}
            className={className}
        >
            <Paper style={{marginTop: '8px', borderRadius: '8px'}}>
                <MenuList style={{padding: '8px'}}>
                    {items.map((item, index) =>
                        <MenuItem
                            key={index}
                            onClick={item.onAction}
                            style={{fontFamily: 'Lato'}}
                        >
                            {item.name}
                        </MenuItem>
                    )}
                </MenuList>
            </Paper>
        </Stack>
    );
};

export default CustomDropdown;