import React, {FC, useRef, useState} from 'react';
import CardListView from './CardListView';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {updateCardProfileStatus} from '../../../api/cardProfile';
import {ACTIVE, CardProfileStatus, UserCardProfile} from '../../../types/userCardProfile';
import MyCardsMenu from '../myCardsMenu/MyCardsMenu';
import {GRID} from '../../../constants/common';
import CardGridView from './CardGridView';
import {ShareModal} from '../../common/shareModal/ShareModal';
import AssignCardModal from '../cardModals/AssignCardModal';
import ChangeCardOwnerModal from '../cardModals/ChangeCardOwnerModal';
import listStyles from './card-list-view.module.scss';
import {IS_COMPANY_ADMIN} from '../../../store/utils/userSession';
import {ViewSelectorType} from '../myCardsHeader/ViewSelector';


interface CardBoxViewProps {
    profile: UserCardProfile,
    onEdit: (cardProfileId: number) => void,
    onDelete: (cardProfileId: number) => void,
    onPreview: (uuid: string) => void,
    removeFromCompanyCards: (id: number) => Promise<void>,
    searchText: string,
    isMyCards?: boolean,
    reloadCardsApi?: () => void,
    viewMode: ViewSelectorType
}

const CardBoxView: FC<CardBoxViewProps> = ({
    profile, onEdit, onDelete, onPreview, removeFromCompanyCards, searchText, isMyCards, reloadCardsApi, viewMode
}) => {
    const user = useAppSelector(selectUser);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [openShareModal, setOpenShareModal] = useState<boolean>(false);
    const [openAssignCardModal, setOpenAssignCardModal] = useState<boolean>(false);
    const [openChangeCardOwnerModal, setOpenChangeCardOwnerModal] = useState<boolean>(false);

    const isCardOwner = user.id === profile.owner.id;
    const canChangeOwner = (isMyCards && isCardOwner) ?? false;
    const canMoveToMyCards = (!isMyCards && isCardOwner) ?? false;
    const managedByOtherUserFlag = (isMyCards && !isCardOwner) ?? false;
    const managedByCompanyFlag = !isMyCards && !IS_COMPANY_ADMIN();

    const menuIconRef = useRef<HTMLDivElement>(null);

    const handleModalView = () => {
        setTimeout(() => {
            setShowMenu(prevState => !prevState);
        }, 50);
    };

    const handleUpdateCardProfileStatus = (status: CardProfileStatus) => updateCardProfileStatus(profile.cardProfileId, status);
    const handleOnEdit = () => onEdit(profile.cardProfileId);
    const handleDelete = () => onDelete(profile.cardProfileId);
    const handlePreview = () => onPreview(profile.uuid);
    const handleRemoveFromCompany = () => removeFromCompanyCards(profile.cardProfileId).then(() => setShowMenu(false));

    const isProfileActive = profile.status === ACTIVE;


    const getMenu = (sx?: any) => (
        <MyCardsMenu
            handlePreview={handlePreview}
            handleShare={() => setOpenShareModal(true)}
            handleEdit={handleOnEdit}
            handleAssignCardTo={() => setOpenAssignCardModal(true)}
            handleChangeCardOwner={() => setOpenChangeCardOwnerModal(true)}
            handleSwitchStatus={handleUpdateCardProfileStatus}
            handleDelete={handleDelete}
            isActive={isProfileActive}
            handleRemoveFromCompany={handleRemoveFromCompany}
            isMyCards={isMyCards}
            onClose={() => setShowMenu(false)}
            canChangeOwner={canChangeOwner}
            canMoveToMyCards={canMoveToMyCards}
            menuIconRef={menuIconRef}
            profileId={profile.cardProfileId}
            reloadCardsApi={reloadCardsApi}
            sx={sx}
        />
    );

    return (
        <>
            {(viewMode === GRID)
                ? <CardGridView
                    profile={profile}
                    onEdit={onEdit}
                    searchText={searchText}
                    showMenu={showMenu}
                    handleModalView={handleModalView}
                    menu={getMenu()}
                    managedByOtherUserFlag={managedByOtherUserFlag}
                    managedByCompanyFlag={managedByCompanyFlag}
                    menuIconRef={menuIconRef}
                />
                : <CardListView
                    profile={profile}
                    onEdit={onEdit}
                    searchText={searchText}
                    showMenu={showMenu}
                    handleModalView={handleModalView}
                    menu={getMenu(listStyles.menuContainer)}
                    managedByOtherUserFlag={managedByOtherUserFlag}
                    managedByCompanyFlag={managedByCompanyFlag}
                    menuIconRef={menuIconRef}
                />
            }
            <ShareModal
                isOpen={openShareModal}
                uuid={profile.uuid}
                onClose={() => setOpenShareModal(false)}
                fullName={user.fullname}
            />
            <AssignCardModal
                isOpen={openAssignCardModal}
                onClose={() => setOpenAssignCardModal(false)}
                cardProfileId={profile.cardProfileId}
                userId={user.id}
            />
            <ChangeCardOwnerModal
                isOpen={openChangeCardOwnerModal}
                onClose={() => setOpenChangeCardOwnerModal(false)}
                userId={user.id}
                uuid={profile.uuid}
                reloadCardsApi={reloadCardsApi}
            />
        </>
    );
};

export default CardBoxView;