import React, { FC, useState } from 'react';
import FileInput from './FileInput';
import styles from '../fileInput/file-input.module.scss';
import uStyles from './upload-take-photo-image.module.scss';
import i18n from '../../../translations/i18n';
import { ReactComponent as AvatarIcon } from '../../../assets/icons/general/avatar.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { ReactComponent as CameraIcon } from '../../../assets/icons/camera.svg';
import MobileCameraFileInput from './MobileCameraFileInput';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';
import {notEmpty} from '../../../utils/common';
import {imageBaseUrl} from '../../../api/base';

interface UploadOrTakePhotoImageProps {
    id: string;
    onPhotoChange: (newPhoto: File) => void;
    profilePhoto?: string;
    initialUserPhoto?: string;
}

const UploadOrTakePhotoImage: FC<UploadOrTakePhotoImageProps> = ({
    id, onPhotoChange, profilePhoto, initialUserPhoto
}) => {
    const [key, setKey] = useState<number>(0);

    const isSmallScreen = useIsSmallScreen();

    const initialUserPath = (notEmpty(initialUserPhoto) && imageBaseUrl) ? `${imageBaseUrl + initialUserPhoto}` : '';
    const userProfilePhotoToDisplay = (notEmpty(initialUserPath) && !profilePhoto) ? initialUserPath : profilePhoto;

    const handleFileChange = (file: File) => {
        onPhotoChange(file);
        setKey(prevKey => prevKey + 1);
    };

    return (
        <div className={uStyles.container} key={key}>
            <div className={uStyles.label}>
                {i18n.t('common.profilePicture')} ({i18n.t('common.optional')})
            </div>
            <div className={uStyles.imageContainer}>
                <div className={styles.avatarContainer}>
                    {userProfilePhotoToDisplay ? (
                        <img
                            className={styles.avatarImage}
                            src={userProfilePhotoToDisplay}
                            alt=""
                        />
                    ) : (
                        <AvatarIcon />
                    )}
                </div>
                <div className={uStyles.profileUploadBtnContainer}>
                    <FileInput
                        id={id}
                        icon={<div className={uStyles.icon}><UploadIcon /></div>}
                        label={isSmallScreen ? '' : i18n.t('profile.general.upload')}
                        labelClassName={uStyles.photoImage}
                        onFileChange={handleFileChange}
                    />
                    <div className={uStyles.orText}>
                        {i18n.t('common.or')}
                    </div>
                    <MobileCameraFileInput
                        id={id}
                        icon={<div className={uStyles.icon}><CameraIcon/></div>}
                        label={isSmallScreen ? '' : i18n.t('common.takeAphoto')}
                        labelClassName={uStyles.photoImage}
                        onFileChange={handleFileChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default UploadOrTakePhotoImage;
