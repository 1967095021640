import React, { FC } from 'react';
import i18n from '../../../translations/i18n';
import styles from './setting-menu.module.scss';
import { Typography } from '@mui/material';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg';
import { ReactComponent as VisitIcon } from '../../../assets/icons/visit.svg';
import { ReactComponent as HelpDeskIcon } from '../../../assets/icons/help-desk-settings.svg';
import { openInNewTab } from '../../../utils/common';
import { ICARDS_FULL_URL } from '../../../constants/common';
import { useNavigate } from 'react-router-dom';
import { navigateTo } from '../../../constants/routes';
import { useKeycloak } from '@react-keycloak/web';

interface SettingMenuProps {
    user: any
    isSmallScreen: boolean
}

const SettingMenu: FC<SettingMenuProps> = ({ user, isSmallScreen }) => {

    const navigate = useNavigate();
    const { keycloak } = useKeycloak();


    const TypographyStyle = {
        p: 1.5,
        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
    };

    const navigateToSettingsPage = () => navigate(navigateTo.settings);
    const navigateToWebsite = () => openInNewTab(ICARDS_FULL_URL);
    const navigateToHelpDeskPage = () => navigate(navigateTo.helpDesk);

    const onLogout = () => keycloak.logout();


    return (
        <div className={isSmallScreen ? styles.mobileMenuItems : ''}>
            {!isSmallScreen &&
                <Typography className={`${styles.settingWrapperSignIn} ${styles.settingWrapper}`} sx={TypographyStyle}>
                    <div className={styles.subtext}>
                        {i18n.t('header.signedInAs')}
                    </div>
                    <div className={styles.text}>
                        {user?.email}
                    </div>
                </Typography>}
            <Typography className={`${styles.text} ${styles.settingWrapper}`} sx={TypographyStyle} onClick={navigateToSettingsPage}>
                <SettingsIcon />
                {i18n.t('header.accountSetting')}
            </Typography>
            <Typography className={`${styles.text} ${styles.settingWrapper}`} sx={TypographyStyle} onClick={navigateToWebsite}>
                <div className={styles.iconWrapper}><VisitIcon /></div>
                {i18n.t('header.visitWebsite')}
            </Typography>
            <Typography className={`${styles.text} ${styles.settingWrapper}`} sx={TypographyStyle} onClick={navigateToHelpDeskPage}>
                <div className={styles.iconWrapper}><HelpDeskIcon /></div>
                {i18n.t('common.helpDesk')}
            </Typography>
            <div className={styles.logoutButton} onClick={onLogout}>
                <Typography className={`${styles.text} ${styles.settingWrapper}`} sx={TypographyStyle}>
                    <LogoutIcon />
                    {i18n.t('header.signOut')}
                </Typography>
            </div>
        </div>
    );
};

export default SettingMenu;