import { useState, useEffect } from 'react';

const useIsSmallScreen = (breakpoint: number = 576): boolean => {
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = (): void => {
            setIsSmallScreen(window.innerWidth <= breakpoint);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [breakpoint]);

    return isSmallScreen;
};

export default useIsSmallScreen;
