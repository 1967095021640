import React, {FC} from 'react';
import { ReactComponent as IcardsLogoNoColorImg } from '../../assets/icons/logos/icards-logo-loading.svg';
import styles from './loading-screen.module.scss';

interface LoadingIcardsProps {
    fill?: string;
    className?: string;
}

const LoadingIcards: FC<LoadingIcardsProps> = ({ fill, className }) => {
    return (
        <div className={`${styles['ic-logo']} ${className}`}>
            <IcardsLogoNoColorImg
                className={styles['ic-logo__icon']}
                style={{fill: fill ? fill : '#F0F0F0'}}
                width={120} height={136}
            />
            <div className={styles['ic-logo__fill']}></div>
        </div>
    );
};

export default LoadingIcards;