import React, {FC, RefObject} from 'react';
import styles from './three-dots-menu.module.scss';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/icons/three-dots.svg';

interface ThreeDotsMenuProps {
    menuIconRef: RefObject<HTMLDivElement>;
    isOpen: boolean;
    toggleOpen: () => void;
    style?: any;
}

const ThreeDotsMenu: FC<ThreeDotsMenuProps> = ({
    menuIconRef, isOpen, toggleOpen, style
}) => {
    return (
        <div
            ref={menuIconRef}
            style={style}
            className={`${styles.menuContainer} ${isOpen && styles.menuActive}`}
            onClick={toggleOpen}
        >
            <ThreeDotsIcon className={styles.dotsIcon}/>
        </div>
    );
};

export default ThreeDotsMenu;