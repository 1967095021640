import {useState, useEffect, useRef} from 'react';
import {replaceEyesWithSVGs, replacePlaceholderImageWithLogo} from './qrCodeUtils';
import {logoSvgCode} from './logoSvgCode';
import {getQrCodePrettier} from './QrCodePrettier';
import {ICARDS_FULL_URL} from '../../constants/common';
import {getQrCodeDefault} from './QrCodeDefault';


const useQrcodeGenerator = (uuid: string, qrPrettier: boolean) => {
    const qrCodeRef = useRef<HTMLDivElement | null>(null);
    const [qrCodeInstance, setQrCodeInstance] = useState<string | null>(null);

    const getModifiedQrCode = async (qrCodeImage: any, qrPrettier: boolean): Promise<string | undefined> => {
        return await new Promise<string | undefined>((resolve, reject) => {
            qrCodeImage.getRawData('svg')
                .then((blob: Blob) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const parser = new DOMParser();
                        const svgDoc = parser.parseFromString(reader.result as string, 'image/svg+xml');
                        const svgElement = svgDoc.querySelector('svg');

                        if (svgElement) {
                            qrPrettier && replacePlaceholderImageWithLogo(svgElement, logoSvgCode);
                            qrPrettier && replaceEyesWithSVGs(svgElement);
                            const size = qrPrettier ? '66' : '100%';
                            svgElement.setAttribute('width', size);
                            svgElement.setAttribute('height', size);

                            const updatedSvgString = new XMLSerializer().serializeToString(svgElement);
                            resolve(updatedSvgString);
                        } else {
                            reject(new Error('Failed to find SVG element in the QR Code.'));
                        }
                    };
                    if (blob) {
                        reader.readAsText(blob);
                    } else {
                        reject(new Error('QR Code blob is null.'));
                    }
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    };

    useEffect(() => {
        const data = `${ICARDS_FULL_URL}/${uuid}`;

        const qrcode = qrPrettier
            ? getQrCodePrettier(data, 300)
            : getQrCodeDefault(data, 300);

        getModifiedQrCode(qrcode, qrPrettier)
            .then(updatedQrCode => {
                if (updatedQrCode) {
                    setQrCodeInstance(updatedQrCode);
                }
            })
            .catch(error => console.error('Error modifying QR Code:', error));
    }, [uuid]);

    return { qrCodeRef, qrCodeInstance };
};

export default useQrcodeGenerator;