import {configDefaultWithToken, deleteApiCall, postApiCall} from './base';
import {HubSpotRequest} from '../types/integration';


const hubspotUrl = '/api/hubspot';
const hubSpotCredentialsByUserUrl = (userId: number) => `${hubspotUrl}/credentials/${userId}`;
const disconnectHubSpotByUserUrl = (userId: number) => `${hubspotUrl}/disconnect/${userId}`;

export const saveHubSpotCredentials = async (userId: number, data: HubSpotRequest) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await postApiCall(hubSpotCredentialsByUserUrl(userId), data, config);
};

export const disconnectHubSpot = async (userId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await deleteApiCall(disconnectHubSpotByUserUrl(userId), config);
};