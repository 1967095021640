import React, {FC, useState} from 'react';
import i18n from '../../../translations/i18n';
import CustomButton from '../../../components/common/button/CustomButton';
import {ReactComponent as WarningTriangleIcon} from '../../../assets/icons/warning/warning-triangle.svg';
import {ReactComponent as CheckCircleIcon} from '../../../assets/icons/check-circle.svg';
import {ReactComponent as SpinnerIcon} from '../../../assets/icons/loading/spinner.svg';
import {UserIntegrationStatus} from '../../../types/exchangeContacts';
import styles from './platform-sync.module.scss';
import {syncExchangeContactFromContactsSection} from '../../../api/exchangeContact';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {useAppSelector} from '../../../store/appDispatch';
import {ERROR, SUCCESS} from '../../../constants/common';
import {useAlert} from '../../../context/AlertProvider';

interface PlatformSyncProps {
    integrationStatus: UserIntegrationStatus;
    reloadIntegrationStatusApi: () => void;
}

const PlatformSync: FC<PlatformSyncProps> = ({
    integrationStatus, reloadIntegrationStatusApi
}) => {
    const user = useAppSelector(selectUser);
    const { setAlert } = useAlert();
    const [loading, setLoading] = useState<boolean>(false);

    const allSynced = integrationStatus.contactsToSync < 1;
    const noIntegrationPlatforms = !integrationStatus.salesforce && !integrationStatus.hubSpot;


    const handlePlatformSync = () => {
        setLoading(true);
        syncExchangeContactFromContactsSection(user.id)
            .then(res => {
                if (res.error) {
                    setAlert(ERROR, i18n.t('messages.contactSyncFailed'));
                } else {
                    setAlert(SUCCESS, i18n.t('messages.contactSuccessfullySynced'));
                    reloadIntegrationStatusApi();
                }
            })
            .finally(() => setLoading(false));
    };

    const getIcon = () => {
        if (allSynced) return <CheckCircleIcon className={styles.icon} />;
        if (loading) return <SpinnerIcon className={styles.spinner} />;
        return <WarningTriangleIcon className={styles.icon} />;
    };

    const getSyncText = () => {
        if (allSynced) return i18n.t('integration.allSynced');
        if (loading) return i18n.t('integration.syncingContacts', {count: integrationStatus.contactsToSync});
        return i18n.t('integration.syncNewContacts', {count: integrationStatus.contactsToSync});
    };

    if (noIntegrationPlatforms) return <></>;

    return (
        <div className={styles.syncWrapper}>
            {getIcon()}
            <div className={styles.syncText}>
                {getSyncText()}
            </div>
            {!allSynced &&
                <CustomButton
                    text={i18n.t(('integration.syncNow'))}
                    onClick={handlePlatformSync}
                    sx={styles.btn}
                    disabled={loading}
                />
            }
        </div>
    );
};

export default PlatformSync;