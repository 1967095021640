import * as React from 'react';
import {ElementType, FC, ReactElement, useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styles from './selector.module.scss';

export const NON_SELECTABLE_ITEM = 'non-selectable';

export type SelectorItem = {
    value: any,
    name: string,
    icon?: any
};

interface SelectorProps {
    label?: string;
    items: SelectorItem[];
    onClick?: () => void;
    onSelect: (value: any) => void
    defaultValue?: string;
    selectDefaultValue?: boolean;
    formStyle?: string;
    icon?: ElementType;
    selectStyle?: object;
    selectedItemStyle?: React.CSSProperties;
    MenuProps?: object;
    notConvert?: boolean;
    resetSelector?: number;
    triggerElement?: ReactElement;
    disabled?: boolean;
}

export const Selector: FC<SelectorProps> = ({
    label, items, onClick, onSelect, defaultValue, selectDefaultValue, formStyle, icon,
    selectStyle, selectedItemStyle, MenuProps, notConvert, resetSelector, triggerElement, disabled
}) => {
    const [value, setValue] = React.useState(defaultValue ?? '');

    const handleChange = (event: SelectChangeEvent) => {
        const val = event.target.value;
        if (val === NON_SELECTABLE_ITEM) return;

        setValue(val);
        onSelect(val);
    };

    const handleClick = () => onClick && onClick();

    const convert = (val: string): string => (
        val ? (val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()) : ''
    );

    useEffect(() => {
        (defaultValue && defaultValue != value) && setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        resetSelector && (resetSelector > 0) && defaultValue && setValue(defaultValue);
    }, [resetSelector]);

    return (
        <FormControl id='selector-form' className={`${formStyle} ${styles.form}`} size="small">
            <InputLabel id="label" className={styles.label}>
                {label}
            </InputLabel>
            <Select
                className={styles.select}
                labelId="label"
                id="select"
                value={value}
                label={label}
                onClick={handleClick}
                onChange={handleChange}
                IconComponent={icon}
                sx={{
                    ...selectStyle,
                    '& .MuiSelect-icon': {
                        right: 12,
                        pointerEvents: 'none',
                        top: 'auto'
                    },
                }}
                MenuProps={MenuProps}
                disabled={disabled}
            >
                {selectDefaultValue && defaultValue && (
                    <MenuItem value={defaultValue} style={{display: 'none'}}>
                        {defaultValue}
                    </MenuItem>
                )}

                {items
                    .filter(item => !selectDefaultValue || !defaultValue?.includes(item.name))
                    .map(item => (
                        <MenuItem
                            value={item.value}
                            style={selectedItemStyle}
                            key={item.value}
                        >
                            {item.icon}
                            {!notConvert ? convert(item.name) : item.name}
                        </MenuItem>
                    ))}
                {triggerElement}
            </Select>
        </FormControl>
    );
};