import React, {FC, useRef, useState} from 'react';
import styles from './support.module.scss';
import {NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX} from '../../../utils/validationUtils';
import i18n from '../../../translations/i18n';
import ModalContainer from '../../../components/common/modalContainer/ModalContainer';
import {TextArea} from '../../common/textArea/TextArea';
import {isBlank, notEmpty} from '../../../utils/common';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {ReportBugEmail} from '../../../types/user';
import {sendReportBugEmailById} from '../../../api/email';
import {useOutsideClick} from '../../../hooks/useOutsideCLick';
import Divider from '../../common/divider/Divider';
import BtnContainer from '../../common/button/BtnContainer';
import CloseButton from '../../common/button/CloseButton';
import {formatFileSize, initBugReportEmail} from './support-utils';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-cloud.svg';
import { ReactComponent as PaperClipIcon } from '../../../assets/icons/paper-clip.svg';
import { ReactComponent as CloseCircleIcon } from '../../../assets/icons/close-circle.svg';
import {useAlert} from '../../../context/AlertProvider';
import {ERROR, SUCCESS} from '../../../constants/common';



interface ReportBugModalModalProps {
    open: boolean;
    onClose: () => void;
}

const ReportBugModal: FC<ReportBugModalModalProps> = ({
    open, onClose
}) => {
    const {setAlert} = useAlert();
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement>(null);

    useOutsideClick([modalRef], () => { onClose(); });

    const [emailData, setEmailData] = useState<ReportBugEmail>(initBugReportEmail());
    const [files, setFiles] = useState<File[]>([]);

    const isDisabled = isBlank(emailData.description) || emailSent;

    const handleSendEmail = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('data', JSON.stringify(emailData));

        if (notEmpty(files)) {
            files.forEach((file) => {
                formData.append('files', file);
            });
        }

        sendReportBugEmailById(user.id, formData)
            .then(res => {
                if (res.error) {
                    setAlert(ERROR, i18n.t('messages.emailSentFailed'));
                } else {
                    setEmailSent(true);
                    setAlert(SUCCESS, i18n.t('messages.emailSentSuccess'));
                    onClose();
                }
            })
            .finally(() => setLoading(false));
    };

    const handleOnClose = () => {
        setEmailData(initBugReportEmail());
        setEmailSent(false);
        setFiles([]);
        onClose();
    };
    
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles = Array.from(event.target.files);
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }
    };

    const handleRemoveFile = (index: number) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    if (!open) return <></>;

    return (
        <ModalContainer className={styles.modalContainer}>
            <div ref={modalRef} className={styles.container}>
                <div className={styles.title}>
                    <div className={styles.titleTextWrapper}>
                        <div>
                            {i18n.t('settings.reportBug')}
                        </div>
                    </div>
                    <CloseButton onClose={handleOnClose}/>
                </div>
                <Divider/>
                <div className={styles.body}>
                    <TextArea
                        notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                        label={i18n.t('labels.stepsToTeproduceTheIssue')}
                        placeholder={`${i18n.t('settings.supportFeatureContactPlaceholder')}...`}
                        value={emailData.description}
                        onChange={(e) => setEmailData({...emailData, description: e.target.value})}
                        rows={5}
                        required
                    />
                    <div className={styles.fileUploadContainer}>
                        <span className={styles.label} >{i18n.t('labels.stepsToTeproduceTheIssue')}</span>
                        <label htmlFor="file-upload" className={styles.fileUploadLabel}>
                            <div className={styles.fileUploadBox}>
                                <UploadIcon/>
                                <span className={styles.fileUploadtitle}>
                                    {i18n.t('common.selectFile')}
                                </span>
                                <span className={styles.fileUploadsubtitle}>
                                    {i18n.t('common.supportedFormat')}
                                </span>
                            </div>
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            accept=".jpeg, .png, .gif, .pdf"
                            className={styles.fileInput}
                        />
                        {files.length > 0 && files.map((file, index) => (
                            <div className={styles.selectedFiles}>
                                <div className={`${styles.fileSideName} ${styles.textEllipsis}`}>
                                    <PaperClipIcon/>
                                    <span className={styles.textEllipsis} key={index}>
                                        {file.name}
                                    </span>
                                </div>
                                <div className={styles.fileSide}>
                                    <span key={index}> {formatFileSize(file.size)} </span>
                                    <div onClick={() => handleRemoveFile(index)} className={styles.close}>
                                        <CloseCircleIcon/>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>                   
                </div>
                <Divider/>
                <div className={styles.footerFeature}>
                    <BtnContainer
                        onCancel={handleOnClose}
                        onSave={handleSendEmail}
                        loading={loading}
                        isDisabled={isDisabled}
                        saveBtnText={emailSent ? i18n.t('common.sent') : i18n.t('common.report')}
                    />
                </div>
            </div>
        </ModalContainer>
    );
};

export default ReportBugModal;