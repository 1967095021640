import React, { FC, useState } from 'react';
import { NON_SELECTABLE_ITEM, Selector } from './Selector';
import i18n from '../../../translations/i18n';
import styles from './selector.module.scss';
import { MenuItem } from '@mui/material';
import { MenuItemStyle, selectCompanyStyle } from '../../layout/AppHeader/AppHeaderSelectorStyle';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow/arrow-down.svg';
import CreateCompanyModal from '../../createCompanyModal/CreateCompanyModal';
import { useAppDispatch } from '../../../store/appDispatch';
import { useAlert } from '../../../context/AlertProvider';
import { setUserActiveCompany } from '../../../api/company';
import { Company } from '../../../types/company';
import { ERROR } from '../../../constants/common';
import { setActiveCompany } from '../../../store/slices/userSessionSlice';
import { CompanyConverter } from '../../../converters/api/CompanyConverter';

interface CompanySelectorProps {
    companyItems: any;
    defaultCompany: any;
    hasCompany: boolean;
    isSmallScreen: boolean;
}

const CompanySelector: FC<CompanySelectorProps> = ({ companyItems, defaultCompany, isSmallScreen, hasCompany }) => {
    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
    
    const { setAlert } = useAlert();
    const dispatch = useAppDispatch();

    const updateNewCompany = (item: number) => {
        setUserActiveCompany(item)
            .then(res => {
                if (res.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                } else {
                    const company: Company | undefined = CompanyConverter.convertFromCompanyApiToCompany(res.data?.activeCompany);
                    dispatch(setActiveCompany(company));
                }
            });
    };

    return (
        <>
            {hasCompany ? (
                <Selector
                    items={companyItems}
                    defaultValue={defaultCompany}
                    onSelect={updateNewCompany}
                    formStyle={isSmallScreen ? styles.selector : styles.selectorCompany}
                    icon={ArrowDownIcon}
                    MenuProps={!isSmallScreen ? MenuItemStyle : undefined}
                    selectStyle={!isSmallScreen ? selectCompanyStyle : undefined}
                    label={isSmallScreen ? i18n.t('labels.company') : ''}
                    triggerElement={
                        <MenuItem
                            value={NON_SELECTABLE_ITEM}
                            style={{ fontSize: '14px', border: '1px solid #808080', borderRadius: '4px', margin: '4px' }}
                            onClick={() => setOpenCreateModal(true)}
                        >
                            {i18n.t('labels.createCompany')}
                        </MenuItem>
                    }
                />
            ) :
                <div className={isSmallScreen ? styles.mobileSwitcher : styles.switcher} onClick={() => setOpenCreateModal(true)}>
                    {i18n.t('header.switchToBusinessAccount')}
                </div>
            }
            <CreateCompanyModal
                isOpen={openCreateModal}
                onClose={() => setOpenCreateModal(false)}
            />
        </>
    );
};

export default CompanySelector;