// IMPORTANT: new routes should not be exact 7 letters length because of preview card profile UUID which is of length 7

export const routes = {
    iportal: '/iportal', // do not change or modify this
    previewCardProfile: '/:uuid',
    analytics: '/',
    myCards: '/my-cards',
    companyCards: '/company-cards',
    profile: '/profile/:id',
    walletCard: '/wallet-card/:id',
    settings: '/settings',
    bulkEditProfiles: '/profiles/edit',
    bulkEditWalletProfiles: '/wallets/edit',
    contacts: '/contacts',
    changelog: '/changelog',
    helpDesk:'/help-desk',
    categorySlug: '/help-desk/:categorySlug',
    articleSlug: '/help-desk/:categorySlug/:articleSlug'
};

export const navigateTo = {
    previewCardProfile: '',
    analytics: routes.analytics,
    myCards: routes.myCards,
    companyCards: routes.companyCards,
    profile: '/profile',
    walletCard: '/wallet-card',
    settings: routes.settings,
    bulkEditProfiles: routes.bulkEditProfiles,
    bulkEditWalletProfiles: routes.bulkEditWalletProfiles,
    contacts: routes.contacts,
    changelog: routes.changelog,
    helpDesk: routes.helpDesk,
    categorySlug: '/help-desk',
    articleSlug: '/help-desk'
};