import {CardProfileSocialLink} from '../../../types/cardProfile';
import {
    COMPANY_LINKEDIN, CUSTOM_LINK,
    FACEBOOK, GITHUB,
    INSTAGRAM,
    PERSONAL_LINKEDIN, PINTEREST, REDDIT,
    SNAPCHAT, THREADS,
    TIKTOK,
    X,
    YOUTUBE
} from '../../../types/socialLinks';
import { ReactComponent as LogoInstagramIcon } from '../../../assets/icons/logos/logo-instagram.svg';
import { ReactComponent as LogoLinkedinIcon } from '../../../assets/icons/logos/logo-linkedin.svg';
import { ReactComponent as LogoXIcon } from '../../../assets/icons/logos/logo-x.svg';
import { ReactComponent as LogoTikTokIcon } from '../../../assets/icons/logos/logo-tiktok.svg';
import { ReactComponent as LogoFacebookIcon } from '../../../assets/icons/logos/logo-facebook.svg';
import { ReactComponent as LogoYoutubeIcon } from '../../../assets/icons/logos/logo-youtube.svg';
import { ReactComponent as LogoSnapchatIcon } from '../../../assets/icons/logos/logo-snapchat.svg';
import { ReactComponent as LogoThreadsIcon } from '../../../assets/icons/logos/logo-threads.svg';
import { ReactComponent as LogoPinterestIcon } from '../../../assets/icons/logos/logo-pinterest.svg';
import { ReactComponent as LogoRedditIcon } from '../../../assets/icons/logos/logo-reddit.svg';
import { ReactComponent as LogoGitHubIcon } from '../../../assets/icons/logos/logo-github.svg';
import { ReactComponent as LogoCustomLinkIcon } from '../../../assets/icons/logos/logo-customlink.svg';

export const getSocialIcon = (socialLink: CardProfileSocialLink, iconStyle: object) => {
    const type = socialLink.type?.trim().toLowerCase();
    let icon, backgroundColor, backgroundImage;

    switch (type) {
        case X.toLowerCase():
            icon = <LogoXIcon style={iconStyle} />;
            backgroundColor = '#000000';
            break;
        case INSTAGRAM.toLowerCase():
            icon = <LogoInstagramIcon style={iconStyle} />;
            backgroundImage = 'linear-gradient(112deg, #f9ce34, #ee2a7b, #6228d7)';
            break;
        case COMPANY_LINKEDIN.toLowerCase():
        case PERSONAL_LINKEDIN.toLowerCase():
            icon = <LogoLinkedinIcon style={iconStyle} />;
            backgroundColor = '#0077B5';
            break;
        case TIKTOK.toLowerCase():
            icon = <LogoTikTokIcon style={iconStyle} />;
            backgroundImage = 'linear-gradient(45deg, #00f2ea, #000000 , #ff0050)';
            break;
        case FACEBOOK.toLowerCase():
            icon = <LogoFacebookIcon style={iconStyle} />;
            backgroundColor = '#0866ff';
            break;
        case YOUTUBE.toLowerCase():
            icon = <LogoYoutubeIcon style={iconStyle} />;
            backgroundColor = '#FF0000';
            break;
        case SNAPCHAT.toLowerCase():
            icon = <LogoSnapchatIcon style={iconStyle} />;
            backgroundColor = '#FFFC00';
            break;
        case PINTEREST.toLowerCase():
            icon = <LogoPinterestIcon style={iconStyle} />;
            backgroundColor = '#dd0019';
            break;
        case THREADS.toLowerCase():
            icon = <LogoThreadsIcon style={iconStyle} />;
            backgroundColor = '#000000';
            break;
        case REDDIT.toLowerCase():
            icon = <LogoRedditIcon style={iconStyle} />;
            backgroundColor = '#ff4500';
            break;
        case GITHUB.toLowerCase():
            icon = <LogoGitHubIcon style={iconStyle} />;
            break;
        case CUSTOM_LINK.toLowerCase():
            icon = <LogoCustomLinkIcon style={iconStyle} />;
            break;
        default:
            break;
    }

    return { icon, backgroundColor, backgroundImage };
};