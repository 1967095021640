import React, { FC, useMemo, useState } from 'react';
import { Selector } from '../../common/selector/Selector';
import i18n from '../../../translations/i18n';
import styles from './selector.module.scss';
import { getDefaultLanguageValue, getSupportedLanguageFromItem, getSupportedLanguageFromValue, getSupportedLanguageItems, getSupportedLanguageValues } from '../../settings/yourAccount/yourAccountUtils';
import { LanguageType } from '../../../types/language';
import { isBlank } from '../../../utils/common';
import { useSessionContext } from '../../../context/SessionProvider';
import useAppHeaderController from '../../layout/AppHeader/useAppHeaderController';
import { MenuItemStyle, selectStyle } from '../../layout/AppHeader/AppHeaderSelectorStyle';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow/arrow-down.svg';

interface LanguageSelectorProps {
    isSmallScreen: boolean;
    user: any
}

const LanguageSelector: FC<LanguageSelectorProps> = ({ isSmallScreen, user }) => {
    const [newLang, setNewLang] = useState<LanguageType | undefined>(undefined);
    const { toggleLanguage } = useSessionContext();
    const { saveUserLanguageApi } = useAppHeaderController();

    const userLang = getSupportedLanguageFromValue(user?.language);

    const languageValues = getSupportedLanguageValues().map(lang => ({
        value: lang, name: lang
    }));

    const languageItems = useMemo(() => getSupportedLanguageItems().map(li => ({value: li, name: li})), []);
    
    const updateNewLanguage = (item: string) => {
        const lang = getSupportedLanguageFromValue(item) as LanguageType | undefined;
        if (!lang || isBlank(lang.value)) return;
        setNewLang(lang);
        toggleLanguage(lang.value);
        saveUserLanguageApi(lang.value);
    };

    const updateNewMobileLanguage = (item: string) => {            
        const lang = getSupportedLanguageFromItem(item);
        if (!lang || isBlank(lang.value)) return;
        setNewLang(lang);
        toggleLanguage(lang.value);
        saveUserLanguageApi(lang.value);
    };

    return (
        <div>
            {isSmallScreen ?
                <div  className={styles.mobileSelectorWrapper}>
                    <Selector
                        items={languageItems}
                        defaultValue={newLang ? newLang.item: getDefaultLanguageValue(user?.language)}
                        onSelect={updateNewMobileLanguage}
                        formStyle={styles.mobileSelector}
                        label={i18n.t('labels.language')}
                    />
                </div> :
                <div className={styles.selectorWrapper}>
                    <Selector
                        items={languageValues}
                        defaultValue={newLang ? newLang.value.toUpperCase(): userLang.value.toUpperCase()}
                        onSelect={updateNewLanguage}
                        formStyle={styles.webSelector}
                        icon={ArrowDownIcon}
                        MenuProps={MenuItemStyle}
                        selectStyle={selectStyle}
                        notConvert
                    />
                </div>
            }
        </div>
    );
};

export default LanguageSelector;