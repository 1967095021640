import React, {FC, useEffect, useMemo, useState} from 'react';
import {Pagination} from '@mui/material';
import SettingsBox from '../SettingsBox';
import i18n from '../../../translations/i18n';
import Member from './Member';
import {useCompanyMemberController} from './useCompanyMemberController';
import { ReactComponent as InviteUserIcon } from '../../../assets/icons/user-plus.svg';
import styles from './company-members.module.scss';
import InvitationModal from '../../common/invitationModal/InvitationModal';
import {IS_COMPANY_OWNER} from '../../../store/utils/userSession';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {CompanyMember} from '../../../types/company';
import {useDebouncedCallback} from 'use-debounce';
import {API_CALL_DEBOUNCE_TIME_IN_MS} from '../../../constants/common';
import {SearchInput} from '../../common/searchInput/SearchInput';
import LoadingIcards from '../../../pages/loading/LoadingIcards';

const CompanyMembers: FC = () => {
    const user = useAppSelector(selectUser);

    const [isLargeSizeScreen, setIsLargeSizeScreen] = useState<boolean>(false);
    const [openInvitationModal, setOpenInvitationModal] = useState<boolean>(false);
    const {
        loading, apiFilter, setApiFilter, companyMembersPagination, companyId, getCompanyMembersApi
    } = useCompanyMemberController();

    const isCompanyOwner = useMemo(() => IS_COMPANY_OWNER(), [user.activeCompany]);
    const companyMembers: CompanyMember[] = companyMembersPagination?.companyMembers ?? [];
    const totalPages = companyMembersPagination?.totalPages ?? 1;
    //const totalElements = companyMembersPagination?.totalElements;
    const companyHasMembers = (companyMembersPagination && companyMembersPagination.noofElements > 0) ?? false;

    const handleToggleInvitationModal = () => setOpenInvitationModal(prevState => !prevState);

    const checkScreenSize = () => {
        setIsLargeSizeScreen(window.innerWidth > 992);
    };

    const onSendInvitationComplete = () => {
        getCompanyMembersApi();
        setOpenInvitationModal(false);
    };

    const onSearch = (text: string) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: 0,
            searchBy: text
        }));
    };

    const onSearchDebounced = useDebouncedCallback((text: string) => {
        return onSearch(text);
    }, API_CALL_DEBOUNCE_TIME_IN_MS);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: page - 1,
        }));
    };

    useEffect(() => {
        window.addEventListener('resize', checkScreenSize);
        checkScreenSize();
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    return (
        <SettingsBox
            title={i18n.t('common.companyMembers')}
            actionElement={
                isCompanyOwner
                    ? <div className={styles.inviteUserContainer} onClick={handleToggleInvitationModal}>
                        <InviteUserIcon />
                        <div className={styles.text}>
                            {i18n.t('common.inviteNewMember')}
                        </div>
                    </div>
                    : undefined
            }
        >
            <div className={styles.settings}>
                <SearchInput
                    placeholder={`${i18n.t('labels.searchNameOrEmail')}...`}
                    onSearch={onSearchDebounced}
                    className={styles.searchInput}
                />
                {isLargeSizeScreen &&
                    <div className={styles.tableHeader}>
                        <div className={styles.member}>{i18n.t('common.member')}</div>
                        <div className={styles.role}>{i18n.t('common.role')}</div>
                        <div className={styles.title}>{i18n.t('common.title')}</div>
                    </div>
                }
                {loading
                    ? <LoadingIcards fill={'#fff'} className={styles.loading} />
                    : companyHasMembers
                        ? <>
                            {companyMembers.map((companyMember, index) => (
                                <Member
                                    key={index}
                                    companyMember={companyMember}
                                    isLargeSizeScreen={isLargeSizeScreen}
                                    companyId={companyId}
                                    getCompanyMembersApi={getCompanyMembersApi}
                                    searchBy={apiFilter.searchBy ?? ''}
                                />
                            ))}
                            {totalPages > 1 &&
                                <Pagination
                                    page={apiFilter.page + 1}
                                    count={totalPages}
                                    shape="rounded"
                                    onChange={handlePageChange}
                                    style={{alignSelf: 'center'}}
                                />
                            }
                        </>
                        :
                        <div style={{alignSelf: 'center', marginTop: '16px'}}>
                            {i18n.t('messages.noCompanyMemberFound')}
                        </div>
                }
            </div>
            <InvitationModal
                isOpen={openInvitationModal}
                onClose={() => setOpenInvitationModal(false)}
                onSaveComplete={onSendInvitationComplete}
            />
        </SettingsBox>
    );
};

export default CompanyMembers;