import React, {FC, useEffect, useMemo, useState} from 'react';
import i18n from '../../../translations/i18n';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';
import {ReactComponent as MoveIcon} from '../../../assets/icons/assign-user.svg';
import MoveToModal from '../../../pages/cards/moveToModal/MoveToModal';
import {navigateTo} from '../../../constants/routes';
import {encode} from '../../../utils/jwt';
import {useCardListContext} from '../../../context/CardListProvider';
import {useNavigate} from 'react-router-dom';
import {CardProfileApiFilterStatus} from '../../../types/userCardProfile';
import {Selector, SelectorItem} from '../../common/selector/Selector';
import styles from './bulk-actions-selector.module.scss';
import {getViewModeFromLocalStorage} from '../../../storage/localStorage';
import {GRID} from '../../../constants/common';
import {IS_BUSINESS_ACCOUNT, IS_COMPANY_ADMIN} from '../../../store/utils/userSession';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';

interface BulkActionsSelectorProps {
    searchStatus?: CardProfileApiFilterStatus;
    searchText: string;
    isMyCards: boolean;
}


const BulkActionsSelector: FC<BulkActionsSelectorProps> = ({
    searchStatus, searchText, isMyCards
}) => {
    const navigate = useNavigate();
    const user = useAppSelector(selectUser);

    const [openMoveToModal, setOpenMoveToModal] = useState<boolean>(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const {
        toggleSelectAll, selected, setSelected, selectAll, noofCardsSelected, reloadCardsApi
    } = useCardListContext();

    const viewMode = getViewModeFromLocalStorage();
    const isGridMode = viewMode === GRID;

    const isAnyElementSelected = selected && selected.length > 0;
    const selectedLenString = isAnyElementSelected ? `(${noofCardsSelected})` : '';
    const isCompanyAdmin = IS_COMPANY_ADMIN();
    const isBusiness = IS_BUSINESS_ACCOUNT();

    const bulkActionsItems: SelectorItem[] = useMemo(() => {
        const actions = [
            {
                value: 'default',
                name: i18n.t('common.bulkActions')
            },
            {
                value: 'edit',
                name: i18n.t('common.editCards'),
                icon: <EditIcon />,
            }
        ];

        if (isBusiness && (isCompanyAdmin || isMyCards)) {
            actions.push({
                value: 'move',
                name: `${i18n.t('common.moveCardsTo')}...`,
                icon: <MoveIcon />,
            });
        }

        return actions;
    }, [i18n.locale, isCompanyAdmin, isBusiness]);

    const handleOnBulkEdit = () => {
        if (selected.length === 1) {
            navigate(`${navigateTo.profile}/${selected[0]}`);
        } else if (selected.length > 1) {
            const selectAllProfiles = selectAll.selected;
            const token = encode({ ids: selectAllProfiles ? selectAll.unselected : selected });
            navigate(
                `${navigateTo.bulkEditProfiles}?selectAll=${selectAllProfiles}&len=${noofCardsSelected}&isMyCards=${isMyCards ?? false}&search=${searchText}&status=${searchStatus}&token=${token}`
            );
        }
    };

    const handleSelect = (value: string) => {
        if (value === 'move') {
            setOpenMoveToModal(true);
        } else if (value === 'edit') {
            handleOnBulkEdit();
        }
    };

    const handleOnClickBulkActions = () => {
        if (isAnyElementSelected) {
            setShowWarningModal(false);
        } else {
            setShowWarningModal(prevState => !prevState);
        }
    };

    useEffect(() => {
        if (showWarningModal) {
            setShowWarningModal(!isAnyElementSelected);
        }
    }, [isAnyElementSelected]);

    useEffect(() => {
        if (!user) return;
        !isMyCards && selectAll?.selected && toggleSelectAll();
    }, [user, user.activeCompany]);

    return (
        <div className={styles.container}>
            {isGridMode && noofCardsSelected > 0 &&
                <div
                    className={styles.selectAll}
                    onClick={toggleSelectAll}
                >
                    {i18n.t('common.selectAll')}
                </div>
            }
            <Selector
                defaultValue={`${bulkActionsItems[0].name} ${selectedLenString}`}
                selectDefaultValue={true}
                items={bulkActionsItems}
                onClick={handleOnClickBulkActions}
                onSelect={handleSelect}
                formStyle={styles.formSelector}
                selectedItemStyle={{display: 'flex', gap: '8px'}}
                disabled={!isAnyElementSelected}
            />
            {openMoveToModal &&
                <MoveToModal
                    onClose={() => setOpenMoveToModal(false)}
                    reloadCardsApi={reloadCardsApi}
                    selectedProfiles={selected}
                    countSelected={noofCardsSelected}
                    isMyCards={isMyCards ?? false}
                    selectAll={selectAll}
                    searchText={searchText}
                    searchStatus={searchStatus}
                    onSaveAction={selectAll.selected ? () => toggleSelectAll() : () => setSelected([])}
                />
            }
            {showWarningModal &&
                <div className={styles.warningDialog}>
                    {i18n.t('messages.selectAtLeastOneItem')}
                </div>
            }
        </div>
    );
};

export default BulkActionsSelector;