import React, {FC} from 'react';
import {TopCardsAnalyticsData} from '../../types/analytics';
import style from './top-cards-analytics.module.scss';
import styles from '../contacts/contacts.module.scss';
import { ReactComponent as AvatarIcon } from '../../assets/icons/avatar.svg';
import i18n from '../../translations/i18n';
import {imageBaseUrl} from '../../api/base';

interface TopCardsAnalyticsProps {
    arePersonalCards: boolean,
    topCards: TopCardsAnalyticsData[]
}

const TopCardsAnalytics: FC<TopCardsAnalyticsProps> = ({
    arePersonalCards, topCards
}) => {

    const getProfileImage = (card : TopCardsAnalyticsData) => {
        const profilePhotoPath = card.imagePath;
        const path = (imageBaseUrl && profilePhotoPath) ? (imageBaseUrl + profilePhotoPath) : undefined;

        return path
            ? <img src={path} alt='profile-photo' className={style.avatar}/>
            : <AvatarIcon className={style.avatar}/>;
    };

    return (
        <div className={style.container}>
            <div className={style.header}>
                <div className={`${style.title} ${style.firstColumn}`}>
                    {arePersonalCards ? i18n.t('common.topCards') : i18n.t('common.groupPerformance')}
                </div>
                <div className={`${style.otherColumn} ${style.headerColumnRightSection}`}>
                    <HeaderItems />
                </div>
            </div>
            {topCards.map(card => (
                <div className={style.topCard} key={card.cardProfileId}>
                    <div className={`${style.cardUser} ${style.firstColumn}`}>
                        <div className={style.avatar}>
                            {getProfileImage(card)}
                        </div>
                        <div className={styles.textEllipsis}>
                            <div className={`${styles.gridEmail} ${styles.textEllipsis}`}>
                                {card.fullname}
                            </div>
                            <div className={styles.gridId}>
                                #{card.cardProfileId}
                            </div>
                        </div>
                    </div>
                    <div className={`${style.otherColumn} ${style.columnRightSection}`}>
                        <HeaderItems />
                    </div>
                    <div className={style.otherColumn}>
                        <div className={`${style.text} ${style.column}`}>
                            {card.countViews}
                        </div>
                        <div className={`${style.text} ${style.column}`}>
                            {card.countSaves}
                        </div>
                        <div className={`${style.text} ${style.column}`}>
                            {card.countUniqueViews}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TopCardsAnalytics;

const HeaderItems = () => {
    return (
        <>
            <div className={`${style.headerText} ${style.otherColumn}`}>
                {i18n.t('common.cardViews')}
            </div>
            <div className={`${style.headerText} ${style.otherColumn}`}>
                {i18n.t('common.contactSaved')}
            </div>
            <div className={`${style.headerText} ${style.otherColumn}`}>
                {i18n.t('common.uniqueUsers')}
            </div>
        </>
    );
};