import React, {FC} from 'react';
import styles from '../integrations.module.scss';
import i18n from '../../../../translations/i18n';
import CustomButton from '../../../common/button/CustomButton';
import HeaderButton from '../../../common/button/HeaderButton';
import {downloadFile} from '../../../../utils/download';
import {getHubSpotFilename, getHubSpotIntegrationUrl} from '../../../../api/base';
import {useAppSelector} from '../../../../store/appDispatch';
import {selectUserLanguage} from '../../../../store/slices/userSessionSlice';
import {ReactComponent as HubSpotIcon} from '../../../../assets/icons/integration/hubspot.svg';
import {ReactComponent as FileTextIcon} from '../../../../assets/icons/file-text.svg';
import {IntegrationForm} from '../../../../types/integration';
import {HUBSPOT} from '../../../../constants/integration';


interface HubSpotIntegrationProps {
    isHubSpotEnabled: boolean;
    setOpenConnectForm: (type: IntegrationForm) => void;
}

const HubSpotIntegration: FC<HubSpotIntegrationProps> = ({
    isHubSpotEnabled, setOpenConnectForm
}) => {
    const language = useAppSelector(selectUserLanguage);

    const handleDownloadInstructions = () => downloadFile(getHubSpotIntegrationUrl(language), getHubSpotFilename(language));

    return (
        <div className={styles.box}>
            <div className={styles.salesforceBox}>
                <div className={styles.salesforceIcon}>
                    <HubSpotIcon />
                </div>
                <div className={styles.textWrapper}>
                    <div className={`${styles.title} ${styles.text}`}>
                        {i18n.t('hubspot.hubspot')}
                    </div>
                    <div className={`${styles.desc} ${styles.text}`}>
                        {i18n.t('hubspot.description')}
                    </div>
                    {isHubSpotEnabled
                        ? <div className={styles.connected}>
                            {i18n.t('common.connected')}
                        </div>
                        : <div className={styles.downloadWrapper}>
                            <FileTextIcon/>
                            <div
                                className={`${styles.downloadText} ${styles.text}`}
                                onClick={handleDownloadInstructions}
                            >
                                {i18n.t('common.downloadInstructions')}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.connectBtn}>
                {isHubSpotEnabled
                    ? <CustomButton
                        onClick={() => setOpenConnectForm(HUBSPOT)}
                        text={i18n.t('common.view')}
                        sx={styles.btnView}
                    />
                    : <HeaderButton
                        title={i18n.t('common.connect')}
                        onClick={() => setOpenConnectForm(HUBSPOT)}
                        sx={styles.btn}
                    />
                }
            </div>
        </div>
    );
};

export default HubSpotIntegration;