import React, { FC, useState } from 'react';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import HomeHeader from '../../components/layout/HomeHeader/HomeHeader';
import { SearchInput } from '../../components/common/searchInput/SearchInput';
import { ReactComponent as HelpDeskIcon } from '../../assets/icons/helpDesk.svg';
import styles from './help-desk.module.scss';
import i18n from '../../translations/i18n';
import CategoryCard from '../../components/helpDesk/CategoryCard';
import useHelpDeskController from './useHelpDeskController';
import LoadingIcards from '../loading/LoadingIcards';

const HelpDesk: FC = () => {
    const isSmallScreen = useIsSmallScreen();
    const { categories, loading } = useHelpDeskController();
    const [searchStr, setSearchStr] = useState('');

    const filteredCategories = categories.filter(category =>
        category.title.toLowerCase().includes(searchStr.toLowerCase())
    );

    const handleSearch = (value: string) => {
        setSearchStr(value.trim());
    };

    return (
        <>
            {!isSmallScreen && <HomeHeader />}
            <div className={styles.wrapper}>
                <div className={styles.top}>
                    <div><HelpDeskIcon /></div>
                    <div className={styles.title}>{i18n.t('common.howCanWeHelp')}</div>
                    <SearchInput
                        placeholder={`${i18n.t('common.search')}...`}
                        onSearch={handleSearch}
                        className={styles.searchInput}
                    />
                </div>
                <div className={styles.categories}>
                    {loading ? (
                        <LoadingIcards className={styles.loading} />
                    ) : (
                        <>
                            {filteredCategories.length > 0 ? (
                                filteredCategories.map((category) => (
                                    <CategoryCard 
                                        key={category.id} 
                                        icon={category.icon.url} 
                                        title={category.title} 
                                        articleCount={category.articles.length} 
                                        slug={category.slug}
                                        searchText={searchStr}
                                    />
                                ))
                            ) : (
                                <div className={styles.noResults}>
                                    {i18n.t('messages.noCategoryFound')}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default HelpDesk;