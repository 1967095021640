import {useState} from 'react';
import {useAppSelector} from '../../../../store/appDispatch';
import {selectUser} from '../../../../store/slices/userSessionSlice';
import {saveHubSpotCredentials} from '../../../../api/hubSpot';
import {AuthResponseType, HubSpotRequest} from '../../../../types/integration';

const useHubSpotFormController = (reloadIntegrationStatus: () => void) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [authResponseType, setAuthResponseType] = useState<AuthResponseType | undefined>(undefined);
    const user = useAppSelector(selectUser);

    const saveHubSpotCredentialsApi = (apiToken: string) => {
        setLoading(true);
        const data: HubSpotRequest = { apiToken: apiToken };

        saveHubSpotCredentials(user.id, data)
            .then((res)=> {
                if (res.status === 401) {
                    setAuthResponseType('authError');
                } else if (!res.error) {
                    setAuthResponseType('success');
                    reloadIntegrationStatus();
                } else {
                    setAuthResponseType('serverError');
                }
            })
            .finally(() => setLoading(false));
    };

    return {
        loading,
        authResponseType,
        setAuthResponseType,
        saveHubSpotCredentialsApi
    };
};

export default useHubSpotFormController;