import {FC, useState} from 'react';
import SettingsBox from '../SettingsBox';
import styles from './support-tab.module.scss';
import CustomButton from '../../common/button/CustomButton';
import i18n from '../../../translations/i18n';
import SettingsCard from '../SettingsCard';
import { ReactComponent as ContactIcon } from '../../../assets/icons/contact/contact.svg';
import { ReactComponent as RequestIcon } from '../../../assets/icons/contact/request.svg';
import { ReactComponent as BugIcon } from '../../../assets/icons/contact/bug.svg';
import ContactSupportModal from './ContactSupportModal';
import FeatureRequestModal from './FeatureRequestModal';
import ReportBugModal from './ReportBugModal';

const CONTACT = 'CONTACT';
const FEATURE = 'FEATURE';
const BUG = 'BUG';

type SUPPORT_MODAL = typeof CONTACT | typeof FEATURE | typeof BUG;

const SupportTab: FC = () => {
    const [modalActive, setModalActive] = useState<SUPPORT_MODAL | undefined>(undefined);
    
    return (
        <>
            <SettingsBox title={i18n.t('settings.titleDesc')}>
                <SettingsCard
                    label={i18n.t('common.support')}
                    text={i18n.t('settings.supportContactDesc')}
                    onClick={() => {return;}}
                    actionElement={
                        <CustomButton
                            onClick={() => setModalActive(CONTACT)}
                            text={i18n.t('common.contact')}
                            sx={`${styles.btnDark} ${styles.btn}`}
                        />
                    }
                    startIcon={<ContactIcon />}
                />
                <SettingsCard
                    label={i18n.t('settings.featureRequest')}
                    text={i18n.t('settings.supportFeatureDesc')}
                    onClick={() => {return;}}
                    actionElement={
                        <CustomButton
                            onClick={() => setModalActive(FEATURE)}
                            text={i18n.t('common.submit')}
                            sx={`${styles.btnDark} ${styles.btn}`}
                        />
                    }
                    startIcon={<RequestIcon />}
                />
                <SettingsCard
                    label={i18n.t('settings.reportBug')}
                    text={i18n.t('settings.reportBugDesc')}
                    onClick={() => {return;}}
                    actionElement={
                        <CustomButton
                            onClick={() => setModalActive(BUG)}
                            text={i18n.t('common.report')}
                            sx={`${styles.btnDark} ${styles.btn}`}
                        />
                    }
                    startIcon={<BugIcon />}
                />
            </SettingsBox>
            <ContactSupportModal
                open={modalActive === CONTACT}
                onClose={() => setModalActive(undefined)}
            />
            <FeatureRequestModal
                open={modalActive === FEATURE}
                onClose={() => setModalActive(undefined)}
            />
            <ReportBugModal
                open={modalActive === BUG}
                onClose={() => setModalActive(undefined)}
            />
        </>
    );
};

export default SupportTab;
