import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useHelpDeskController from './useHelpDeskController';
import styles from './help-desk.module.scss';
import { ReactComponent as ArticleIcon } from '../../assets/icons/article.svg';
import { strapiUrl } from '../../api/base';
import { navigateTo } from '../../constants/routes';
import i18n from '../../translations/i18n';
import LoadingIcards from '../loading/LoadingIcards';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import HomeHeader from '../../components/layout/HomeHeader/HomeHeader';

const HelpDeskCategory = () => {
    const isSmallScreen  = useIsSmallScreen();
    const { categorySlug } = useParams();
    const navigate = useNavigate();

    const { category, categories, loadingCategory } = useHelpDeskController(categorySlug);

    const navigateToCategory = (slug: string) => {
        navigate(`${navigateTo.helpDesk}/${slug}`);
    };

    const navigateToArticles = (categorySlug: string, articleSlug: string) => {
        navigate(`${navigateTo.helpDesk}/${categorySlug}/${articleSlug}`);
    };

    return (
        <>
            {!isSmallScreen && <HomeHeader/>}
            <div className={styles.container}>
                <div className={styles.categoryWrapper}>
                    <div className={styles.icon}> {category?.icon && <img src={`${strapiUrl}${category?.icon.url}`} />}</div>
                    {loadingCategory ? <LoadingIcards className={styles.loading} fill='#fff' /> : <>
                        <div className={styles.title}> {category?.title} </div>
                        <div> {category?.description} </div>
                        <div className={styles.articles}> {category?.articles.map((article) => (
                            <div className={styles.articleWrapper}>
                                <ArticleIcon />
                                <span onClick={() => navigateToArticles(category.slug, article.slug)} className={styles.article}>{article.title} </span>
                            </div>
                        ))} </div></>}
                </div>
                <div className={styles.categoriesTable}>
                    <div className={styles.tableTitle}>{i18n.t('common.categories').toUpperCase()} </div>
                    {categories.map((category) => (
                        <span className={styles.categoryTitle} onClick={() => navigateToCategory(category.slug)}>{category.title} </span>
                    ))}

                </div>
            </div>
        </>
    );
};

export default HelpDeskCategory;