import { useEffect } from 'react';
import {portalUrl} from '../api/base';

const RedirectToPortal = () => {
    useEffect(() => {
        portalUrl && window.location.replace(portalUrl);
    }, []);

    return <p>Redirecting to portal...</p>;
};

export default RedirectToPortal;