import React, {FC, useRef, useState} from 'react';
import {ExchangeContactData} from '../../../types/exchangeContacts';
import styles from './contacts.module.scss';
import i18n from '../../../translations/i18n';
import ThreeDotsMenu from '../../../components/common/menu/ThreeDotsMenu';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-square.svg';
import { ReactComponent as CallIcon } from '../../../assets/icons/call.svg';
import { ReactComponent as WebsiteIcon } from '../../../assets/icons/world.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow/arrow-down.svg';
import { ReactComponent as AvatarIcon } from '../../../assets/icons/avatar.svg';
import Divider from '../../../components/common/divider/Divider';
import {getExternalUrl, getMailUrl, getPhoneUrl, notEmpty} from '../../../utils/common';
import ContactMenu from '../menu/ContactMenu';
import {convertToExchangeContactFormData} from '../contactsColumnsUtils';
import {imageBaseUrl} from '../../../api/base';

interface ContactProps {
    contact: ExchangeContactData;
}

const Contact: FC<ContactProps> = ({ contact }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const menuIconRef = useRef<HTMLDivElement>(null);

    const goToPhone = (phone: string) => window.location.href = getPhoneUrl(phone);
    const goToEmail = (email: string) => window.open(getMailUrl(email), '_self');
    const goToWebsite = (website: string) => window.location.href = getExternalUrl(website);

    return (
        <div className={styles.contact}>
            <div className={styles.header}>
                <div
                    className={styles.firstColumn}
                    onClick={() => setOpen(prevState => !prevState)}
                >
                    <ArrowIcon width={13} height={7} className={`${open && styles.rotate}`} />
                </div>
                <div className={styles.secondColumn}>
                    <div className={styles.name}>
                        {contact.firstName} {contact.lastName}
                    </div>
                    {contact.company &&
                        <div className={styles.companyText}>
                            {contact.company}
                        </div>
                    }
                </div>
                <div className={styles.thirdColumn}>
                    <ThreeDotsMenu
                        menuIconRef={menuIconRef}
                        isOpen={openMenu}
                        toggleOpen={() => setOpenMenu(prevState =>  !prevState)}
                        style={{alignSelf: 'center', marginLeft: 'unset'}}
                    />
                    {openMenu &&
                        <ContactMenu
                            contactId={contact.id}
                            menuIconRef={menuIconRef}
                            onClose={() => setOpenMenu(false)}
                            exchangeContact={convertToExchangeContactFormData(contact)}
                            className={styles.contactMenuContainer}
                        />
                    }
                </div>
            </div>

            { open &&
                <div className={styles.dropdownContainer}>
                    <div style={{margin: '0px 14px 4px 48px'}}><Divider/></div>
                    <div className={styles.dropdown}>
                        <div className={styles.avatar}>
                            <div className={styles.avatarImage}>
                                {notEmpty(contact.profileImagePath)
                                    ? <img
                                        className={styles.avatarImage}
                                        src={`${imageBaseUrl + contact.profileImagePath}`}
                                        alt=""
                                    />
                                    : <AvatarIcon className={styles.avatarImage}/>
                                }
                            </div>
                        </div>
                        <div className={styles.items}>
                            {contact.phone &&
                            <div className={styles.row}>
                                <div className={`${styles.secondColumn} ${styles.textEllipsis}`}>
                                    <div className={styles.subtext}>
                                        {i18n.t('common.phone')}
                                    </div>
                                    <div className={`${styles.text} ${styles.ellipsis}`}>
                                        {contact.phone}
                                    </div>
                                </div>
                                <div className={styles.thirdColumn}>
                                    <CallIcon className={styles.icon} onClick={() => goToPhone(contact.phone)}/>
                                </div>
                            </div>
                            }

                            {contact.email &&
                            <div className={styles.row}>
                                <div className={`${styles.secondColumn} ${styles.textEllipsis}`}>
                                    <div className={styles.subtext}>
                                        {i18n.t('common.email')}
                                    </div>
                                    <div className={`${styles.text} ${styles.ellipsis}`}>
                                        {contact.email}
                                    </div>
                                </div>
                                <div className={styles.thirdColumn}>
                                    <EditIcon className={styles.icon} onClick={() => goToEmail(contact.email)}/>
                                </div>
                            </div>
                            }

                            {contact.website &&
                            <div className={styles.row}>
                                <div className={`${styles.secondColumn} ${styles.textEllipsis}`}>
                                    <div className={styles.subtext}>
                                        {i18n.t('common.website')}
                                    </div>
                                    <div className={`${styles.text} ${styles.ellipsis}`}>
                                        {contact.website}
                                    </div>
                                </div>
                                <div className={styles.thirdColumn}>
                                    <WebsiteIcon className={styles.icon} onClick={() => goToWebsite(contact.website)}/>
                                </div>
                            </div>
                            }

                            {contact.notes &&
                            <div className={styles.row}>
                                <div className={`${styles.secondColumn} ${styles.textNewLine}`}>
                                    <div className={styles.subtext}>
                                        {i18n.t('common.notes')}
                                    </div>
                                    <div className={`${styles.text} ${styles.ellipsis}`}>
                                        {contact.notes}
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Contact;