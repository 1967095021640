import React, {FC} from 'react';
import styles from './not-found-page.module.scss';
import CustomButton from '../../components/common/button/CustomButton';
import i18n from '../../translations/i18n';
import { ReactComponent as NotFoundImage } from '../../assets/icons/not-found.svg';
import { ReactComponent as ICardsLogo } from '../../assets/icons/logos/iCards-logo-dark.svg';
import {portalUrl} from '../../api/base';

const NotFoundPage: FC = () => {
    return (

        <div className={styles.container}>
            <div className={styles.leftHalf}>
                <div className={styles.leftHalfContainer}>
                    <div className={styles.notFoundTextContainer}>
                        <div className={styles.title}>
                            {i18n.t('notFoundPage.title')}
                        </div>
                        <div className={styles.desc}>
                            {i18n.t('notFoundPage.description')}
                        </div>
                    </div>
                    <CustomButton
                        text={i18n.t('notFoundPage.goBackToHomepage')}
                        onClick={() => portalUrl && (window.location.href = portalUrl)}
                        sx={styles.btn}
                    />
                    <div className={styles.logo}>
                        <ICardsLogo />
                    </div>
                </div>
            </div>
            <div className={styles.rightHalf}>
                <NotFoundImage className={styles.notFoundImage} />
            </div>
        </div>

    );
};

export default NotFoundPage;
