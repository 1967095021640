import { ReactComponent as PhoneIcon } from '../../assets/icons/contact/phone.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/icons/contact/whatsapp.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/contact/email.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow/arrow-right.svg';
import { ReactComponent as HelpDeskIcon } from '../../assets/icons/contact/help-desk.svg';
import {CONTACT_PHONE_NUMBER, ICARDS_SUPPORT_EMAIL} from '../../constants/common';

export const PHONE_CONTACT = 'phone';
export const WHATSAPP_CONTACT = 'whatsapp';
export const EMAIL_CONTACT = 'email';
export const HELP_DESK = 'help-desk';

export type ContactType =
    typeof PHONE_CONTACT |
    typeof WHATSAPP_CONTACT |
    typeof EMAIL_CONTACT|
    typeof HELP_DESK;

interface ContactItem  {
    id: number;
    type: ContactType,
    icon: any;
    title: string;
    desc: string;
    descTranslate: boolean;
    actionIcon?: any;
}

export const CONTACT_ITEMS: ContactItem[] = [
    {
        id: 1,
        type: PHONE_CONTACT,
        icon: PhoneIcon,
        title: 'common.phone',
        desc: CONTACT_PHONE_NUMBER,
        descTranslate: false
    },
    {
        id: 2,
        type: WHATSAPP_CONTACT,
        icon: WhatsappIcon,
        title: 'common.whatsapp',
        desc: 'contact.liveChat',
        descTranslate: true,
        actionIcon: ArrowRightIcon
    },
    {
        id: 3,
        type: EMAIL_CONTACT,
        icon: EmailIcon,
        title: 'common.emailDash',
        desc: ICARDS_SUPPORT_EMAIL,
        descTranslate: false
    },
    {
        id: 4,
        type: HELP_DESK,
        icon: HelpDeskIcon,
        title: 'common.helpDesk',
        desc: 'contact.helpDeskDescription',
        descTranslate: true
    }
];