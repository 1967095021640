import React, {FC, useRef, useState} from 'react';
import styles from './move-to-modal.module.scss';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as RedoIcon } from '../../../assets/icons/redo.svg';
import i18n from '../../../translations/i18n';
import ModalContainer from '../../../components/common/modalContainer/ModalContainer';
import Divider from '../../../components/common/divider/Divider';
import CustomButton from '../../../components/common/button/CustomButton';
import {SearchInput} from '../../../components/common/searchInput/SearchInput';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {useOutsideClick} from '../../../hooks/useOutsideCLick';
import { TextHighlighter } from '../../../components/common/textHighlighter/TextHighlighter';
import {getCompanyItemsWhereUserIsAdmin} from './moveToModalUtils';
import {SelectAllObject} from '../CardsListView';
import {CardProfileApiFilterStatus} from '../../../types/userCardProfile';
import {COMPANY_ID} from '../../../store/utils/userSession';
import {
    assignCardToCompany, assignCardToCompanySelectAll,
    assignPersonalCardToCompanySelectAll
} from '../../../api/companyAssign';
import CloseButton from '../../../components/common/button/CloseButton';


interface MoveToModalProps {
    onClose: () => void;
    reloadCardsApi?: () => void;
    selectedProfiles: number[];
    countSelected: number;
    isMyCards: boolean;
    selectAll?: SelectAllObject;
    searchText?: string;
    searchStatus?: CardProfileApiFilterStatus;
    onSaveAction?: () => void;
}

interface Item {
    value: number;
    name: string;
}

const MoveToModal: FC<MoveToModalProps> = ({
    onClose, reloadCardsApi, selectedProfiles, countSelected,
    isMyCards, selectAll, searchText, searchStatus, onSaveAction
}) => {
    const user = useAppSelector(selectUser);
    const [selectedCompany, setSelectedCompany] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchStr, setSearchStr] = useState<string>('');

    const currentCompanyId = COMPANY_ID() as number;

    const companyItems: Item[] =  isMyCards
        ? getCompanyItemsWhereUserIsAdmin(user.companies)
        : getCompanyItemsWhereUserIsAdmin(user.companies).filter(c => c.value !== currentCompanyId);

    const [items, setItems] = useState<(Item[])>(companyItems);

    const modalContentRef = useRef<HTMLDivElement>(null);
    useOutsideClick([modalContentRef], () => { onClose(); });

    const toggleCompany = (companyId: number) => {
        setSelectedCompany(companyId);
    };

    const moveToCompanyCards = () => {
        if (!selectedCompany) return;

        setLoading(true);
        (selectAll?.selected
            ? (isMyCards
                ? assignPersonalCardToCompanySelectAll(selectedCompany, selectAll.unselected, searchText, searchStatus)
                : assignCardToCompanySelectAll(currentCompanyId, selectedCompany, selectAll.unselected, searchText, searchStatus)
            )
            : assignCardToCompany(selectedCompany, selectedProfiles)
        )
            .then((res) => {
                if (res.error) return;

                reloadCardsApi && reloadCardsApi();
                onClose();
                onSaveAction && onSaveAction();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSearch = (value: string) => {
        setItems(companyItems.filter(c => c.name.toLowerCase().includes(value.toLowerCase())));
        setSearchStr(value);
    };

    const isSelected = (companyId: number) => selectedCompany === companyId;

    return (
        <ModalContainer className={styles.modalContainer}>
            <div ref={modalContentRef} className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.titleText}>
                        {i18n.t('common.moveCardsTo')}
                    </div>
                    <CloseButton onClose={onClose}/>
                </div>
                <SearchInput
                    placeholder={`${i18n.t('common.search')}`}
                    onSearch={handleSearch}
                    className={styles.input}
                />
                <div className={styles.companies}>
                    {items.map((company: Item) => (
                        <div
                            key={company.value}
                            className={`${styles.company} ${isSelected(company.value) && styles.companySelected}`}
                            onClick={() => toggleCompany(company.value)}
                        >
                            {isSelected(company.value) && <RedoIcon/>}
                            <TextHighlighter
                                text={company.name}
                                textToHighlight={searchStr}
                            />
                        </div>
                    ))}
                </div>
                <Divider/>
                <div className={styles.footer}>
                    <div className={styles.footerText}>
                        <CheckIcon/>
                        {countSelected} &nbsp;
                        {countSelected === 1 ? i18n.t('common.cardSelectedCapital') : i18n.t('common.cardsSelectedCapital')}
                    </div>
                    <div className={styles.btnWrapper}>
                        {!loading &&
                            <CustomButton
                                text={i18n.t('common.cancel')}
                                onClick={onClose}
                                sx={`${styles.btnDiscard}`}
                                disabled={loading}
                            />
                        }
                        <CustomButton
                            text={i18n.t('common.move')}
                            onClick={moveToCompanyCards}
                            sx={styles.btnDark}
                            loading={loading}
                            disabled={loading || !selectedCompany}
                        />
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};

export default MoveToModal;