import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

export type MarkdownRendererProps = {
    richText: string;
    className?: string;
};

export const MarkdownRenderer: FC<MarkdownRendererProps> = ({
    richText, className
}) => {

    return (
        <ReactMarkdown
            className={className}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
                h1: ({...props }) => <h1 className="text-3xl font-bold my-4" {...props} />,
                h2: ({...props }) => <h2 className="text-2xl font-semibold my-3 text-gray-900" {...props} />,
                h3: ({...props }) => <h3 className="text-xl font-medium my-2" {...props} />,
                ul: ({...props }) => <ul className="text-gray-900 list-disc ml-4 space-y-2" {...props} />,
                ol: ({...props }) => <ol className="list-decimal list-inside my-2" {...props} />,
                p: ({...props }) => <p className="my-4 text-gray-800" {...props} />,
                a: ({...props }) => <a className="text-blue-500 underline" {...props} />,
            }}
        >
            {richText}
        </ReactMarkdown>
    );
};

export default MarkdownRenderer;
