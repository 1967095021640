
export const COUNTRY = 'Country';
export const WEBSITE = 'Website';
export const OTHER_WEBSITE = 'Other website';
export const WORK_PHONE = 'Work phone';
export const MOBILE_PHONE = 'Mobile phone';
export const OFFICE_PHONE = 'Office phone';
export const HOME_PHONE = 'Home phone';
export const WHATSAPP = 'WhatsApp';
export const EMAIL = 'Email';
export const WORK_EMAIL = 'Work email';
export const HOME_EMAIL = 'Home email';
export const APPOINTMENT_BOOKING = 'Appointment booking';

export type Contacts =
    typeof WEBSITE |
    typeof WORK_PHONE |
    typeof MOBILE_PHONE |
    typeof OFFICE_PHONE |
    typeof HOME_PHONE |
    typeof WHATSAPP |
    typeof EMAIL |
    typeof WORK_EMAIL |
    typeof HOME_EMAIL |
    typeof COUNTRY |
    typeof APPOINTMENT_BOOKING;