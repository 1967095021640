import React, {FC, RefObject, useMemo, useRef, useState} from 'react';
import i18n from '../../../translations/i18n';
import styles from './company-member-menu.module.scss';
import { ReactComponent as RemoveUserIcon } from '../../../assets/icons/remove-user.svg';
import { ReactComponent as ResendInvitationIcon } from '../../../assets/icons/resend-invitation.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning/warning.svg';
import {COMPANY_ID} from '../../../store/utils/userSession';
import {useOutsideClick} from '../../../hooks/useOutsideCLick';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {removeCompanyInvitation, removeCompanyMember, resendCompanyInvitation} from '../../../api/company';
import {WarningModal} from '../../common/warningModal/WarningModal';
import {CompanyUser} from '../../../types/user';
import {useAlert} from '../../../context/AlertProvider';
import {ERROR, SUCCESS} from '../../../constants/common';

interface CompanyMemberMenuProps {
    companyUser: CompanyUser;
    onClose: () => void;
    menuIconRef: RefObject<HTMLDivElement>;
    getCompanyMembersApi: () => void;
}

const CompanyMemberMenu: FC<CompanyMemberMenuProps> = ({
    companyUser, onClose, menuIconRef, getCompanyMembersApi
}) => {
    const {setAlert} = useAlert();
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const user = useAppSelector(selectUser);
    const menuRef = useRef<HTMLDivElement>(null);

    const companyInvitationNotAccepted = companyUser.id == null;

    useOutsideClick([menuRef, menuIconRef], () => {
        onClose();
    });

    const companyId = useMemo(() => COMPANY_ID(), [user.activeCompany]);

    const getCompanyModalTitle = () => (
        companyInvitationNotAccepted
            ? i18n.t('modal.removeCompanyInvitationTitle')
            : i18n.t('modal.removeCompanyMemberTitle')
    );

    const getCompanyModalDesc = () => (
        companyInvitationNotAccepted
            ? i18n.t('modal.removeCompanyInvitationDesc' ,{userEmail: companyUser.email})
            : i18n.t('modal.removeCompanyMemberDesc' ,{name: companyUser.fullname})
    );

    const handleRemoveMember = () => {
        if (!companyId) return;

        (companyInvitationNotAccepted
            ? removeCompanyInvitation(companyId, companyUser.email)
                .then((res) => {
                    if (res.error) {
                        setAlert(ERROR, i18n.t('messages.internalServerError'));
                    } else {
                        setAlert(SUCCESS, i18n.t('messages.companyInvitationRemoved'));
                    }
                })
            : removeCompanyMember(companyId, companyUser.id)
                .then((res) => {
                    if (res.status == 409) {
                        setAlert(ERROR, i18n.t('messages.unableToRemoveCompanyMember'));
                    } else if (res.error) {
                        setAlert(ERROR, i18n.t('messages.internalServerError'));
                    } else {
                        setAlert(SUCCESS, i18n.t('messages.companyMemberRemoved'));
                    }
                })
        )
            .finally(() => {
                getCompanyMembersApi();
                setOpenRemoveModal(false);
                onClose();
            });
    };

    const handleResendCompanyInvitation = () => {
        if (!companyId) return;

        resendCompanyInvitation(companyId, companyUser.email)
            .then((res) => {
                if (res.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                } else {
                    setAlert(SUCCESS, i18n.t('messages.companyInvitationSent'));
                }

                getCompanyMembersApi();
            });
    };

    return (
        <div ref={menuRef} className={styles.container}>
            <div className={`${styles.item} ${styles.pointer}`} onClick={() => setOpenRemoveModal(true)}>
                <div className={styles.iconWrapper}>
                    <RemoveUserIcon />
                </div>
                <div className={styles.text}>
                    {companyInvitationNotAccepted
                        ? i18n.t('common.cancelInvitation')
                        : i18n.t('common.removeMember')
                    }
                </div>
            </div>
            {companyInvitationNotAccepted &&
                <div className={`${styles.item} ${styles.pointer}`} onClick={handleResendCompanyInvitation}>
                    <div className={styles.iconWrapper}>
                        <ResendInvitationIcon />
                    </div>
                    <div className={styles.text}>
                        {i18n.t('common.resendInvitation')}
                    </div>
                </div>
            }
            <WarningModal
                isOpen={openRemoveModal}
                icon={<WarningIcon/>}
                title={getCompanyModalTitle()}
                text={getCompanyModalDesc()}
                confirmBtnText={i18n.t('common.proceed')}
                cancelBtnText={i18n.t('common.cancel')}
                onConfirm={handleRemoveMember}
                onCancel={() => setOpenRemoveModal(false)}
            />
        </div>
    );
};

export default CompanyMemberMenu;