import { createSlice } from '@reduxjs/toolkit';
import {KeycloakUser, User} from '../../types/user';
import {KeycloakConverter} from '../../converters/KeycloakConverter';
import {decode} from '../../utils/jwt';
import {Company} from '../../types/company';
import {BUSINESS} from '../../constants/accountRole';

interface UserSessionSlice {
    user: User | null;
    userPhoto: string;
    kkUser: KeycloakUser | null;
}

const initialState: UserSessionSlice = {
    user: null,
    userPhoto: '',
    kkUser: null
};

const userSessionSlice = createSlice({
    name: 'userSession',
    initialState,
    reducers: {
        storeKkUser: (state, action) => {
            const token = action.payload;

            if (token) {
                const decodedToken = decode(token);
                state.kkUser = KeycloakConverter.convertFromKkTokenToKeycloakUser(decodedToken);
            }
        },
        storeUser: (state, action) => {
            const user: User = action.payload;

            if (user) {
                state.user = user;
            }

        },
        storeUserPhoto: (state, action) => {
            if (action.payload) {
                state.userPhoto = action.payload;
            }
        },
        removeUserProfilePhoto: (state) => {
            state.userPhoto = '';
        },
        storeCompany: (state, action) => {
            const company: Company = action.payload;
            if (action.payload && state.user) {
                state.user.accountType = BUSINESS;
                state.user.activeCompany = company;
                state.user.companies?.push(company);
            }
        },
        setActiveCompany: (state, action) => {
            const company: Company = action.payload;
            if (action.payload && state.user) {
                state.user.activeCompany = company;
            }
        }
    },
});

export default userSessionSlice.reducer;

export const {
    storeKkUser, storeUser, storeUserPhoto, removeUserProfilePhoto,
    storeCompany, setActiveCompany
} = userSessionSlice.actions;

export const selectUser = (state: any) => state.userSession.user;
export const selectUserLanguage = (state: any) => state.userSession.user.language;
export const selectUserPhoto = (state: any) => state.userSession.userPhoto;
export const selectKkUser = (state: any) => state.userSession.kkUser;
