import {FC, useCallback, useEffect, useState} from 'react';
import styles from './app-footer.module.scss';
import i18n from '../../../translations/i18n';
import {NavLink} from 'react-router-dom';
import {routes} from '../../../constants/routes';
import {useAppSelector} from '../../../store/appDispatch';
import {selectAppVersion} from '../../../store/slices/appConfigSlice';

interface AppFooterProps {
    hasStickyFooter?: boolean
}

const privacyPolicyURL = process.env.REACT_APP_PRIVACY_POLICY_URL;

const AppFooter: FC<AppFooterProps> = ({
    hasStickyFooter
}) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const appVersion = useAppSelector(selectAppVersion);

    const classNames = hasStickyFooter ? `${styles.navContainer} ${styles.stickyFooter}` : styles.navContainer;

    const getChangelogLink = useCallback(() => (
        <NavLink to={routes.changelog} className={styles.link} rel="noopener noreferrer">
            {i18n.t('header.changelog')}
        </NavLink>
    ), []);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 576);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={classNames}>
            <div className={styles.version}>
                <div>v{appVersion}</div>
                <div>© iCards AG 2025</div>
            </div>
            {isSmallScreen &&
                getChangelogLink()
            }
            <div className={styles.privacyPolicy}>
                <span className={styles.firstDot}>•</span>
                <a href={privacyPolicyURL} className={styles.link} target="_blank" rel="noopener noreferrer">
                    {i18n.t('settings.termsAndConditions')}
                </a>
                <span>•</span>
                <a href={privacyPolicyURL} className={styles.link} target="_blank" rel="noopener noreferrer">
                    {i18n.t('settings.privacyPolicy')}
                </a>
                {!isSmallScreen &&
                    <>
                        <span>•</span>
                        {getChangelogLink()}
                    </>
                }
            </div>
        </div>
    );
};

export default AppFooter;