import React, {FC, ReactElement, useState} from 'react';
import styles from './social-links.module.scss';
import CustomDropdown from '../../common/dropdown/CustomDropdown';
import {extractDomainName, getExternalUrl} from '../../../utils/common';
import {CardProfileSocialLink} from '../../../types/cardProfile';

interface CustomLinkDropdownProps {
    customLinks: CardProfileSocialLink[];
    style: object;
    icon?: ReactElement;
    backgroundColor?: string;
    backgroundImage?: string;
    isLastTwo: boolean;
}

const CustomLinkDropdown: FC<CustomLinkDropdownProps> = ({
    customLinks, style, icon, backgroundColor, backgroundImage, isLastTwo
}) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const goToLink = (url: string) => {
        url && window.open(getExternalUrl(url), '_blank');
    };

    return (
        <div
            className={styles.dropdownWrapper}
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
        >
            <div className={styles.logoWrapper}
                style={{...style, backgroundColor, backgroundImage}}>
                {icon}
            </div>
            {showDropdown && (
                <CustomDropdown
                    className={`${styles.customDropdown} ${isLastTwo ? styles.dropdownAdjust : ''}`}
                    items={customLinks.map(link => ({
                        name: extractDomainName(link.details),
                        onAction: () => goToLink(link.details),
                    }))}
                />
            )}
        </div>
    );
};

export default CustomLinkDropdown;