import React, {FC} from 'react';
import styles from './header-button.module.scss';

interface HeaderButtonProps {
    title: string;
    removePlus?: boolean;
    onClick: () => void;
    sx?: string;
}

const HeaderButton: FC<HeaderButtonProps> = ({
    title, removePlus, onClick, sx
}) => {

    const containerStyle = sx ? `${styles.btn} ${styles.container} ${sx}` : `${styles.btn} ${styles.container}`;

    return (
        <div
            className={containerStyle}
            onClick={onClick}
        >
            {!removePlus &&
                <div className={styles.plus}>
                    +
                </div>
            }
            <div className={styles.btn}>
                {title}
            </div>
        </div>
    );
};

export default HeaderButton;