import React, {ChangeEvent, FC, ReactElement, useState} from 'react';
import styles from './file-input.module.scss';
import { fileValidation } from '../../../utils/validationUtils';


interface MobileCameraFileInputProps {
    id: string;
    label: string;
    className?: string; 
    labelClassName?: string;
    onFileChange?: (file: File) => void;
    icon?: ReactElement;
    disabled?: boolean;
}

const MobileCameraFileInput: FC<MobileCameraFileInputProps> = ({
    id, label, labelClassName, onFileChange, icon, disabled
}) => {
    const [error, setError] = useState<string | null>(null);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        const file = e.target.files?.[0];
        if (!file) return;

        const validationResult = fileValidation(file);
        if (!validationResult.isValid) {
            setError(validationResult.error || '');
            return;
        }
        setError(null);
        onFileChange && onFileChange(file);
    };

    return (
        <label className={`${labelClassName} ${styles.label}`} htmlFor={id}>
            {icon && icon}
            {label}
            <input
                id={id}
                type="file"
                accept="image/*"
                capture={'environment'} // Mobile: Open camera
                style={{display: 'none'}}
                onChange={handleFileChange}
            />
            {error &&
                <div className={styles.error}>
                    {error}
                </div>
            }
        </label>
    );
};

export default MobileCameraFileInput;