import { FC, useMemo } from 'react';
import styles from './home-header.module.scss';
import { NavLink } from 'react-router-dom';
import i18n from '../../../translations/i18n';
import { routes } from '../../../constants/routes';
import { IS_BUSINESS_ACCOUNT } from '../../../store/utils/userSession';
import { useAppSelector } from '../../../store/appDispatch';
import { selectUser } from '../../../store/slices/userSessionSlice';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';

interface HomeHeaderProps {
    onLinkClick?: () => void;
}

const HomeHeader: FC<HomeHeaderProps> = ({ onLinkClick }) => {
    const user = useAppSelector(selectUser);
    const isBusiness = useMemo(() => IS_BUSINESS_ACCOUNT(), [user.activeCompany]);
    const isSmallScreen = useIsSmallScreen();

    return (
        <nav className={isSmallScreen ? styles.mobileNavContainer : styles.navContainer}>
            <div className={isSmallScreen ? styles.mobileNavLinks : styles.navLinks}>
                <NavLink to={routes.analytics} onClick={onLinkClick} className={isSmallScreen ? styles.mobileLink : ({ isActive }) => isActive ? styles.activeLink : styles.link}>
                    {i18n.t('header.analytics')}
                </NavLink>
                <NavLink to={routes.myCards} onClick={onLinkClick} className={isSmallScreen ? styles.mobileLink : ({ isActive }) => isActive ? styles.activeLink : styles.link}>
                    {i18n.t('header.personalCards')}
                </NavLink>
                {isBusiness && (
                    <NavLink to={routes.companyCards} onClick={onLinkClick} className={isSmallScreen ? styles.mobileLink : ({ isActive }) => isActive ? styles.activeLink : styles.link}>
                        {i18n.t('header.companyCards')}
                    </NavLink>
                )}
                <NavLink to={routes.contacts} onClick={onLinkClick} className={isSmallScreen ? styles.mobileLink : ({ isActive }) => isActive ? styles.activeLink : styles.link}>
                    {i18n.t('common.contacts')}
                </NavLink>
                <NavLink to={routes.settings} onClick={onLinkClick} className={isSmallScreen ? styles.mobileLink : ({ isActive }) => isActive ? styles.activeLink : styles.link}>
                    {i18n.t('header.settings')}
                </NavLink>
            </div>
        </nav>
    );
};

export default HomeHeader;
