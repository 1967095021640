export const getChangedFields = <T extends Record<string, any>>(initialData: T, updatedData: T): Partial<T> => {
    const changedFields: Partial<T> = {};

    (Object.keys(updatedData) as (keyof T)[]).forEach((key) => {
        if (updatedData[key] !== initialData[key]) {
            changedFields[key] = updatedData[key];
        }
    });

    return changedFields;
};
