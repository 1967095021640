import {useCallback, useEffect, useMemo, useState} from 'react';
import { CardProfileConverter } from '../../converters/api/CardProfileConverter';
import { CardProfile } from '../../types/cardProfile';
import {assignCardProfile, getCardProfileById} from '../../api/cardProfile';
import store from '../../store/store';
import {ERROR, SUCCESS} from '../../constants/common';
import {useAlert} from '../../context/AlertProvider';
import i18n from '../../translations/i18n';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import {COMPANY_ID} from '../../store/utils/userSession';
import {imageBaseUrl} from '../../api/base';

const useCardProfileController = (id: number) => {
    const {setAlert} = useAlert();
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingAssign, setLoadingAssign] = useState<boolean>(false);
    const [cardProfileData, setCardProfileData] = useState<CardProfile | null>(null);
    const [profilePhoto, setProfilePhoto] = useState<string>('');
    const [coverPhoto, setCoverPhoto] = useState<string>('');

    const address = cardProfileData?.profileAddresses;
    const kkUser = store.getState().userSession.kkUser;
    const user = useAppSelector(selectUser);

    const companyId = useMemo(() => COMPANY_ID(), [user.activeCompany]);

    const getCardProfileByIdApi = useCallback((id: number) => {
        setLoading(true);

        getCardProfileById(id)
            .then(res => {
                const data = res.error ? null : CardProfileConverter.convertFromCardProfileApiToCardProfile(res.data);
                setCardProfileData(data);
                const profilePhotoPath = data?.profileBasic?.profilePhotoPath;
                const profilePhotoUrl = (imageBaseUrl && profilePhotoPath) ? (imageBaseUrl + profilePhotoPath) : '';

                const profileCoverPath = data?.profileBasic?.coverPhotoPath;
                const profileCoverUrl = (imageBaseUrl && profileCoverPath) ? (imageBaseUrl + profileCoverPath) : '';

                setProfilePhoto(profilePhotoUrl);
                setCoverPhoto(profileCoverUrl);
            }).finally(() => {
                setLoading(false);
                initialLoad && setInitialLoad(false);
            });
    }, [id]);

    useEffect(() => {
        if (loading) return; 
        if (!kkUser) return;

        getCardProfileByIdApi(id);
    }, [kkUser]);

    const assignCardProfileApi = (userEmail: string) => {
        if (!companyId) return;

        setLoadingAssign(true);
        assignCardProfile(id, companyId, CardProfileConverter.convertToCardProfileAssignRequest(userEmail))
            .then((res) => {
                if (res.error) {
                    setAlert(ERROR, res.error);
                } else {
                    getCardProfileByIdApi(id);
                    setAlert(SUCCESS, i18n.t('messages.profileWasSuccessfullyAssigned'));
                }
            })
            .finally(() => setLoadingAssign(false));
    };


    return {
        loading: loading || initialLoad,
        coverPhoto,
        profilePhoto,
        profileBasic: cardProfileData?.profileBasic,
        profileSocialLinks: cardProfileData?.profileSocialLinks ?? [],
        profileContacts: cardProfileData?.profileContacts ?? [],
        profileAddress: address ? address : undefined,
        profileAbout: cardProfileData?.profileAbout,
        profileStyle: cardProfileData?.profileStyle,
        toggle: cardProfileData?.profileToggle,
        cardProfile: cardProfileData,
        setProfilePhoto,
        setCoverPhoto,
        assignCardProfileApi,
        loadingAssign
    };
};

export default useCardProfileController;