import { strapiUrl } from '../../api/base';
import { Article, Category } from '../../types/helpDesk';
import {isEmpty} from '../../utils/common';

export const CatgoryConverter = {
    convertFromCategoriesApiToCatgories(data: any): Category[] {
        if (isEmpty(data)) return [];

        return data.map((d: Category) => ({
            id: d.id,
            title: d.title,
            slug: d.slug,
            description: d.description,
            icon: {
                url:  `${strapiUrl}${d.icon.url}`
            },
            articles: d.articles?.map((article: Article) => ({
                id: article.id,
                title: article.title,
                slug: article.slug,
                updatedAt: article.updatedAt
            })) || [],
        }));
    }
};