import {configDefaultWithToken, deleteApiCall, getApiCall, postApiCall} from './base';
import {SalesforceCredentialsCreate} from '../types/exchangeContacts';

const salesforceUrl = '/api/salesforce';
const salesforceCredentialsByUserUrl = (userId: number) => `${salesforceUrl}/credentials/${userId}`;
const disconnectSalesforceByUserUrl = (userId: number) => `${salesforceUrl}/disconnect/${userId}`;
const integrationStatusByUserUrl = (userId: number) => `${salesforceUrl}/hubspot/status/${userId}`;

export const saveSalesforceCredentials = async (userId: number, secretHidden: boolean, data: SalesforceCredentialsCreate) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            secretHidden: secretHidden
        }
    };

    return await postApiCall(salesforceCredentialsByUserUrl(userId), data, config);
};

export const getSalesforceCredentials = async (userId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await getApiCall(salesforceCredentialsByUserUrl(userId), config);
};

export const getUserIntegrationStatus = async (userId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await getApiCall(integrationStatusByUserUrl(userId), config);
};

export const disconnectSalesforce = async (userId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await deleteApiCall(disconnectSalesforceByUserUrl(userId), config);
};