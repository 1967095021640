import {CardProfileAddress, CardProfileContact} from '../../../types/cardProfile';
import {getExternalUrl, getMailUrl, getPhoneUrl, isPhoneNumber} from '../../../utils/common';
import {
    isAppointmentBookingType,
    isEmailContactType,
    isPhoneContactType,
    isWebsiteContactType, isWhatsAppContactType
} from '../../../utils/contact/contact';


export function createWhatsAppLink(input: string): string {
    // If it's a wa.me message link (starts with https://wa.me/message/)
    if (/^https:\/\/wa\.me\/message\/[A-Za-z0-9]+$/.test(input)) {
        return input;
    }

    if (isPhoneNumber(input)) {
        // If it's a number, remove all non-digit characters
        const phoneNumber = input.replace(/\D/g, '');

        // Basic validation: 6-18 digits (international numbers)
        if (/^\d{6,18}$/.test(phoneNumber)) {
            return `https://wa.me/${phoneNumber}`;
        }
    }

    return input;
}

export const getContactUrl = (contact: CardProfileContact) => {
    const type = contact.type?.trim().toLowerCase();
    const details = contact.details?.trim();

    if (isPhoneContactType(type)) return getPhoneUrl(details);
    if (isEmailContactType(type)) return getMailUrl(details);
    if (isWebsiteContactType(type) || isAppointmentBookingType(type)) return getExternalUrl(details);
    if (isWhatsAppContactType(type)) return createWhatsAppLink(details);
    return '';
};

export const getLocationUrl = (address: CardProfileAddress) => {
    const googleMapsUrl = 'https://maps.google.com/maps?q=';
    return googleMapsUrl + address.address + ', ' + address.country + ', ' + address.city + ', ' + address.zip;
};