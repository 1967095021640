import {useCallback, useEffect, useMemo, useState} from 'react';
import {UserCardProfileConverter} from '../../converters/api/UserCardProfileConverter';
import {UserCardProfileFilter, UserCardProfilesPagination} from '../../types/userCardProfile';
import {deleteCardProfileById} from '../../api/cardProfile';
import {defaultUserCardProfileFilter} from './myCardsUtils';
import {COMPANY_ID, USER_ID} from '../../store/utils/userSession';
import {FilerConverter} from '../../converters/api/FilterConverter';
import {getCompanyCardProfilesControllerApi} from '../../api/apiController';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';

const useCompanyCardsController = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [apiCalled, setApiCalled] = useState<boolean>(false);
    const [apiFilter, setApiFilter] = useState<UserCardProfileFilter>(defaultUserCardProfileFilter);
    const [cardProfilesPagination, setCardProfilesPagination] = useState<UserCardProfilesPagination | null>(null);
    const [reloadCards, setReloadCards] = useState<number>(0);
    const user = useAppSelector(selectUser);

    const companyId = useMemo(() => COMPANY_ID(), [user.activeCompany]);
    const userId = useMemo(() => USER_ID(), []);

    const getUserCardProfilesApi = useCallback(() => {
        if (!companyId || !userId) return;

        setLoading(true);
        getCompanyCardProfilesControllerApi(userId, companyId, FilerConverter.convertUserCardProfileFilterToCardProfileApiFilter(apiFilter, defaultUserCardProfileFilter))
            .then(res => {
                setCardProfilesPagination(
                    UserCardProfileConverter.convertFromUserCardProfileApiToUserCardProfilesPagination(res.data)
                );
            })
            .finally(() => {
                setLoading(false);
                setApiCalled(true);
            });
    }, [companyId, apiFilter, user.activeCompany]);

    useEffect(() => {
        if (loading) return;
        getUserCardProfilesApi();
    }, [companyId, apiFilter, reloadCards, user.activeCompany]);

    const handleDeleteCardProfile = async (cardProfileId: number) => {
        return await deleteCardProfileById(cardProfileId).then(() => getUserCardProfilesApi());
    };

    const reloadCardsApi = () => setReloadCards(prevState => prevState + 1);

    return {
        apiCalled,
        loading,
        apiFilter,
        setApiFilter,
        cardProfilesPagination,
        handleDeleteCardProfile,
        getUserCardProfilesApi,
        reloadCardsApi
    };
};

export default useCompanyCardsController;