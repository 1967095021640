import React, {FC, RefObject, useRef, useState} from 'react';
import styles from './contact-menu.module.scss';
import i18n from '../../../translations/i18n';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import {useOutsideClick} from '../../../hooks/useOutsideCLick';
import EditContactModal from '../modal/EditContactModal';
import Divider from '../../../components/common/divider/Divider';
import DeleteContactModal from '../modal/DeleteContactModal';
import {useExchangeContactContext} from '../../../context/ExchangeContactProvider';
import {ExchangeContactFormData} from '../../previewCardProfile/exchangeContact/ExchangeContact';

interface ContactMenuProps {
    contactId: number;
    menuIconRef: RefObject<HTMLDivElement>;
    onClose: () => void;
    exchangeContact: ExchangeContactFormData;
    className?: string;
}

const ContactMenu: FC<ContactMenuProps> = ({
    contactId, menuIconRef, onClose, exchangeContact, className
}) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const { addUpdatedExchangeContact, removeExchangeContact } = useExchangeContactContext();

    useOutsideClick([menuRef, menuIconRef], () => {
        !openEditModal && !openDeleteModal && onClose();
    });

    const handleOnClose = () => {
        setOpenEditModal(false);
        onClose();
    };

    const handleOnCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        removeExchangeContact(contactId);
        onClose();
    };

    return (
        <>
            <div ref={menuRef} className={`${styles.container} ${className}`}>
                <div className={`${styles.item} ${styles.pointer}`} onClick={() => setOpenEditModal(true)}>
                    <div className={styles.iconWrapper}>
                        <EditIcon className={styles.icon}/>
                    </div>
                    <div className={styles.text}>
                        {i18n.t('common.editContact')}
                    </div>
                </div>
                <Divider/>
                <div className={`${styles.item} ${styles.pointer}`} onClick={() => setOpenDeleteModal(true)}>
                    <div className={styles.iconWrapper}>
                        <TrashIcon className={styles.icon}/>
                    </div>
                    <div className={styles.text}>
                        {i18n.t('common.deleteContact')}
                    </div>
                </div>
            </div>
            <EditContactModal
                contactId={contactId}
                open={openEditModal}
                onClose={handleOnClose}
                exchangeContact={exchangeContact}
                addUpdatedExchangeContact={addUpdatedExchangeContact}
            />
            <DeleteContactModal
                open={openDeleteModal}
                onClose={handleOnCloseDeleteModal}
                onCancel={() => setOpenDeleteModal(false)}
                contactId={contactId}
            />
        </>
    );
};

export default ContactMenu;