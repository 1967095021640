import {ViewSelectorType} from '../components/cards/myCardsHeader/ViewSelector';


const LOCAL_STORAGE_VIEW_MODE = 'iCardsViewMode';


export const saveViewModeToLocalStorage = (viewMode: ViewSelectorType) => {
    localStorage.setItem(LOCAL_STORAGE_VIEW_MODE, viewMode);
};

export const getViewModeFromLocalStorage = () => (
    localStorage.getItem(LOCAL_STORAGE_VIEW_MODE) as ViewSelectorType
);

