import React, { FC } from 'react';
import { ExchangeContactData } from '../../../types/exchangeContacts';
import Contact from './Contact';
import {Pagination} from '@mui/material';
import styles from './contacts.module.scss';

interface ContactsMobileViewProps {
    contacts: ExchangeContactData[];
    totalPages: number;
    handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
    page: number;
}

const ContactsMobileView: FC<ContactsMobileViewProps> = ({
    contacts, totalPages, handlePageChange, page
}) => {
    return (
        <div className={styles.contactsContainer}>
            {contacts.map(contact => (
                <Contact
                    key={contact.id}
                    contact={contact}
                />
            ))}
            {totalPages > 1 &&
                <Pagination
                    page={page}
                    count={totalPages}
                    shape="rounded"
                    onChange={handlePageChange}
                    style={{alignSelf: 'center'}}
                />
            }
        </div>
    );
};

export default ContactsMobileView;
