import {useCallback, useEffect, useState} from 'react';
import {getExchangeContacts} from '../../api/exchangeContact';
import {ExchangeContactData, ExchangeContactPagination, ExchangeContactsApiFilter} from '../../types/exchangeContacts';
import {defaultExchangeContactsApiFilter} from './contactsColumnsUtils';
import {ExchangeContactConverter} from '../../converters/api/ExchangeContactConverter';

const useContactsController = (userId: number) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [apiFilter, setApiFilter] = useState<ExchangeContactsApiFilter>(defaultExchangeContactsApiFilter);
    const [exchangeContactPagination, setExchangeContactPagination] = useState<ExchangeContactPagination | undefined>(undefined);
    const [reloadContacts, setReloadContacts] = useState<number>(0);

    const getExchangeContactsApi = useCallback((userId: number) => {
        setLoading(true);
        getExchangeContacts(userId, apiFilter)
            .then(res => {
                const data = res.error ? undefined : ExchangeContactConverter.convertFromExchangeContactApiToExchangeContactsPagination(res.data);
                setExchangeContactPagination(data);

            })
            .finally(() => setLoading(false));
    }, [userId, apiFilter]);


    const reloadContactsApi = () => setReloadContacts(prevState => prevState + 1);

    const addUpdatedExchangeContact = (updatedContact: ExchangeContactData) => {
        setExchangeContactPagination((prevState) => {
            if (!prevState) return prevState;

            return {
                ...prevState,
                contacts: prevState.contacts.map((contact) =>
                    contact.id === updatedContact.id ? updatedContact : contact
                )
            };
        });
    };

    const removeExchangeContact = (contactId: number) => {
        setExchangeContactPagination((prevState) => {
            if (!prevState) return prevState;

            return {
                ...prevState,
                contacts: prevState.contacts.filter((contact) => contact.id !== contactId)
            };
        });
    };

    useEffect(() => {
        if (loading) return;

        getExchangeContactsApi(userId);
    }, [userId, apiFilter, reloadContacts]);


    return {
        loading,
        apiFilter,
        setApiFilter,
        exchangeContactPagination,
        addUpdatedExchangeContact,
        removeExchangeContact,
        reloadContactsApi
    };
};

export default useContactsController;