import React from 'react';
import styles from './loading-screen.module.scss';
import LoadingIcards from './LoadingIcards';

const LoadingScreen = () => {
    return (
        <div className={styles['x-reset-container']}>
            <LoadingIcards fill={'#FFF'} />
        </div>
    );
};

export default LoadingScreen;
