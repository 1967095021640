import {useCallback, useEffect, useState} from 'react';
import {ExchangeContactConverter} from '../../../converters/api/ExchangeContactConverter';
import {getUserIntegrationStatus} from '../../../api/salesforce';
import {UserIntegrationStatus} from '../../../types/exchangeContacts';
import {getInitUserIntegrationStatus} from '../contactsUtils';


const useSalesforcePopupController = (userId: number) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showSalesforcePopup, setShowSalesforcePopup] = useState<boolean>(false);
    const [integrationStatus, setIntegrationStatus] = useState<UserIntegrationStatus>(getInitUserIntegrationStatus());
    const [reloadIntegrationStatus, setReloadIntegrationStatus] = useState<number>(0);

    const isSalesforceIntegratedApi = useCallback((userId: number) => {
        setLoading(true);
        getUserIntegrationStatus(userId)
            .then(res => {
                const data = ExchangeContactConverter.convertFromIntegrationStatusApiToUserIntegrationStatus(res.data);
                setShowSalesforcePopup((!data?.salesforce && !data?.hubSpot) ?? false);
                data && setIntegrationStatus(data);
            })
            .finally(() => setLoading(false));
    }, [userId]);

    const reloadIntegrationStatusApi = () => setReloadIntegrationStatus(prevState => prevState + 1);

    useEffect(() => {
        if (loading) return;

        isSalesforceIntegratedApi(userId);
    }, [userId, reloadIntegrationStatus]);


    return {
        integrationStatus,
        showSalesforcePopup,
        setShowSalesforcePopup,
        reloadIntegrationStatusApi
    };
};

export default useSalesforcePopupController;