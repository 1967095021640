import {useCallback, useEffect, useState} from 'react';
import {useAppSelector} from '../../../../store/appDispatch';
import {selectUser} from '../../../../store/slices/userSessionSlice';
import {SalesforceCredentials, SalesforceCredentialsCreate} from '../../../../types/exchangeContacts';
import {ExchangeContactConverter} from '../../../../converters/api/ExchangeContactConverter';
import {isHidden} from '../integrationUtils';
import {AuthResponseType} from '../../../../types/integration';
import {getSalesforceCredentials, saveSalesforceCredentials} from '../../../../api/salesforce';

const useSalesforceFormController = (isSalesforceEnabled: boolean, reloadIntegrationStatus: () => void) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [salesforceCredentials, setSalesforceCredentials] = useState<SalesforceCredentials | undefined>(undefined);
    const [authResponseType, setAuthResponseType] = useState<AuthResponseType | undefined>(undefined);
    const user = useAppSelector(selectUser);

    const getSalesforceCredentialsApi = useCallback(() => {
        if (!user) return;

        setLoading(true);
        getSalesforceCredentials(user.id)
            .then((res) => {
                const error = res.error;
                if (!error) {
                    setSalesforceCredentials(ExchangeContactConverter.convertFromSalesforceApiToSalesforceCredentials(res.data));
                }
            })
            .finally(() => setLoading(false));
    }, [user]);

    const saveSalesforceCredentialsApi = (instanceUrl: string, consumerKey: string, consumerSecret: string) => {
        setLoading(true);
        const data: SalesforceCredentialsCreate = {
            consumerKey: consumerKey,
            consumerSecret: consumerSecret,
            instanceUrl: instanceUrl
        };

        saveSalesforceCredentials(user.id, isHidden(consumerSecret), data)
            .then((res)=> {
                if (res.status === 401) {
                    setAuthResponseType('authError');
                } else if (!res.error) {
                    setAuthResponseType('success');
                    reloadIntegrationStatus();
                } else {
                    setAuthResponseType('serverError');
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        isSalesforceEnabled && user && getSalesforceCredentialsApi();
    }, [user, isSalesforceEnabled]);

    return {
        loading,
        salesforceCredentials,
        saveSalesforceCredentialsApi,
        authResponseType,
        setAuthResponseType
    };
};

export default useSalesforceFormController;